/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Icons
import { HelpIcon } from 'assets/images'

// Utils
import { numberWithCommas, capitalizeWords } from 'common/utils/valueFormat'

// Constants
import {
  STANDARD_DATETIME_FORMAT,
  // STANDARD_DATE_FORMAT
} from 'common/constants/dateFormat'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Text,
  Title,
  GridRowContainer,
  TextRowContainer,
  BlockContainer,
  RowWrapper,
  FlowWrapper,
} from '../../AddressesTableItemDetails.elements'
import moment from 'moment'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function SummarySection(props) {
  // Destructure
  const { actions, financialData, addressData } = props

  // Store Actions
  const { toggleInfoModal, setInfoModalDetails } = actions

  // Functions
  const handleToggleInfo = (title, content) => {
    setInfoModalDetails({ title, content })
    toggleInfoModal(true)
  }
  return (
    <FlowWrapper>
      <RowWrapper
        style={{
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '20px 10px 10px 10px',
          cursor: 'pointer',
          width: 'fit-content',
        }}
        onClick={() => {
          handleToggleInfo(
            'Wallet Address Information',
            // eslint-disable-next-line max-len
            'Details about a specific blockchain address, including its balance, associated blockchain, and whether it is self-hosted.'
          )
        }}
      >
        <Title style={{ fontWeight: '600', fontSize: '15px', margin: 0 }}>Wallet Address Information</Title>
        <HelpIcon size={16} />
      </RowWrapper>

      <BlockContainer style={{ padding: '10px', borderWidth: 2 }}>
        <GridRowContainer>
          <BlockContainer>
            <TextRowContainer>
              <Text>Balance: </Text>
              {financialData && (
                <Text>
                  {financialData?.balance !== null && financialData?.balance !== undefined && addressData?.symbol
                    ? `${
                        financialData.balance > 1
                          ? numberWithCommas(Math.round(financialData.balance * 100) / 100)
                          : Number(financialData.balance?.toFixed(6))
                      } ${addressData?.symbol && addressData.symbol.toUpperCase()}`
                    : '-'}
                </Text>
              )}
              {!financialData && addressData && (
                <Text>
                  {addressData?.balance !== null && addressData?.balance !== undefined && addressData?.symbol
                    ? `${
                        addressData.balance > 1
                          ? numberWithCommas(Math.round(addressData.balance * 100) / 100)
                          : Number(addressData.balance?.toFixed(6))
                      } ${addressData?.symbol && addressData.symbol.toUpperCase()}`
                    : '-'}
                </Text>
              )}
            </TextRowContainer>
            <TextRowContainer>
              <Text>Blockchain: </Text>
              {addressData?.chain && (
                <Text>
                  {addressData?.chain !== 'xdc' ? capitalizeWords(addressData.chain) : addressData.chain?.toUpperCase()}
                </Text>
              )}
              {!addressData?.chain && <Text>-</Text>}
            </TextRowContainer>
            <TextRowContainer>
              <Text>Self-hosted Wallet: </Text>
              <Text>{addressData?.is_self_hosted ? 'Yes' : 'No'}</Text>
            </TextRowContainer>
          </BlockContainer>

          <BlockContainer>
            <TextRowContainer>
              <Text>Transfers Sent: </Text>
              <Text>{Number.isInteger(addressData?.out_transfers_count) ? addressData?.out_transfers_count : '-'}</Text>
            </TextRowContainer>
            <TextRowContainer>
              <Text>Transfers Received: </Text>
              <Text>{Number.isInteger(addressData?.in_transfers_count) ? addressData?.in_transfers_count : '-'}</Text>
            </TextRowContainer>
          </BlockContainer>

          <BlockContainer>
            <TextRowContainer>
              <Text>Last Modified: </Text>
              <Text>
                {addressData?.last_modified ? moment(addressData?.last_modified).format(STANDARD_DATETIME_FORMAT) : '-'}
              </Text>
            </TextRowContainer>
            <TextRowContainer>
              <Text>Last Modified By: </Text>
              <Text> {addressData?.modified_by_name ? addressData.modified_by_name : '-'}</Text>
            </TextRowContainer>
          </BlockContainer>
        </GridRowContainer>
      </BlockContainer>
    </FlowWrapper>
  )
}

// Default Props
SummarySection.defaultProps = {}

// Proptypes Validation
SummarySection.propTypes = {
  financialData: PropTypes.instanceOf(Object),
  addressData: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    is_self_hosted: PropTypes.bool,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    create_tz: PropTypes.string,
    link_type: PropTypes.number,
    out_transfers_count: PropTypes.number,
    in_transfers_count: PropTypes.number,
    link_id: PropTypes.string,
    modified_by_name: PropTypes.string,
    last_modified: PropTypes.string,
  }),
  actions: PropTypes.shape({
    toggleAddressHistoryModal: PropTypes.func,
    toggleAddressReportModal: PropTypes.func,
    setAddressReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setIsLinkAddressDrawerOpen: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
    setAddressHistoryFilters: PropTypes.func,
    setAddressRemove: PropTypes.func,
    showAlert: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(SummarySection)
