import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './DeactivateUserModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Hooks
import { useUpdateUser } from 'core/hooks/api'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function DeactivateUserModal(props) {
  // Destructure
  const { ui, actions, auth } = props

  // Store State
  const { isDeactivateUserModalOpen } = ui
  const { userToDeact } = auth

  // Store Actions
  const { toggleDeactivateUserModal, setIsPageTableUpdated, setUserToDeact } = actions

  // Hooks
  const { updateUser, updateUserError, isUpdateUserLoading } = useUpdateUser()

  // Functions
  const toggleModal = () => {
    toggleDeactivateUserModal()
  }
  const handleDeact = () => {
    updateUser({ user_id: userToDeact.user_id, is_active: userToDeact.is_active })
  }

  // UseEffect
  useEffect(() => {
    if (isDeactivateUserModalOpen) {
      if (!isUpdateUserLoading && !updateUserError) {
        setIsPageTableUpdated(true)
        setUserToDeact()
        toggleDeactivateUserModal()
      }
    }
  }, [isUpdateUserLoading])
  return (
    <Modal isOpen={isDeactivateUserModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Delete address
      </ModalHeader>
      <ModalBody>
        {isUpdateUserLoading && <ModalBodyWrapper>Deactivating User...</ModalBodyWrapper>}
        {!isUpdateUserLoading && !updateUserError && (
          <ModalBodyWrapper>
            Are you sure you want to delete this address? This action is irreversible.
          </ModalBodyWrapper>
        )}
        {!isUpdateUserLoading && updateUserError && (
          <ModalBodyWrapper>An error occurred in removing address, please try again later.</ModalBodyWrapper>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton onClick={toggleModal}>
            <Button color="default" disabled={isUpdateUserLoading}>
              Cancel
            </Button>
          </ModalFooterButton>
          <ModalFooterButton onClick={handleDeact}>
            <Button color="secondary" disabled={isUpdateUserLoading}>
              Deactivate
            </Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
DeactivateUserModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
DeactivateUserModal.propTypes = {
  ui: PropTypes.shape({ isDeactivateUserModalOpen: PropTypes.bool }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  auth: PropTypes.shape({
    userToDeact: PropTypes.shape({ user_id: PropTypes.string, is_active: PropTypes.bool }),
  }),
  actions: PropTypes.shape({
    toggleDeactivateUserModal: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setUserToDeact: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateUserModal)
