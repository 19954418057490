import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Views
import { CardInfo, Button } from 'views/components'
import {
  AddressTableFilter,
  // TravelRuleTableFilter
} from 'views/layouts'

// Services
import { GetAddressAccountSummary } from 'services/api/Addresses'
import { GetAccountTransferSummary } from 'services/api/TravelRule'

// Assets
import { BlockchainAnalyticsIcon } from 'assets/images'
import OutIcon from 'assets/images/out'
import InIcon from 'assets/images/in'

// Store
import { actions } from 'core/store'

// Styled Elements
import { Wrapper, Container, RowWrapper } from './AccountSummaryFilters.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AccountSummaryFilters(props) {
  // Destructure
  const { actions, account_id, account_data, activeTable, setActiveTable } = props

  // Local state
  const [addressSummary, setAddressSummary] = useState()
  const [inAccountSummary, setInAccountSummary] = useState()
  const [outAccountSummary, setOutAccountSummary] = useState()

  // store actions
  const { setAddressFilters, setIsAddressSearchModalOpen, setAddressSearchDetails, setTravelRuleFilters } = actions

  // Variables
  const defaultTransferFilters = {
    sort: '-last_modified',
    page: 1,
    size: 10,
    transfer_status_id: undefined,
    asset_code: undefined,
    direction: undefined,
    start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
    end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  }
  // functions
  const fetchData = async () => {
    const allAddress = await GetAddressAccountSummary({ account_id })
    const allInAccount = await GetAccountTransferSummary({ in_account_id: account_id })
    const allOutAccount = await GetAccountTransferSummary({ out_account_id: account_id })
    if (allAddress) setAddressSummary(allAddress)
    if (allInAccount) setInAccountSummary(allInAccount)
    if (allOutAccount) setOutAccountSummary(allOutAccount)
  }
  const handleAddressFilter = () => {
    setAddressFilters({
      sort: '-last_modified',
      page: 1,
      size: 10,
      start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
      link_id: account_id,
      identified: undefined,
    })
    setActiveTable(0)
  }
  const handleTransfersIn = () => {
    setTravelRuleFilters({
      ...defaultTransferFilters,
      in_account_id: account_id,
      out_account_id: undefined,
    })
    setActiveTable(1)
  }
  const handleTransfersOut = () => {
    setTravelRuleFilters({
      ...defaultTransferFilters,
      in_account_id: undefined,
      out_account_id: account_id,
    })
    setActiveTable(2)
  }
  const handleAddWallet = () => {
    setIsAddressSearchModalOpen(true)
    setAddressSearchDetails({ link_id: account_data?.id, link_type: account_data?.account_type_id })
  }

  // useEffects
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Wrapper>
      <Container>
        <CardInfo
          data={[
            { label: 'Today', value: addressSummary?.today ? addressSummary?.today : '-' },
            { label: 'Last 30 Days', value: addressSummary?.days_30 ? addressSummary?.days_30 : '-' },
            { label: 'All Time', value: addressSummary?.all_time ? addressSummary?.all_time : '-' },
          ]}
          title="All Linked Addresses"
          icon={<BlockchainAnalyticsIcon />}
          active={activeTable === 0}
          onClick={handleAddressFilter}
        />
        <CardInfo
          data={[
            { label: 'Today', value: inAccountSummary?.today ? inAccountSummary?.today : '-' },
            { label: 'Last 30 Days', value: inAccountSummary?.days_30 ? inAccountSummary?.days_30 : '-' },
            { label: 'All Time', value: inAccountSummary?.all_time ? inAccountSummary?.all_time : '-' },
          ]}
          title="Transfers Sent"
          icon={<OutIcon size={30} />}
          active={activeTable === 1}
          onClick={handleTransfersIn}
        />
        <CardInfo
          data={[
            { label: 'Today', value: outAccountSummary?.today ? outAccountSummary?.today : '-' },
            { label: 'Last 30 Days', value: outAccountSummary?.days_30 ? outAccountSummary?.days_30 : '-' },
            { label: 'All Time', value: outAccountSummary?.all_time ? outAccountSummary?.all_time : '-' },
          ]}
          title="Transfers Received"
          icon={<InIcon size={30} />}
          active={activeTable === 2}
          onClick={handleTransfersOut}
        />
      </Container>
      <RowWrapper>
        {activeTable === 0 && <AddressTableFilter id="accountAddressTableFilter" />}
        {(activeTable === 1 || activeTable === 2) && <div />}
        {/* {(activeTable === 1 || activeTable === 2) && (
          <TravelRuleTableFilter setIsTransferLoading={() => {}} id="accountAddressTableFilter" />
        )} */}
        <Button onClick={handleAddWallet}>Add New Wallet</Button>
      </RowWrapper>
    </Wrapper>
  )
}

// Default Props
AccountSummaryFilters.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
  account_id: '',
}

// Proptypes Validation
AccountSummaryFilters.propTypes = {
  account_id: PropTypes.string,
  filters: PropTypes.shape({
    addressFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
    setAddressFilters: PropTypes.func,
    setIsAddressSearchModalOpen: PropTypes.func,
    setAddressSearchDetails: PropTypes.func,
    setTravelRuleFilters: PropTypes.func,
  }),
  activeTable: PropTypes.number,
  setActiveTable: PropTypes.func,
  account_data: PropTypes.shape({
    id: PropTypes.string,
    account_type_id: PropTypes.number,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSummaryFilters)
