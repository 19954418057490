import { useMutation } from 'react-query'

// Services
import { GetAccountTransferSummary } from 'services/api/TravelRule'

export default () => {
  const {
    mutate: getAccountTransferSummary,
    data: getAccountTransferSummaryData,
    error: getAccountTransferSummaryError,
    isLoading: isGetAccountTransferSummaryLoading,
    isSuccess: isGetAccountTransferSummarySuccess,
    isError: isGetAccountTransferSummaryError,
  } = useMutation(GetAccountTransferSummary)

  return {
    getAccountTransferSummary,
    getAccountTransferSummaryData,
    getAccountTransferSummaryError,
    isGetAccountTransferSummaryLoading,
    isGetAccountTransferSummarySuccess,
    isGetAccountTransferSummaryError,
  }
}
