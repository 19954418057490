/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Assets
import { PlusWhiteIcon, CloseIcon } from 'assets/images'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'

// Constants
// import {
//   IP_RISK_PARTNER_OPTIONS,
//   IP_RISK_PARTNER_OPTIONS_MAPPING,
//   RISK_OPTIONS,
//   SUPPORTED_CHAIN_OPTIONS,
// } from 'common/constants/formOptions'

// Constants
import {
  // STANDARD_DATETIME_FORMAT,
  STANDARD_DATE_FORMAT,
} from 'common/constants/dateFormat'
// Hooks
import { useCreateOrUpdateTransaction } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Store
import { actions } from 'core/store'

// Styled Elements
import { Text, RowWrapper, RowContainer, FlowWrapper, BlueText } from '../../TransactionsTableItemDetails.elements'

// Components
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  LoadingTablePlaceholder,
} from 'views/components'
import RiskDetails from './RiskDetails'
import FinancialDetails from './FinancialDetails'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DetailsSummary(props) {
  // Destructure
  const {
    actions,
    // setFinancialData,
    riskData,
    setRiskData,
    transactionData,
    setTransactionData,
    isGetTransactionDetailsLoading,
    transaction,
  } = props

  const {
    toggleTransactionReportModal,
    setTransactionReportDetails,
    toggleDeleteTransactionModal,
    showAlert,
    setTransactionRemove,
    toggleInfoModal,
    setInfoModalDetails,
    toggleTransactionDetailsModalOpen,
    setTransactionModalDetails,
  } = actions
  const { transactionChains } = transaction

  // hooks
  const {
    createOrUpdateTransaction,
    createOrUpdateTransactionData,
    createOrUpdateTransactionError,
    isCreateOrUpdateTransactionLoading,
  } = useCreateOrUpdateTransaction()
  const [userCredentials] = useLocalStorage('userCredentials', {})

  // local state
  const [currentIpUsed, setCurrentIpUsed] = useState()
  const [txDescription, setTxDescription] = useState()
  const [availableIps, setAvailableIps] = useState(null)

  // !THIS FUNCTION RELLIES ON FINANCIAL DATA
  const handleDeleteTransaction = () => {
    setTransactionRemove({ transaction_id: transactionData.id })
    toggleDeleteTransactionModal(true)
  }
  const handleScan = (ip) => {
    setCurrentIpUsed(ip)
    createOrUpdateTransaction({
      transaction: transactionData.hash,
      chain: transactionData.chain,
      name_first: userCredentials.name_first,
      name_last: userCredentials.name_last,
      integration_id: ip,
    })
  }
  const handleCreateReport = () => {
    setTransactionReportDetails({
      transaction_id: transactionData.id,
      chain: transactionData.chain,
    })
    toggleTransactionReportModal()
  }
  const handleCreateOrUpdateTransaction = () => {
    if (createOrUpdateTransactionData) {
      setRiskData((prevValue) => {
        const newRiskData = [...prevValue]
        let found = false
        prevValue.forEach((item, i) => {
          if (item?.risk_integration_id === createOrUpdateTransactionData?.risk_data?.risk_integration_id) {
            newRiskData[i] = createOrUpdateTransactionData.risk_data
          }
          found = true
        })
        if (found === false) newRiskData.push(createOrUpdateTransactionData.risk_data)
        return newRiskData
      })
      setTransactionData({ ...transactionData, risk_ratio: createOrUpdateTransactionData?.risk_data?.risk_ratio })
    }
  }
  const handleToggleInfo = (title, content) => {
    setInfoModalDetails({ title, content })
    toggleInfoModal(true)
  }
  const formatRiskDescriptor = (risk) => {
    if (risk?.risk_descriptors?.split(',')?.length > 1) {
      const desc = (
        <Text>
          On <BlueText>{moment(transactionData?.created).format(STANDARD_DATE_FORMAT)}</BlueText>, a(n){' '}
          <BlueText>{transactionData?.chain}</BlueText> transaction with the hash{' '}
          <BlueText>{transactionData?.hash}</BlueText> was analysed. The transaction involves{' '}
          <BlueText>{transactionData?.in_count}</BlueText> input(s) with a value of{' '}
          <BlueText>USD ${numberWithCommas(Math.round(transactionData.in_usd * 100) / 100)}</BlueText> and{' '}
          <BlueText>{transactionData?.out_count}</BlueText> output(s) amounting{' '}
          <BlueText>USD ${numberWithCommas(Math.round(transactionData.out_usd * 100) / 100)}</BlueText> including
          digital assets such as <BlueText>{transactionData?.symbol}</BlueText>. This transaction, carries an input risk
          score of <BlueText>{risk?.in_risk_ratio}%</BlueText>, while the output has a risk score of{' '}
          <BlueText>{risk?.out_risk_ratio}%</BlueText>, sourced from{' '}
          <BlueText>{risk?.provider_name || 'a risk provider'}</BlueText>. Risk descriptor(s) linked to the transaction
          encompass <BlueText>{risk.risk_descriptors?.split(',').join(', ')}</BlueText>, indicative of potential
          association with the transaction.
        </Text>
      )
      return desc
    }
    if (risk?.risk_descriptors?.split(',')?.length < 2 || !risk?.risk_descriptors) {
      const desc = (
        <Text>
          On <BlueText>{moment(transactionData?.created).format(STANDARD_DATE_FORMAT)}</BlueText>, a(n){' '}
          <BlueText>{transactionData?.chain}</BlueText> transaction with the hash{' '}
          <BlueText>{transactionData?.hash}</BlueText> was analysed. The transaction involves{' '}
          <BlueText>{transactionData?.in_count}</BlueText> input(s) with a value of{' '}
          <BlueText>USD ${numberWithCommas(Math.round(transactionData.in_usd * 100) / 100)}</BlueText> and{' '}
          <BlueText>{transactionData?.out_count}</BlueText> output(s) amounting{' '}
          <BlueText>USD ${numberWithCommas(Math.round(transactionData.out_usd * 100) / 100)}</BlueText> including
          digital assets such as <BlueText>{transactionData?.symbol}</BlueText>. This transaction, carries an input risk
          score of <BlueText>{risk?.in_risk_ratio}%</BlueText>, while the output has a risk score of{' '}
          <BlueText>{risk?.out_risk_ratio}%</BlueText>, sourced from{' '}
          <BlueText>{risk?.provider_name || 'a risk provider'}</BlueText>.
        </Text>
      )
      return desc
    }
  }
  const handleRiskClick = (index) => {
    if (!Number.isNaN(index) && (transactionData?.in_count > 0 || transactionData?.out_count > 0)) {
      toggleTransactionDetailsModalOpen()
      setTransactionModalDetails({ ...transactionData, riskData: riskData[index] })
    }
  }
  const handleAvailableIps = () => {
    if (availableIps) return
    if (!transactionChains?.risk) return
    let newAvailableIps = {}
    Object.keys(transactionChains?.risk).forEach((item) => {
      const ip = transactionChains?.risk[item]
      Object.keys(ip).forEach((ipChain) => {
        if (ip[ipChain]?.chain_name === transactionData?.chain) {
          newAvailableIps = {
            ...newAvailableIps,
            [ip[ipChain]?.provider_code]: {
              integration_id: ip[ipChain]?.integration_id,
              provider_code: ip[ipChain]?.provider_code,
            },
          }
        }
      })
    })
    setAvailableIps(newAvailableIps)
  }

  // UseEffects
  useEffect(() => {
    handleAvailableIps()
  }, [])
  useEffect(() => handleCreateOrUpdateTransaction(), [createOrUpdateTransactionData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (createOrUpdateTransactionError) {
      showAlert({ type: 'error', message: 'An error occurred in scanning transaction' })
    }
  }, [createOrUpdateTransactionError])
  useEffect(() => {
    if (!currentIpUsed && riskData?.length > 0) {
      if (riskData[0]?.risk_integration_id) setCurrentIpUsed(riskData[0].risk_integration_id)
      setTxDescription(formatRiskDescriptor(riskData[0]))
    } else if (currentIpUsed && riskData?.length > 0) {
      riskData.forEach((v) => {
        if (v?.risk_integration_id === currentIpUsed) setTxDescription(formatRiskDescriptor(v))
      })
    }
  }, [riskData])

  return (
    <FlowWrapper>
      {isGetTransactionDetailsLoading && <LoadingTablePlaceholder />}
      {/* -------FINANCIAL DATA------- */}
      <FinancialDetails
        transactionData={transactionData}
        isGetTransactionDetailsLoading={isGetTransactionDetailsLoading}
        txDescription={txDescription}
      />
      {/* -------EVENT DATA------- */}
      {/* <EventDetails
        transactionData={transactionData}
        isGetTransactionDetailsLoading={isGetTransactionDetailsLoading}
        txDescription={txDescription}
        currentIpUsed={currentIpUsed}
        isExpanded={isExpanded}
        eventDetails={eventDetails}
        setEventDetails={setEventDetails}
      /> */}
      {/* -------RISK DATA------- */}
      <RiskDetails
        onClick={handleRiskClick}
        key={riskData}
        riskData={riskData}
        isGetTransactionDetailsLoading={isGetTransactionDetailsLoading}
        isCreateOrUpdateTransactionLoading={isCreateOrUpdateTransactionLoading}
        currentIpUsed={currentIpUsed}
      />
      {/* -------BOTTOM BUTTONS------- */}
      <RowWrapper>
        <RowContainer>
          <Button variant="outlined" size="small" onClick={handleCreateReport} disabled>
            Create Report
          </Button>
        </RowContainer>
        <RowContainer style={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" size="small" endIcon={<CloseIcon />} onClick={handleDeleteTransaction}>
            Remove
          </Button>
          {/* <Button
            variant="outlined"
            size="small"
            endIcon={<EyeIcon />}
            onClick={() =>
              handleToggleInfo('Authorization Needed', 'You currently do not have access to this feature.')
            }
          >
            Investigate
          </Button> */}
          <UncontrolledDropdown direction="down">
            <DropdownToggle>
              <Button variant="outlined" size="small" endIcon={<PlusWhiteIcon />}>
                Add score
              </Button>
            </DropdownToggle>
            <DropdownMenu left style={{ maxWidth: '155px', minWidth: '125px', padding: '0 12px' }}>
              {Object.keys(transactionChains?.risk || {})?.length > 0 &&
                Object.keys(transactionChains?.risk)?.map((key) => (
                  <DropdownItem
                    key={key}
                    disabled={
                      isCreateOrUpdateTransactionLoading || !transactionChains?.risk?.[key]?.[transactionData?.symbol]
                    }
                    onClick={() => {
                      if (
                        isCreateOrUpdateTransactionLoading ||
                        !transactionChains?.risk?.[key]?.[transactionData?.symbol]
                      ) {
                        return
                      }
                      if (transactionChains?.risk?.[key]?.[transactionData?.symbol]) {
                        handleScan(transactionChains?.risk?.[key]?.[transactionData?.symbol]?.provider_id)
                      } else {
                        handleToggleInfo('Authorization Needed', 'You currently do not have access to this feature.')
                      }
                    }}
                    style={{ borderRadius: 0, justifyContent: 'center', padding: '9px' }}
                  >
                    <Text>{key}</Text>
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </RowContainer>
      </RowWrapper>
    </FlowWrapper>
  )
}

// Default Props
DetailsSummary.defaultProps = {
  // setFinancialData: () => {},
  riskData: null,
  setRiskData: () => {},
  transactionData: null,
  setTransactionData: () => {},
  actions: {},
  filters: {},
  ui: {},
  isGetTransactionDetailsLoading: false,
}

// Proptypes Validation
DetailsSummary.propTypes = {
  // setFinancialData: PropTypes.func,
  transaction: PropTypes.instanceOf(Object),
  riskData: PropTypes.instanceOf(Object),
  setRiskData: PropTypes.func,
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  transactionData: PropTypes.shape({
    id: PropTypes.string,
    hash: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    in_usd: PropTypes.number,
    out_usd: PropTypes.number,
    fee_amount_usd: PropTypes.number,
    balance: PropTypes.number,
    last_modified: PropTypes.string,
    created: PropTypes.string,
    in_count: PropTypes.number,
    out_count: PropTypes.number,
    asset_count: PropTypes.number,
  }),
  setTransactionData: PropTypes.func,
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    toggleTransactionReportModal: PropTypes.func,
    setTransactionReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteTransactionModal: PropTypes.func,
    setIsLinkTransactionDrawerOpen: PropTypes.func,
    setTransactionLinkDetails: PropTypes.func,
    setAddressHistoryFilters: PropTypes.func,
    setTransactionRemove: PropTypes.func,
    showAlert: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
    toggleTransactionDetailsModalOpen: PropTypes.func,
    setTransactionModalDetails: PropTypes.func,
  }),

  address_data: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
  }),
  isGetTransactionDetailsLoading: PropTypes.bool,
  eventDetails: PropTypes.shape({
    inputs: PropTypes.shape({
      events: PropTypes.arrayOf({}),
      page: PropTypes.number,
    }),
    outputs: PropTypes.shape({
      events: PropTypes.arrayOf({}),
      page: PropTypes.number,
    }),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsSummary)
