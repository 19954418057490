import { useMutation } from 'react-query'

// Services
import { RequestVasp } from 'services/api/Business'

export default () => {
  const {
    mutate: requestVasp,
    data: requestVaspData,
    error: requestVaspError,
    isLoading: isRequestVaspLoading,
    isSuccess: isRequestVaspSuccess,
    isError: isRequestVaspError,
  } = useMutation(RequestVasp)

  return {
    requestVasp,
    requestVaspData,
    requestVaspError,
    isRequestVaspLoading,
    isRequestVaspSuccess,
    isRequestVaspError,
  }
}
