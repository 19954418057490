import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Utils
import { removeSameValues } from 'common/utils/removeSameValues'

// Assets
import DownloadIcon from 'assets/images/download'
import CloseIcon from 'assets/images/close'

// Status
import { CUSTOMER_STATUS } from 'common/constants/customerStatus'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchVasp, useUpdateVasp } from 'core/hooks/api'

// Styled Elements
import { Container, Title, EditBtnWrapper, BtnContainer } from './DetailsTab.elements'

// Views
import { Button } from 'views/components'
import { VaspDetailsForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DetailsTab(props) {
  // Destructure
  const { actions, ui } = props
  const { vasp_id } = useParams()
  const { isPageTableUpdated } = ui

  // store actions
  const {
    setShowHeaderLoader,
    showAlert,
    setApproveVaspModal,
    setApproveVaspDetails,
    setRejectVaspModal,
    setRejectVaspDetails,
    setIsPageTableUpdated,
  } = actions

  // refs
  const formRef = useRef()

  // hooks
  const { getVasp, vaspData, isVaspLoading } = useFetchVasp()
  const { updateVasp, vaspUpdateError, isVaspUpdateSuccess, isVaspUpdateError, isVaspUpdateLoading } = useUpdateVasp()

  // state
  const [vaspDataState, setVaspDataState] = useState()
  const [viewMode, setViewMode] = useState(true)

  // Functions
  const handleNotifyVasp = () => {
    setApproveVaspDetails({ vasp_id: vaspDataState?.id, vasp_name: vaspDataState?.name_legal })
    setApproveVaspModal(true)
  }
  const handleRejectVasp = () => {
    setRejectVaspDetails({
      vasp_id: vaspDataState?.id,
      vasp_name: vaspDataState?.name_legal,
      customer_status_id: vaspDataState?.customer_status_id,
    })
    setRejectVaspModal(true)
  }
  const fetchData = () => {
    getVasp({ vasp_id })
  }
  const handleSave = () => {
    const finalValues = removeSameValues(formRef?.current?.values, vaspDataState)
    updateVasp({ vasp_id, values: finalValues })
  }
  const handleCancel = () => {
    setViewMode(true)
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }
  const handleSuccess = () => {
    if (formRef.current) {
      formRef.current.resetForm({ values: formRef.current?.values })
    }
    setVaspDataState(formRef.current?.values)
    showAlert({ type: 'success', message: 'Successfully updated details' })
    setViewMode(true)
  }

  const handleFailure = () => {
    if (vaspUpdateError?.response?.data?.context?.error) {
      showAlert({ type: 'error', message: vaspUpdateError?.response?.data?.context?.error })
    } else {
      showAlert({ type: 'error', message: 'An error occurred in updating details' })
    }
  }

  const loading = isVaspLoading || isVaspUpdateLoading

  const handleLoading = () => {
    if (loading) setShowHeaderLoader(true)
    if (!loading) setShowHeaderLoader(false)
  }

  // useEffect
  useEffect(() => fetchData(), [])
  useEffect(() => {
    if (isPageTableUpdated) {
      fetchData()
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])
  useEffect(() => {
    if (vaspData) setVaspDataState(vaspData)
  }, [vaspData])
  useEffect(() => {
    if (isVaspUpdateSuccess) handleSuccess()
  }, [isVaspUpdateSuccess])
  useEffect(() => {
    if (isVaspUpdateError) handleFailure()
  }, [isVaspUpdateError])
  useEffect(() => handleLoading(), [loading])

  return (
    <Container>
      {!isVaspLoading && vaspDataState && (
        <>
          {viewMode === true && (
            <EditBtnWrapper>
              <Title>{vaspDataState.name_legal}</Title>
              <BtnContainer>
                <Button color="primary" disabled={loading} onClick={() => setViewMode(false)}>
                  Edit
                </Button>
                {vaspDataState?.customer_status_id === CUSTOMER_STATUS.Requested && (
                  <>
                    <Button color="secondary" disabled={loading} onClick={handleRejectVasp}>
                      Reject
                    </Button>
                    <Button variant="primary" disabled={loading} onClick={handleNotifyVasp}>
                      Approve
                    </Button>
                  </>
                )}
              </BtnContainer>
            </EditBtnWrapper>
          )}
          {viewMode === false && (
            <EditBtnWrapper>
              <Title>{vaspDataState.name_legal}</Title>
              <BtnContainer>
                <Button
                  type="button"
                  disabled={loading}
                  onClick={handleCancel}
                  variant="outlined"
                  startIcon={<CloseIcon />}
                >
                  Cancel
                </Button>
                <Button type="button" disabled={loading} onClick={handleSave} startIcon={<DownloadIcon />}>
                  Save Changes
                </Button>
              </BtnContainer>
            </EditBtnWrapper>
          )}
          <VaspDetailsForm
            style={{ paddingTop: '50px' }}
            values={vaspDataState}
            viewMode={viewMode}
            formRef={formRef}
          />
        </>
      )}
    </Container>
  )
}

// Default Props
DetailsTab.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
DetailsTab.propTypes = {
  filters: PropTypes.shape({
    vaspDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setVaspDirectoryFilters: PropTypes.func,
    setIsIndividualVaspDrawerOpen: PropTypes.func,
    setIsEntityVaspDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,

    setApproveVaspModal: PropTypes.func,
    setApproveVaspDetails: PropTypes.func,
    setRejectVaspModal: PropTypes.func,
    setRejectVaspDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsTab)
