import { useMutation } from 'react-query'

// Services
import { ApproveVasp } from 'services/api/Business'

export default () => {
  const {
    mutate: approveVasp,
    data: approveVaspData,
    error: approveVaspError,
    isLoading: isApproveVaspLoading,
    isSuccess: isApproveVaspSuccess,
    isError: isApproveVaspError,
  } = useMutation(ApproveVasp)

  return {
    approveVasp,
    approveVaspData,
    approveVaspError,
    isApproveVaspLoading,
    isApproveVaspSuccess,
    isApproveVaspError,
  }
}
