export const CUSTOMER_STATUS_MAP = {
  '-2': { value: -2, label: 'Requested' },
  '-1': { value: -1, label: 'Rejected' },
  0: { value: 0, label: 'Non-Customer' },
  1: { value: 1, label: 'Customer' },
  2: { value: 2, label: 'Mid Prospect' },
  3: { value: 3, label: 'Trial Account' },
  4: { value: 4, label: 'Inactive Customer' },
}
export const CUSTOMER_STATUS = {
  Requested: -2,
  Rejected: -1,
  NonCustomer: 0,
  Customer: 1,
  MidProspect: 2,
  TrialAccount: 3,
  InactiveCustomer: 4,
}
export const CUSTOMER_STATUS_OPTIONS = [
  { value: -2, label: 'Requested' },
  { value: -1, label: 'Rejected' },
  { value: 0, label: 'Non-Customer' },
  { value: 1, label: 'Customer' },
  { value: 2, label: 'Mid Prospect' },
  { value: 3, label: 'Trial Account' },
  { value: 4, label: 'Inactive Customer' },
]
