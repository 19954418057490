import { useMutation } from 'react-query'

// Services
import { GetGleif } from 'services/api/Gleif'

export default () => {
  const {
    mutate: getGleif,
    data: getGleifData,
    error: getGleifError,
    isLoading: isGetGleifLoading,
    isSuccess: isGetGleifSuccess,
    isError: isGetGleifError,
  } = useMutation(GetGleif)

  return {
    getGleif,
    getGleifData,
    getGleifError,
    isGetGleifLoading,
    isGetGleifSuccess,
    isGetGleifError,
  }
}
