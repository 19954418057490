/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Assets
import { PlusWhiteIcon, CloseIcon, LinkIcon, HelpIcon } from 'assets/images'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Constants
import {
  // STANDARD_DATETIME_FORMAT,
  STANDARD_DATE_FORMAT,
} from 'common/constants/dateFormat'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Text,
  Title,
  ColWrapper,
  ColContainer,
  RowWrapper,
  RowContainer,
  GridRowContainer,
  TextRowContainer,
  GroupRowContainer,
  BlockContainer,
  FlowWrapper,
  InfoTitle,
  RiskContainer,
} from '../../DemoAddressesTableItemDetails.elements'

// Components
import {
  // MoneyBar,
  Button,
  // NumberedChart,
  CircularProgressBar,
  UncontrolledDropdown,
  SkeletonText,
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function DetailsSummary(props) {
  // Destructure
  const { actions, BarWidth } = props

  // Store
  const { toggleUpgradeModal } = actions

  // local state
  // const [sentBarWidth, setSentBarWidth] = useState('0%')
  // const [receivedBarWidth, setReceivedBarWidth] = useState('0%')

  return (
    <FlowWrapper key={1525161}>
      {/* -------FINANCIAL DATA------- */}
      <RowWrapper>
        <BlockContainer style={{ padding: '10px', borderWidth: 2 }}>
          <GridRowContainer>
            <BlockContainer>
              <TextRowContainer>
                <Text>Balance: </Text>
                <SkeletonText width="140" />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Protocol: </Text>
                <SkeletonText width="180" />
              </TextRowContainer>
            </BlockContainer>

            <BlockContainer>
              <TextRowContainer>
                <Text>Travel Rule Transfers: </Text>
                <SkeletonText width="70" />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Detection Engine Alerts: </Text>
                <SkeletonText width="120" />
              </TextRowContainer>
            </BlockContainer>

            <BlockContainer>
              <TextRowContainer>
                <Text>Linked Account Risk Level: </Text>
                <SkeletonText width="140" />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Modified By: </Text>
                <SkeletonText width="110" />
              </TextRowContainer>
            </BlockContainer>
          </GridRowContainer>
        </BlockContainer>
      </RowWrapper>

      {/* -------RISK DATA------- */}
      <BlockContainer style={{ padding: '10px', marginTop: '20px', borderWidth: 2 }} key={28948923}>
        <GridRowContainer
          style={{
            gridTemplateColumns: '320px 1fr 0.79fr',
          }}
        >
          <>
            <RowContainer style={{ maxWidth: '320px', alignItems: 'center' }}>
              <BlockContainer style={{ display: 'flex', padding: 0 }}>
                <ColWrapper>
                  <RiskContainer>
                    <CircularProgressBar key={1512} id={`${1512}-${1}`} size={120} progress={BarWidth}>
                      <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                        <Text style={{ fontSize: '18px', fontWeight: 600 }}>{`${BarWidth}%`}</Text>
                        <Text style={{ fontSize: '12px', fontWeight: 400 }} id="rsSubTitle">
                          Risk
                        </Text>
                      </ColContainer>
                    </CircularProgressBar>
                  </RiskContainer>
                  <GroupRowContainer style={{ padding: '20px' }}>
                    <ColContainer>
                      <InfoTitle
                        onClick={() => {
                          notifyPaymentRequired(toggleUpgradeModal)
                        }}
                      >
                        Risk Score
                        <HelpIcon />
                      </InfoTitle>
                      <Text style={{ border: 'none', fontWeight: 600, fontSize: 15 }}>{`${BarWidth}`}/100</Text>
                    </ColContainer>
                    <ColContainer style={{ alignItems: 'center' }}>
                      <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                      <Text style={{ border: 'none', fontWeight: 600, fontSize: 15 }}>
                        {moment('2024-11-15').format(STANDARD_DATE_FORMAT)}
                      </Text>
                    </ColContainer>
                    <ColContainer style={{ alignItems: 'flex-end' }}>
                      <InfoTitle
                        onClick={() => {
                          notifyPaymentRequired(toggleUpgradeModal)
                        }}
                      >
                        Source
                        <HelpIcon />
                      </InfoTitle>
                      <SkeletonText width="60" height="14" style={{ marginTop: '3px' }} />
                    </ColContainer>
                  </GroupRowContainer>
                </ColWrapper>
              </BlockContainer>
            </RowContainer>

            <RowContainer>
              <BlockContainer>
                <ColContainer>
                  <Title>Risk Score Descriptors: </Title>
                  <RowContainer style={{ gap: 5, maxHeight: '150px', overflowY: 'auto', paddingRight: '20px' }}>
                    <SkeletonText width="80" />
                    <SkeletonText width="180" />
                    <SkeletonText width="210" />
                    <SkeletonText width="150" />
                    <SkeletonText width="140" />
                    <SkeletonText width="150" />
                    <SkeletonText width="120" />
                    <SkeletonText width="110" />
                    <SkeletonText width="140" />
                  </RowContainer>
                </ColContainer>
              </BlockContainer>
            </RowContainer>

            <RowContainer style={{ maxWidth: '443px' }}>
              <BlockContainer>
                <ColContainer>
                  <Title>Controlling Entity: </Title>
                  <TextRowContainer style={{ padding: '0 0 10px 0' }}>
                    <Text>Name: </Text>
                    <SkeletonText width="120" />
                  </TextRowContainer>
                  <TextRowContainer>
                    <Text>Website: </Text>
                    <SkeletonText width="180" />
                  </TextRowContainer>
                  <TextRowContainer>
                    <Text>Type: </Text>
                    <SkeletonText width="90" />
                  </TextRowContainer>
                </ColContainer>
              </BlockContainer>
            </RowContainer>
          </>
        </GridRowContainer>
      </BlockContainer>
      <BlockContainer style={{ padding: '10px', marginTop: '20px', borderWidth: 2 }} key={28948762}>
        <GridRowContainer
          style={{
            gridTemplateColumns: '320px 1fr 0.79fr',
          }}
        >
          <>
            <RowContainer style={{ maxWidth: '320px', alignItems: 'center' }}>
              <BlockContainer style={{ display: 'flex', padding: 0 }}>
                <ColWrapper>
                  <RiskContainer>
                    <CircularProgressBar key={1512} id={`${1512}-${1}`} size={120} progress={BarWidth}>
                      <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                        <Text style={{ fontSize: '18px', fontWeight: 600 }}>{`${BarWidth}%`}</Text>
                        <Text style={{ fontSize: '12px', fontWeight: 400 }} id="rsSubTitle">
                          Risk
                        </Text>
                      </ColContainer>
                    </CircularProgressBar>
                  </RiskContainer>
                  <GroupRowContainer style={{ padding: '20px' }}>
                    <ColContainer>
                      <InfoTitle
                        onClick={() => {
                          notifyPaymentRequired(toggleUpgradeModal)
                        }}
                      >
                        Risk Score
                        <HelpIcon />
                      </InfoTitle>
                      <Text style={{ border: 'none', fontWeight: 600, fontSize: 15 }}>{`${BarWidth}`}/100</Text>
                    </ColContainer>
                    <ColContainer style={{ alignItems: 'center' }}>
                      <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                      <Text style={{ border: 'none', fontWeight: 600, fontSize: 15 }}>
                        {moment('2024-11-13').format(STANDARD_DATE_FORMAT)}
                      </Text>
                    </ColContainer>
                    <ColContainer style={{ alignItems: 'flex-end' }}>
                      <InfoTitle
                        onClick={() => {
                          notifyPaymentRequired(toggleUpgradeModal)
                        }}
                      >
                        Source
                        <HelpIcon />
                      </InfoTitle>
                      <SkeletonText width="60" height="14" style={{ marginTop: '3px' }} />
                    </ColContainer>
                  </GroupRowContainer>
                </ColWrapper>
              </BlockContainer>
            </RowContainer>

            <RowContainer>
              <BlockContainer>
                <ColContainer>
                  <Title>Risk Score Descriptors: </Title>
                  <RowContainer style={{ gap: 5, maxHeight: '150px', overflowY: 'auto', paddingRight: '20px' }}>
                    <SkeletonText width="140" />
                    <SkeletonText width="120" />
                    <SkeletonText width="110" />
                    <SkeletonText width="130" />
                    <SkeletonText width="90" />
                    <SkeletonText width="50" />
                    <SkeletonText width="250" />
                    <SkeletonText width="140" />
                    <SkeletonText width="100" />
                    <SkeletonText width="160" />
                  </RowContainer>
                </ColContainer>
              </BlockContainer>
            </RowContainer>

            <RowContainer style={{ maxWidth: '443px' }}>
              <BlockContainer>
                <ColContainer>
                  <Title>Controlling Entity: </Title>
                  <TextRowContainer style={{ padding: '0 0 10px 0' }}>
                    <Text>Name: </Text>
                    <SkeletonText width="120" />
                  </TextRowContainer>
                  <TextRowContainer>
                    <Text>Website: </Text>
                    <SkeletonText width="180" />
                  </TextRowContainer>
                  <TextRowContainer>
                    <Text>Type: </Text>
                    <SkeletonText width="90" />
                  </TextRowContainer>
                </ColContainer>
              </BlockContainer>
            </RowContainer>
          </>
        </GridRowContainer>
      </BlockContainer>
      {/* -------BOTTOM BUTTONS------- */}
      <RowWrapper>
        <RowContainer>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              notifyPaymentRequired(toggleUpgradeModal)
            }}
          >
            See History
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              notifyPaymentRequired(toggleUpgradeModal)
            }}
          >
            Create Report
          </Button>
        </RowContainer>
        <RowContainer style={{ justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            size="small"
            endIcon={<CloseIcon />}
            onClick={() => {
              notifyPaymentRequired(toggleUpgradeModal)
            }}
          >
            Remove
          </Button>
          <Button
            variant="outlined"
            size="small"
            endIcon={<LinkIcon />}
            onClick={() => {
              notifyPaymentRequired(toggleUpgradeModal)
            }}
          >
            Link
          </Button>
          <UncontrolledDropdown direction="down">
            <Button
              variant="outlined"
              size="small"
              endIcon={<PlusWhiteIcon />}
              onClick={() => {
                notifyPaymentRequired(toggleUpgradeModal)
              }}
            >
              Add score
            </Button>
          </UncontrolledDropdown>
        </RowContainer>
      </RowWrapper>
    </FlowWrapper>
  )
}

// Default Props
DetailsSummary.defaultProps = {
  actions: {},
}

// Proptypes Validation
DetailsSummary.propTypes = {
  actions: PropTypes.shape({
    toggleUpgradeModal: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsSummary)
