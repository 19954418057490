import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Components
import { TableWrapper } from './AdminRequestedVasps.elements'

// Views
import { Container } from 'views/components'
import { App, Header, AdminRequestedVaspsTable, PageWrapper, Sidebar, AdminVaspsSubHeader } from 'views/layouts'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

// !THIS PAGE IS NOT CURRENTLY SECURE
// !ADD RESTRICTIONS TO ROUTING IN THE CORE ROUTING
function AdminRequestedVasps() {
  const [userCredentials] = useLocalStorage('userCredentials', {})

  useEffect(() => {
    if (userCredentials?.role_id !== 0 || !userCredentials) {
      window.location.replace(window.location.origin)
    }
  }, [userCredentials])
  return (
    <App>
      <Sidebar />
      <Header pageTitle="Vasp Notification" subHeader={<AdminVaspsSubHeader />} />
      <PageWrapper>
        <Container>
          <TableWrapper>
            <AdminRequestedVaspsTable />
          </TableWrapper>
        </Container>
      </PageWrapper>
    </App>
  )
}

// Default Props
AdminRequestedVasps.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
AdminRequestedVasps.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminRequestedVasps)
