/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { saveAs } from 'file-saver'

// Assets
import ProfileIcon from 'assets/images/profile'
import ElipisisIcon from 'assets/images/elipisis'
import TabPaneIcon from 'assets/images/tab-pane.svg'
import NotificationIcon from 'assets/images/notification'
import ArrowUpIcon from 'assets/images/arrowUp'
import ArrowLeftIcon from 'assets/images/arrowLeft'

// Contants
// import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Store
import { actions } from 'core/store'

// Hooks
import { useLogout } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  HeaderWrapper,
  HeaderTitle,
  HeaderInner,
  HeaderRightNav,
  HeaderRightNavList,
  HeaderRightNavListItem,
  HeaderLogoutButton,
  ProfileInfoWrapper,
  ProfileInfoContainer,
  ProfileFullName,
  ProfileUserName,
  ProfileUserRole,
  HeaderSideMenuButton,
  HeaderSideMenuIcon,
  IconWrapper,
  SubHeaderWrapper,
  SubHeaderContent,
  DropdownItemLink,
  DropdownItemText,
  DropdownItemDownload,
  HasNotificationIcon,
} from './Header.elements'

// Views
import {
  ContainerFluid,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Image,
  LoaderLine,
  Button,
} from 'views/components'
import { useHistory } from 'react-router'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function Header(props) {
  // Destructure
  const { ui, actions, subHeader, pageTitle, link } = props

  // Store States
  const { showHeaderLoader, isProfileUpdated, isNotifUpdated } = ui

  // Store Actions
  const { openAppSidebarDrawer, setIsProfileUpdated, setIsNotifUpdated } = actions

  // Hooks
  const { logoutUser } = useLogout()
  const history = useHistory()
  const [userCredentials, setUserCredentials] = useLocalStorage('userCredentials', false)
  const [reportStrings, setReportStrings] = useLocalStorage('reportStrings', [])
  const [reportTitles, setReportTitles] = useLocalStorage('reportTitles', [])

  // Functions
  // handling logout
  const handleLogout = async () => {
    await logoutUser()
  }
  // handling download
  const handleDownloadReport = (index) => {
    saveAs(
      new Blob([s2ab(reportStrings[index])], {
        type: 'application/octet-stream',
      }),
      reportTitles[index]
    )
  }
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  useEffect(() => {
    if (isProfileUpdated) {
      // Updating profile header
      setUserCredentials(JSON.parse(localStorage.getItem('userCredentials')))
      setIsProfileUpdated(false)
    }
    if (isNotifUpdated) {
      // Updating notif bar
      setReportStrings(JSON.parse(localStorage.getItem('reportStrings')))
      setReportTitles(JSON.parse(localStorage.getItem('reportTitles')))
      setIsNotifUpdated(false)
    }
  }, [isProfileUpdated, isNotifUpdated])

  return (
    <HeaderWrapper>
      <LoaderLine show={showHeaderLoader} />
      <ContainerFluid>
        <HeaderInner>
          <HeaderTitle>
            {link ? (
              <Button onClick={() => history.push(link)}>
                <ArrowLeftIcon size={30} />
                {pageTitle || ''}
              </Button>
            ) : (
              pageTitle || ''
            )}
          </HeaderTitle>
          <HeaderRightNav>
            <HeaderRightNavList>
              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <IconWrapper>
                      <NotificationIcon />
                      {reportTitles?.length > 0 && <HasNotificationIcon>!</HasNotificationIcon>}
                    </IconWrapper>
                  </DropdownToggle>
                  <DropdownMenu left>
                    {reportTitles?.length > 0 ? (
                      <>
                        <DropdownItem header>
                          <DropdownItemText>Data import completed, download the report</DropdownItemText>
                        </DropdownItem>
                        {reportTitles.map((item, i) => (
                          <DropdownItem header key={item}>
                            <DropdownItemDownload
                              onClick={() => {
                                handleDownloadReport(i)
                              }}
                            >
                              {item.substring(0, 19)}...
                            </DropdownItemDownload>
                          </DropdownItem>
                        ))}
                      </>
                    ) : (
                      <DropdownItem header>
                        <DropdownItemText>No notifications</DropdownItemText>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderRightNavListItem>

              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <IconWrapper>
                      <ElipisisIcon />
                    </IconWrapper>
                  </DropdownToggle>
                  <DropdownMenu left>
                    {/* <DropdownItem header>
                      <a
                        onClick={() => {
                          window.open(EXTERNAL_URLS.FEEDBACK, '_blank')
                        }}
                      >
                        Send feedback
                      </a>
                    </DropdownItem> */}

                    <DropdownItem header>
                      <a
                        onClick={() => {
                          window.open('https://docs.ospree.io/ospree-api/overview', '_blank')
                        }}
                      >
                        API Documentation <ArrowUpIcon />
                      </a>
                    </DropdownItem>
                    <DropdownItem header>
                      <a
                        onClick={() => {
                          window.open('https://ospree.io/help/', '_blank')
                        }}
                      >
                        Get help <ArrowUpIcon />
                      </a>
                    </DropdownItem>

                    <DropdownItem header>
                      <a
                        onClick={() => {
                          window.open('https://ospree.io/terms-and-conditions/', '_blank')
                        }}
                      >
                        Terms of use <ArrowUpIcon />
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderRightNavListItem>

              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <IconWrapper>
                      <ProfileIcon />
                    </IconWrapper>
                  </DropdownToggle>
                  <DropdownMenu left>
                    <ProfileInfoWrapper>
                      <ProfileIcon />
                      <ProfileInfoContainer>
                        <ProfileFullName>
                          {userCredentials.name_first} {userCredentials.name_last}
                        </ProfileFullName>
                        <ProfileUserRole>{userCredentials.role_desc}</ProfileUserRole>
                        <ProfileUserName>{userCredentials.email}</ProfileUserName>
                      </ProfileInfoContainer>
                    </ProfileInfoWrapper>

                    {userCredentials?.role_id === 1 && (
                      <DropdownItem header>
                        <DropdownItemLink to="/business-details">Business Details</DropdownItemLink>
                      </DropdownItem>
                    )}
                    <DropdownItem header>
                      <DropdownItemLink to={`/user-details/${userCredentials.user_id}`}>Profile</DropdownItemLink>
                    </DropdownItem>

                    <DropdownItem header>
                      <DropdownItemLink to="/settings?page=0">Settings</DropdownItemLink>
                    </DropdownItem>
                    {/* <DropdownItem header>
                      <DropdownItemLink to="/settings?page=2">Users</DropdownItemLink>
                    </DropdownItem> */}

                    <DropdownItem header onClick={handleLogout}>
                      <HeaderLogoutButton>Logout</HeaderLogoutButton>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderRightNavListItem>
            </HeaderRightNavList>
          </HeaderRightNav>

          <HeaderSideMenuButton>
            <HeaderSideMenuIcon
              onClick={() => {
                openAppSidebarDrawer()
              }}
            >
              <Image src={TabPaneIcon} />
            </HeaderSideMenuIcon>
          </HeaderSideMenuButton>
        </HeaderInner>
      </ContainerFluid>
      {subHeader && (
        <SubHeaderWrapper>
          <ContainerFluid>
            <SubHeaderContent>{subHeader}</SubHeaderContent>
          </ContainerFluid>
        </SubHeaderWrapper>
      )}
    </HeaderWrapper>
  )
}

// Default Props
Header.defaultProps = {
  subHeader: '',
  ui: {},
  actions: {},
  pageTitle: '',
}

// Proptypes Validation
Header.propTypes = {
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  ui: PropTypes.shape({
    showHeaderLoader: PropTypes.bool,
    isProfileUpdated: PropTypes.bool,
    isNotifUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    openAppSidebarDrawer: PropTypes.func,
    setIsProfileUpdated: PropTypes.func,
    setIsNotifUpdated: PropTypes.func,
  }),
  pageTitle: PropTypes.string,
  link: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
