import styled from 'styled-components'

export const Text = styled.p`
  font-size: 13px;
  margin: 0;
  text-align: right;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Label = styled.p`
  font-size: 13px;
  margin: 0;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Title = styled.h3`
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.darkGray};
  text-transform: capitalize;
`
export const SubTitle = styled.h4`
  font-size: 16px;
  margin: 0;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 400;

  max-width: 260px;
  width: 190px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const TableRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
export const TableColContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-transform: uppercase;
`
export const TableColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 20px;
  &:last-child {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0 0 10px 10px;
  }
`
export const TableRowWrapper = styled.div`
  display: flex;
  padding: 20px;
  background-color: ${({ theme }) => `${theme.colors.gray}10`};
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 10px 10px 0 0;
`

export const StatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Chip = styled.p`
  font-size: 13px;
  margin: 0 0 4px 0;
  width: fit-content;
  padding: 2px 28px;
  ${({ theme, customer_status_id }) => `
    ${
      customer_status_id === -1 &&
      `
      color: ${theme.colors.red};
      background-color: transparent;
      border: 1px solid ${theme.colors.red};
      border-radius: 8px;
    `
    }
  `};
  ${({ theme, customer_status_id }) => `
    ${
      customer_status_id === -2 &&
      `
      color: ${theme.colors.darkGray};
      background-color: transparent;
      border: 1px solid ${theme.colors.darkGray};
      border-radius: 8px;
    `
    }
  `};
`
