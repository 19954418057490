/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
// import moment from 'moment'

// Assets
import { PlusWhiteIcon, CloseIcon, LinkIcon, CheckIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Utils
import { getCustomerStatus } from 'common/utils/customerValidation'

// Hooks
import {
  // useCreateOrUpdateAddress,
  useUpdateAddressHosted,
} from 'core/hooks/api'

// Styled Elements
import { Text, RowWrapper, RowContainer, FlowWrapper } from '../../AddressesTableItemDetails.elements'

// Components
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function FooterSection(props) {
  // Destructure
  const {
    addressData,
    setAddressData,
    setCurrentIpUsed,
    setIsLoading,
    actions,
    address,
    createOrUpdateAddress,
    createOrUpdateAddressError,
    isCreateOrUpdateAddressLoading,
  } = props
  const {
    toggleAddressHistoryModal,
    toggleAddressReportModal,
    setAddressReportDetails,
    toggleDeleteAddressModal,
    setIsLinkAddressDrawerOpen,
    showAlert,
    setAddressLinkDetails,
    setAddressHistoryFilters,
    setAddressRemove,
    toggleInfoModal,
    setInfoModalDetails,
  } = actions
  const { addressChains } = address

  // Hooks
  const { updateAddressHosted, updateAddressHostedData, updateAddressHostedError, isUpdateAddressHostedLoading } =
    useUpdateAddressHosted()

  // local state
  const [csi] = useState(getCustomerStatus())

  // Functions
  const handleDeleteAddress = () => {
    setAddressRemove({ address_id: addressData.id })
    toggleDeleteAddressModal()
  }
  const handleLink = () => {
    setAddressLinkDetails({
      address: addressData.address,
      address_id: addressData.id,
      chain: addressData.chain,
    })
    setIsLinkAddressDrawerOpen(true)
  }
  const handleScan = (ip) => {
    setCurrentIpUsed(ip)
    createOrUpdateAddress({
      address: addressData.address,
      chain: addressData.chain,
      provider_id: addressChains?.risk?.[ip]?.[addressData?.symbol]?.provider_id,
    })
  }
  const handleCreateReport = () => {
    setAddressReportDetails({
      address: addressData.address,
      address_id: addressData.id,
      chain: addressData.chain,
    })
    toggleAddressReportModal()
  }
  const handleHistoryToggle = () => {
    setAddressHistoryFilters({
      address: addressData.address,
      address_id: addressData.id,
      sort: '-last_modified',
      page: 1,
      size: 10,
      start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    })
    toggleAddressHistoryModal()
  }
  const handleToggleInfo = (title, content) => {
    setInfoModalDetails({ title, content })
    toggleInfoModal(true)
  }

  // UseEffect
  useEffect(() => {
    if (createOrUpdateAddressError?.response?.data?.data) {
      showAlert({ type: 'error', message: createOrUpdateAddressError?.response?.data?.data })
    } else if (createOrUpdateAddressError) {
      showAlert({ type: 'error', message: 'An error occurred in screening address' })
    }
  }, [createOrUpdateAddressError])
  useEffect(() => {
    if (isCreateOrUpdateAddressLoading) setIsLoading(true)
    else setIsLoading(false)
  }, [isCreateOrUpdateAddressLoading])
  useEffect(() => {
    if (updateAddressHostedData && !updateAddressHostedError) {
      setAddressData({ ...addressData, is_self_hosted: updateAddressHostedData?.is_self_hosted })
    }
  }, [updateAddressHostedData])

  return (
    <FlowWrapper>
      <RowWrapper>
        <RowContainer>
          <Button variant="outlined" size="small" onClick={handleHistoryToggle} disabled={csi !== 1}>
            See History
          </Button>
          <Button variant="outlined" size="small" onClick={handleCreateReport} disabled={csi !== 1}>
            Create Report
          </Button>
        </RowContainer>
        <RowContainer style={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" size="small" endIcon={<CloseIcon />} onClick={handleDeleteAddress}>
            Remove
          </Button>

          <UncontrolledDropdown direction="down">
            <DropdownToggle>
              <Button variant="outlined" size="small" endIcon={<CheckIcon />}>
                {isUpdateAddressHostedLoading ? 'Updating...' : 'Is Wallet Self-Hosted'}
              </Button>
            </DropdownToggle>
            <DropdownMenu left style={{ maxWidth: '155px', minWidth: '125px', padding: '0 12px' }}>
              <DropdownItem
                disabled={isUpdateAddressHostedLoading || addressData?.is_self_hosted === true}
                onClick={() => {
                  updateAddressHosted({ is_self_hosted: true, address_id: addressData?.id })
                }}
                style={{ borderRadius: 0, justifyContent: 'center', padding: '9px' }}
              >
                <Text>Yes</Text>
              </DropdownItem>
              <DropdownItem
                disabled={isUpdateAddressHostedLoading || addressData?.is_self_hosted === false}
                onClick={() => {
                  updateAddressHosted({ is_self_hosted: false, address_id: addressData?.id })
                }}
                style={{ borderRadius: 0, justifyContent: 'center', padding: '9px' }}
              >
                <Text>No</Text>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {addressData.id && (
            <Button variant="outlined" size="small" endIcon={<LinkIcon />} onClick={handleLink}>
              Link
            </Button>
          )}
          <UncontrolledDropdown direction="down">
            <DropdownToggle>
              <Button variant="outlined" size="small" endIcon={<PlusWhiteIcon />}>
                Add score
              </Button>
            </DropdownToggle>
            <DropdownMenu left style={{ maxWidth: '155px', minWidth: '125px', padding: '0 12px' }}>
              {Object?.keys(addressChains?.risk || {})?.length > 0 &&
                Object.keys(addressChains?.risk)?.map((key) => (
                  <DropdownItem
                    key={key}
                    disabled={isCreateOrUpdateAddressLoading || !addressChains?.risk?.[key]?.[addressData?.symbol]}
                    onClick={() => {
                      if (isCreateOrUpdateAddressLoading || !addressChains?.risk?.[key]?.[addressData?.symbol]) {
                        return
                      }
                      if (addressChains?.risk?.[key]?.[addressData?.symbol]) handleScan(key)
                      else {
                        handleToggleInfo('Authorization Needed', 'You currently do not have access to this feature.')
                      }
                    }}
                    style={{ borderRadius: 0, justifyContent: 'center', padding: '9px' }}
                  >
                    <Text>{key}</Text>
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </RowContainer>
      </RowWrapper>
    </FlowWrapper>
  )
}

// Default Props
FooterSection.defaultProps = {}

// Proptypes Validation
FooterSection.propTypes = {
  addressData: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    create_tz: PropTypes.string,
    link_type: PropTypes.number,
    link_id: PropTypes.string,
    is_self_hosted: PropTypes.bool,
    modified_by_name: PropTypes.string,
  }),
  setCurrentIpUsed: PropTypes.func,
  createOrUpdateAddress: PropTypes.func,
  createOrUpdateAddressError: PropTypes.shape({
    response: PropTypes.shape({
      data: PropTypes.shape({ data: PropTypes.shape({}) }),
    }),
  }),
  isCreateOrUpdateAddressLoading: PropTypes.bool,
  accountDataProp: PropTypes.shape({}),
  setAddressData: PropTypes.func,
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  setIsLoading: PropTypes.func,
  actions: PropTypes.shape({
    toggleAddressHistoryModal: PropTypes.func,
    toggleAddressReportModal: PropTypes.func,
    setAddressReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setIsLinkAddressDrawerOpen: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
    setAddressHistoryFilters: PropTypes.func,
    setAddressRemove: PropTypes.func,
    showAlert: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  address: PropTypes.instanceOf(Object),
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterSection)
