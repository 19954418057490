import React, { useEffect } from 'react'
import { useParams } from 'react-router'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Hooks
import { useConfirmVaspUserEmail } from 'core/hooks/api'

// Styled Elements
import {
  Wrapper,
  InnerWrapper,
  LogoLink,
  Title,
  Error,
  FormWrapper,
  FormInnerWrapper,
  FormContent,
} from './VaspUserEmailConfirmation.elements'

// Components
import { LoadingTablePlaceholder } from 'views/components'

function VaspUserEmailConfirmation() {
  // Hooks
  const { confirmVaspUserEmail, confirmVaspUserEmailData, confirmVaspUserEmailError, isConfirmVaspUserEmailLoading } =
    useConfirmVaspUserEmail()
  const { hash } = useParams()

  useEffect(() => {
    confirmVaspUserEmail({ hash })
  }, [])
  // useEffect(() => {
  // }, [confirmVaspUserEmailData])
  return (
    <Wrapper>
      <InnerWrapper>
        <FormWrapper>
          <FormInnerWrapper>
            <FormContent>
              <LogoLink>
                <OspreeLogoIcon />
              </LogoLink>
              {isConfirmVaspUserEmailLoading && !confirmVaspUserEmailData && <Title>Confirming Email</Title>}
              {isConfirmVaspUserEmailLoading && !confirmVaspUserEmailData && <LoadingTablePlaceholder />}
              {!isConfirmVaspUserEmailLoading && confirmVaspUserEmailData && (
                <Title>Successfully confirmed email</Title>
              )}
              {confirmVaspUserEmailError && <Error>An error occurred in confirming email</Error>}
            </FormContent>
          </FormInnerWrapper>
        </FormWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

// Default Props
VaspUserEmailConfirmation.defaultProps = {}

// Proptypes Validation
VaspUserEmailConfirmation.propTypes = {}

export default VaspUserEmailConfirmation
