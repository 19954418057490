import { useMutation } from 'react-query'

// Services
import { AddVaspUser } from 'services/api/Business'

export default () => {
  const {
    mutate: addVaspUser,
    data: addVaspUserData,
    error: addVaspUserError,
    isLoading: isAddVaspUserLoading,
    isSuccess: isAddVaspUserSuccess,
    isError: isAddVaspUserError,
  } = useMutation(AddVaspUser)

  return {
    addVaspUser,
    addVaspUserData,
    addVaspUserError,
    isAddVaspUserLoading,
    isAddVaspUserSuccess,
    isAddVaspUserError,
  }
}
