import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { usePushRequestVasp } from 'core/hooks/api'

// Styled Elements
import { FormWrapper, FormFooter, FormError } from '../DirectoryForms.elements'

// Views
import {
  Form,
  Button,
  // FormSelect
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function PushRequestVaspForm(props) {
  // Destructure
  const { actions, email } = props

  // Hooks
  const { pushRequestVasp, pushRequestVaspError, isPushRequestVaspLoading, isPushRequestVaspSuccess } =
    usePushRequestVasp()

  // Store Actions
  const { setIsRequestVaspModalOpen, showAlert } = actions

  // Internal State
  const [errorMessage, setErrorMessage] = useState()

  // Functions
  const handleOnSubmit = () => {
    const payload = { email }
    pushRequestVasp(payload)
  }

  const handleLoadingChange = () => {
    if (isPushRequestVaspLoading) return
    if (!isPushRequestVaspLoading) {
      if (isPushRequestVaspSuccess) {
        setIsRequestVaspModalOpen(false)
        showAlert({ type: 'success', message: 'Successfully Requested for VASP!' })
      } else if (pushRequestVaspError) {
        setErrorMessage('An occurred in Requesting for VASP')
      }
    }
  }
  // UseEffects
  useEffect(() => handleLoadingChange(), [isPushRequestVaspLoading])
  return (
    <FormWrapper style={{ padding: '25px' }}>
      <Form>
        {errorMessage && <FormError>{errorMessage}</FormError>}
        {!errorMessage && (
          <>
            <FormError>
              Pushing through with this request will invalidate previous requests. Are you sure you want to push through
              with requesting this VASP?
            </FormError>

            <FormFooter>
              <Button
                type="button"
                onClick={() => setIsRequestVaspModalOpen}
                disabled={isPushRequestVaspLoading}
                variant="outlined"
                fullWidth
              >
                Cancel
              </Button>
              <Button type="button" onClick={handleOnSubmit} disabled={isPushRequestVaspLoading} fullWidth>
                {isPushRequestVaspLoading ? 'Pushing Request...' : 'Push Request'}
              </Button>
            </FormFooter>
          </>
        )}
      </Form>
    </FormWrapper>
  )
}

// Default Props
PushRequestVaspForm.defaultProps = {
  formRef: {},
  form: {
    toBeUpdatedVasp: null,
  },
  actions: {},
}

// Proptypes Validation
PushRequestVaspForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    countryListOptions: PropTypes.instanceOf(Array),
    toBeUpdatedVasp: PropTypes.shape({
      vasp_id: PropTypes.string,
    }),
  }),
  email: PropTypes.string,
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,
    setCreatedVasp: PropTypes.func,
    showAlert: PropTypes.func,
    setIsRequestVaspModalOpen: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setIsRecordUpdated: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(PushRequestVaspForm)
