import { useMutation } from 'react-query'

// Services
import { GetVaspUsers } from 'services/api/Business'

export default () => {
  const {
    mutate: getVaspUsers,
    data: getVaspUsersData,
    error: getVaspUsersError,
    isLoading: isGetVaspUsersLoading,
    isSuccess: isGetVaspUsersSuccess,
    isError: isGetVaspUsersError,
  } = useMutation(GetVaspUsers)

  return {
    getVaspUsers,
    getVaspUsersData,
    getVaspUsersError,
    isGetVaspUsersLoading,
    isGetVaspUsersSuccess,
    isGetVaspUsersError,
  }
}
