/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

// Constants
import { CUSTOMER_STATUS_MAP } from 'common/constants/customerStatus'

// Store
import { actions } from 'core/store'

// Constants
import {
  STANDARD_DATETIME_FORMAT,
  // STANDARD_DATE_FORMAT
} from 'common/constants/dateFormat'

// Styled Elements
import {
  Chip,
  Text,
  Label,
  Title,
  SubTitle,
  TableColWrapper,
  TableColContainer,
  TableRowWrapper,
  TableRowContainer,
  StatusContainer,
} from './AdminVaspsTableItem.elements'

// Views
import { CardTableItem } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AdminVaspsTableItem(props) {
  // Destructure
  const { vaspDetails: vasp_detail, ui } = props
  // Store State
  const { rejectVaspDetails } = ui

  // State
  const [cardName, setCardName] = useState({})
  const [vaspDetails, setVaspDetails] = useState(vasp_detail)

  // UseEffect
  useEffect(() => {
    if (vaspDetails?.name_legal) {
      const nameArr = vaspDetails?.name_legal.split(' ')
      const firstNameLength = Math.floor(nameArr.length / 2)
      const firstName = []
      for (let i = 0; i < firstNameLength; i++) {
        firstName.push(nameArr[i])
      }
      nameArr.splice(0, firstNameLength)
      setCardName({
        initials: vaspDetails?.name_legal[0] + vaspDetails?.name_legal[1],
        fullname: [firstName.join(' '), nameArr.join(' ')],
      })
    }
  }, [vaspDetails?.external_id])
  useEffect(() => {
    setVaspDetails(vasp_detail)
  }, [vasp_detail])
  useEffect(() => {
    if (rejectVaspDetails && rejectVaspDetails?.vasp_id === vaspDetails?.id) {
      setVaspDetails({ ...vaspDetails, ...rejectVaspDetails })
    }
  }, [rejectVaspDetails])

  return (
    <NavLink to={`/admin/vasps/details/${vaspDetails?.id}`}>
      <CardTableItem key={vaspDetails?.external_id}>
        <TableRowWrapper>
          <Title>{cardName?.initials || 'NA'}</Title>
          <TableColContainer>
            {cardName.fullname?.[0] || cardName.fullname?.[1] ? (
              <>
                <SubTitle>{cardName.fullname?.[0] || '-'}</SubTitle>
                <SubTitle>{cardName.fullname?.[1] || '-'}</SubTitle>
              </>
            ) : (
              <>
                <SubTitle>To Be</SubTitle>
                <SubTitle>Registered</SubTitle>
              </>
            )}
          </TableColContainer>
        </TableRowWrapper>

        <TableColWrapper>
          {vaspDetails?.customer_status_id !== null && vaspDetails?.customer_status_id !== undefined && (
            <TableRowContainer>
              <Label>Status: </Label>
              <StatusContainer>
                <Chip customer_status_id={vaspDetails.customer_status_id}>
                  {CUSTOMER_STATUS_MAP?.[vaspDetails?.customer_status_id]?.label || 'N/A'}
                </Chip>
              </StatusContainer>
            </TableRowContainer>
          )}

          <TableRowContainer>
            <Label>Website: </Label>
            <Text>{vaspDetails?.website || 'NA'}</Text>
          </TableRowContainer>

          <TableRowContainer>
            <Label>Email: </Label>
            <Text>{vaspDetails?.email_contact || 'NA'}</Text>
          </TableRowContainer>

          <TableRowContainer>
            <Label>Latest update: </Label>
            <Text>{moment(vaspDetails?.created).format(STANDARD_DATETIME_FORMAT)}</Text>
          </TableRowContainer>
        </TableColWrapper>
      </CardTableItem>
    </NavLink>
  )
}

// Default Props
AdminVaspsTableItem.defaultProps = {}

// Proptypes Validation
AdminVaspsTableItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  vaspDetails: PropTypes.any,
  actions: PropTypes.shape({
    setApproveVaspModal: PropTypes.func,
    setApproveVaspDetails: PropTypes.func,
    setRejectVaspModal: PropTypes.func,
    setRejectVaspDetails: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isRejectVaspModalOpen: PropTypes.bool,
    rejectVaspDetails: PropTypes.shape({
      vasp_name: PropTypes.string,
      vasp_id: PropTypes.string,
    }),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminVaspsTableItem)
