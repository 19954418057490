/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Store
import { actions } from 'core/store'

// Hooks
// import { useFetchTransfer } from 'core/hooks/api'

// Constants
import {
  STANDARD_DATETIME_FORMAT,
  // STANDARD_DATE_FORMAT
} from 'common/constants/dateFormat'

// Assets
import { CopyIcon } from 'assets/images'

// Utils
import { numberWithCommas, compactNumberFormatter } from 'common/utils/valueFormat'

// Constants
import { BLOCKCHAIN_MONO_SVGS } from 'common/constants/blockchainMonoSvgs'

// Styled Elements
import {
  TableButtonWrapper,
  TableRowWrapper,
  TableColWrapper,
  TableRowContainer,
  TableText,
  // TableTextTiny,
  TableSubText,
  TablePanelWrapper,
  AddressHash,
  AddressHashWrapper,
  CopyNotification,
} from './TransactionsTableItem.elements'

// Views
import { AccordionTableItem, RiskBar, TextSliced } from 'views/components'
import { TransactionsTableItemDetails } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function TransactionsTableItem(props) {
  // Destructure
  const { data, transaction, actions, openedTab, setOpenedTab } = props

  const { transactionLinkData, transactionLinkDetails, scannedTransactionDetails } = transaction
  const {
    setTransactionLinkData,
    setTransactionFilters,
    setTransactionLinkDetails,
    setScannedTransactionDetails,
    setIsPageTableUpdated,
  } = actions

  // States
  const [barWidth, setBarWidth] = useState('0%')
  const [isExpanded, setIsExpanded] = useState(false)
  const [chainIcon, setChainIcon] = useState()

  // Component States
  const [transactionData, setTransactionData] = useState({ ...data })
  const { chain_name, in_usd, last_modified, risk_ratio } = transactionData
  const [eventDetails, setEventDetails] = useState({
    inputs: {
      events: [],
      page: 1,
    },
    outputs: {
      events: [],
      page: 1,
    },
  })
  const [riskData, setRiskData] = useState()
  const [senderAccountData, setSenderAccountData] = useState()
  const [recipientAccountData, setRecipientAccountData] = useState()
  const [businessNotes, setBusinessNotes] = useState()
  const [copyNotification, setCopyNotification] = useState('')

  // Functions
  function formatValue(number) {
    if (number < 1_000_000) {
      return numberWithCommas(Math.round(number * 100) / 100)
    }
    return compactNumberFormatter.format(number)
  }
  const copyHash = () => {
    navigator.clipboard.writeText(transactionData.hash).then(
      () => setCopyNotification('Copied!'),
      () => setCopyNotification('Error: Could not copy')
    )
    setTimeout(() => setCopyNotification(''), 700)
  }
  function handleScannedTransaction() {
    setRiskData((prevValue) => {
      const newRiskData = [scannedTransactionDetails.risk_data]
      if (prevValue) {
        prevValue.forEach((item) => {
          if (item?.provider_name !== scannedTransactionDetails?.risk_data?.provider_name) {
            newRiskData.push(item)
          }
        })
      }
      return newRiskData
    })
    setEventDetails(formatTransactionEvents(scannedTransactionDetails))
    setOpenedTab(scannedTransactionDetails.hash)
    setScannedTransactionDetails(null)
  }
  const formatTransactionEvents = (value) => {
    if (!value?.financial_data?.events) {
      return {
        input: [],
        output: [],
        page: 0,
        size: 0,
        total: 0,
      }
    }
    const events = [...value.financial_data.events]
    const newEvents = {
      inputs: {
        events: [],
        page: 1,
      },
      outputs: {
        events: [],
        page: 1,
      },
    }
    events.forEach((item) => {
      if (item?.type !== 'fee' && item?.source) {
        newEvents.inputs.events.push(item)
      }
      if (item?.type !== 'fee' && item?.destination) {
        newEvents.outputs.events.push(item)
      }
    })
    return newEvents
  }

  // UseEffects
  useEffect(() => {
    if (chain_name) {
      setChainIcon(BLOCKCHAIN_MONO_SVGS[chain_name?.toLowerCase()])
    }
    if (risk_ratio) {
      setBarWidth(`${risk_ratio}%`)
    }
  }, [risk_ratio])
  useEffect(() => {
    if (transactionLinkData) {
      if (transactionLinkDetails.transaction_id === transactionData.id) {
        if (transactionLinkData?.sender) setSenderAccountData(transactionLinkData?.sender)
        if (transactionLinkData?.recipient) setRecipientAccountData(transactionLinkData?.recipient)
        setTransactionLinkData()
        setTransactionLinkDetails()
      }
    }
  }, [transactionLinkData])
  useEffect(
    () => scannedTransactionDetails && scannedTransactionDetails?.hash === data.hash && handleScannedTransaction(),
    [scannedTransactionDetails]
  )
  useEffect(() => {
    if (!isExpanded && !transactionData.id && !scannedTransactionDetails) {
      setTransactionFilters()
      setIsPageTableUpdated(true)
      setOpenedTab()
    }
  }, [isExpanded])
  useEffect(() => {
    if (openedTab !== data.id) setIsExpanded(false)
    if (openedTab === data.id) setIsExpanded(true)
  }, [openedTab])
  return (
    <AccordionTableItem
      setIsExpanded={(val) => {
        setIsExpanded(val)
        setOpenedTab(data.id)
      }}
      expandPanel={isExpanded}
      button={
        <TableButtonWrapper>
          <TableRowWrapper isExpanded={isExpanded}>
            <TableRowContainer>
              {chainIcon && cloneElement(chainIcon)}
              {isExpanded ? (
                <AddressHashWrapper className="hashWrapper" onClick={copyHash}>
                  <AddressHash className="hashWrapper">{transactionData.hash}</AddressHash>
                  <CopyIcon className="hashWrapper" />
                  <CopyNotification display={copyNotification}>{copyNotification}</CopyNotification>
                </AddressHashWrapper>
              ) : (
                <TextSliced text={transactionData.hash} sliceLength={7} />
              )}
            </TableRowContainer>

            <TableRowContainer>
              <TableText>{moment(last_modified).format(STANDARD_DATETIME_FORMAT)}</TableText>
            </TableRowContainer>
          </TableRowWrapper>

          <TableRowWrapper isExpanded={isExpanded}>
            <TableRowContainer>
              <TableText>{risk_ratio && `${risk_ratio}%`}</TableText>
              <RiskBar width={barWidth} />
            </TableRowContainer>

            <TableRowContainer>
              <TableColWrapper>
                <TableText style={{ textTransform: 'uppercase' }}>{!!chain_name && chain_name}</TableText>
                <TableSubText>{in_usd ? `USD $${formatValue(in_usd)}` : 'USD -'} </TableSubText>
              </TableColWrapper>
            </TableRowContainer>
          </TableRowWrapper>
        </TableButtonWrapper>
      }
      panel={
        <TablePanelWrapper>
          <TransactionsTableItemDetails
            isExpanded={isExpanded}
            // Details state
            eventDetails={eventDetails}
            setEventDetails={setEventDetails}
            riskData={riskData}
            setRiskData={setRiskData}
            transactionData={transactionData}
            setTransactionData={setTransactionData}
            senderAccountData={senderAccountData}
            setSenderAccountData={setSenderAccountData}
            recipientAccountData={recipientAccountData}
            setRecipientAccountData={setRecipientAccountData}
            businessNotes={businessNotes}
            setBusinessNotes={setBusinessNotes}
          />
        </TablePanelWrapper>
      }
    />
  )
}

// Default Props
TransactionsTableItem.defaultProps = {
  transaction: {},
  actions: {},
  data: {
    id: '',
    hash: '',
    chain_name: '',
    symbol: '',
    risk_score: 0,
    in_usd: 0,
    last_modified: '',
    link_type: 1,
    link_id: '',
  },
}

// Proptypes Validation
TransactionsTableItem.propTypes = {
  openedTab: PropTypes.string,
  setOpenedTab: PropTypes.func,
  transaction: PropTypes.shape({
    transactionLinkDetails: PropTypes.shape({
      transaction_id: PropTypes.string,
    }),
    transactionLinkData: PropTypes.shape({
      sender: PropTypes.shape({}),
      recipient: PropTypes.shape({}),
    }),
    scannedTransactionDetails: PropTypes.instanceOf(Object),
  }),
  actions: PropTypes.shape({
    setTransactionLinkData: PropTypes.func,
    setTransactionLinkDetails: PropTypes.func,
    setTransactionFilters: PropTypes.func,
    setTransactionDetails: PropTypes.func,
    setScannedTransactionDetails: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
  }),
  data: PropTypes.shape({
    id: PropTypes.string,
    hash: PropTypes.string,
    chain_name: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    out_usd: PropTypes.number,
    in_usd: PropTypes.number,
    fee_amount_usd: PropTypes.number,
    last_modified: PropTypes.string,
    sender_link_type: PropTypes.number,
    sender_link_id: PropTypes.string,
    recipient_link_type: PropTypes.number,
    recipient_link_id: PropTypes.string,
  }),
}
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTableItem)
