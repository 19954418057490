import React, { useEffect } from 'react'
import { useParams } from 'react-router'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Hooks
import { useConfirmTrialAccount } from 'core/hooks/api'

// Styled Elements
import {
  Wrapper,
  InnerWrapper,
  LogoLink,
  Title,
  Error,
  FormWrapper,
  FormInnerWrapper,
  FormContent,
  SuccessTitle,
  SuccessText,
  ButtonContainer,
} from './TrialEmailConfirmation.elements'

// Components
import { LoadingTablePlaceholder, Button } from 'views/components'

function TrialEmailConfirmation() {
  // Hooks
  const { confirmTrialAccount, confirmTrialAccountData, confirmTrialAccountError, isConfirmTrialAccountLoading } =
    useConfirmTrialAccount()
  const { hash } = useParams()

  useEffect(() => {
    confirmTrialAccount({ hash })
  }, [])
  // useEffect(() => {
  // }, [confirmTrialAccountData])
  return (
    <Wrapper>
      <InnerWrapper>
        <FormWrapper>
          <FormInnerWrapper>
            <FormContent>
              <LogoLink>
                <OspreeLogoIcon />
              </LogoLink>
              {isConfirmTrialAccountLoading && !confirmTrialAccountData && <Title>Confirming Email</Title>}
              {isConfirmTrialAccountLoading && !confirmTrialAccountData && <LoadingTablePlaceholder />}
              {!isConfirmTrialAccountLoading && confirmTrialAccountData && (
                <>
                  <SuccessTitle>Successfully confirmed your email!</SuccessTitle>
                  <SuccessText>Log in to your new account to access Ospree</SuccessText>

                  <ButtonContainer href="/login">
                    <Button fullWidth variant="outlined">
                      Login
                    </Button>
                  </ButtonContainer>
                </>
              )}
              {confirmTrialAccountError && <Error>An error occurred in confirming email</Error>}
            </FormContent>
          </FormInnerWrapper>
        </FormWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

// Default Props
TrialEmailConfirmation.defaultProps = {}

// Proptypes Validation
TrialEmailConfirmation.propTypes = {}

export default TrialEmailConfirmation
