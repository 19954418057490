import { authAxios, createAxiosInstance } from 'common/utils/axios'

import { BUSINESS_ENDPOINTS, LOCAL_URL } from 'common/constants/apiUrls'

export const authAxiosLocalServer = createAxiosInstance(LOCAL_URL)

const handler = authAxios

// * VASP Onboarding
export const RequestVasp = async (values) => {
  const response = await handler.post(BUSINESS_ENDPOINTS.REQUEST_VASP, values)
  const { data } = response
  return data
}
export const PushRequestVasp = async (values) => {
  const response = await handler.post(BUSINESS_ENDPOINTS.PUSH_REQUEST_VASP, values)
  const { data } = response
  return data
}
export const RegisterVasp = async ({ token, ...rest }) => {
  const response = await handler.post(`${BUSINESS_ENDPOINTS.REGISTER_VASP}${token}`, { ...rest })
  const { data } = response
  return data
}
export const ApproveVasp = async ({ vasp_id }) => {
  const response = await handler.post(`${BUSINESS_ENDPOINTS.APPROVE_VASP}${vasp_id}`)
  const { data } = response
  return data
}
export const RejectVasp = async ({ vasp_id }) => {
  const response = await handler.post(`${BUSINESS_ENDPOINTS.REJECT_VASP}${vasp_id}`)
  const { data } = response
  return data
}

// * VASP Providers
export const GetAllProviders = async () => {
  const response = await handler.get(BUSINESS_ENDPOINTS.GET_ALL_PROVIDERS)
  const { data } = response
  return data
}
export const GetVaspProviders = async ({ vasp_id }) => {
  const response = await handler.get(`${BUSINESS_ENDPOINTS.GET_VASP_PROVIDERS}${vasp_id}`)
  const { data } = response
  return data
}
export const DeleteVaspProvider = async ({ vasp_id, ...rest }) => {
  const response = await handler.delete(`${BUSINESS_ENDPOINTS.DELETE_VASP_PROVIDERS}${vasp_id}`, { data: { ...rest } })
  const { data } = response
  return data
}
export const AddVaspProvider = async ({ vasp_id, ...rest }) => {
  const response = await handler.post(`${BUSINESS_ENDPOINTS.ADD_VASP_PROVIDERS}${vasp_id}`, { ...rest })
  const { data } = response
  return data
}

// * VASP Users
export const GetVaspUsers = async ({ vasp_id }) => {
  const response = await handler.get(`${BUSINESS_ENDPOINTS.GET_VASP_USERS}${vasp_id}`)
  const { data } = response
  return data
}
export const AddVaspUser = async ({ vasp_id, ...rest }) => {
  const response = await handler.post(`${BUSINESS_ENDPOINTS.ADD_VASP_USER}${vasp_id}`, { ...rest })
  const { data } = response
  return data
}
export const UpdateVaspUser = async ({ vasp_id, ...rest }) => {
  const response = await handler.patch(`${BUSINESS_ENDPOINTS.UPDATE_VASP_USER}${vasp_id}`, { ...rest })
  const { data } = response
  return data
}
export const CreateVaspUserActivation = async ({ user_id, ...rest }) => {
  const response = await handler.post(`${BUSINESS_ENDPOINTS.ADD_VASP_USER}${user_id}`, { ...rest })
  const { data } = response
  return data
}
