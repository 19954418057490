import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  SubHeaderWrapper,
  SubHeaderNavList,
  SubHeaderNavListItem,
  SubHeaderNavListLink,
} from './BusinessDetailsSubHeader.elements'

function BusinessDetailsSubHeader(props) {
  // Hooks
  const { page, setPage } = props

  return (
    <SubHeaderWrapper>
      <SubHeaderNavList>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink
            className={page === 0 && 'active'}
            onClick={() => {
              setPage(0)
            }}
          >
            Details
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>

        <SubHeaderNavListItem>
          <SubHeaderNavListLink
            className={page === 1 && 'active'}
            onClick={() => {
              setPage(1)
            }}
          >
            Users
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>
      </SubHeaderNavList>
    </SubHeaderWrapper>
  )
}

// Default Props
BusinessDetailsSubHeader.defaultProps = {
  page: 0,
  setPage: () => {},
}

// Proptypes Validation
BusinessDetailsSubHeader.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
}
export default BusinessDetailsSubHeader
