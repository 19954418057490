import { useMutation } from 'react-query'

// Services
import { GetVaspProviders } from 'services/api/Business'

export default () => {
  const {
    mutate: getVaspProviders,
    data: getVaspProvidersData,
    error: getVaspProvidersError,
    isLoading: isGetVaspProvidersLoading,
    isSuccess: isGetVaspProvidersSuccess,
    isError: isGetVaspProvidersError,
  } = useMutation(GetVaspProviders)

  return {
    getVaspProviders,
    getVaspProvidersData,
    getVaspProvidersError,
    isGetVaspProvidersLoading,
    isGetVaspProvidersSuccess,
    isGetVaspProvidersError,
  }
}
