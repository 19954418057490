/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

export default function OutIcon(props) {
  return (
    <svg
      width={props?.size || '15'}
      height={props?.size || '15'}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.0368 11.0353V3.96425H3.96569" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.96567 11.0349L11.0367 3.96387" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
