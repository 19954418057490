import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchAllVasps } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './AdminCustomerVaspsTable.elements'

// Views
import { CardTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

import { AdminVaspsTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AdminCustomerVaspsTable(props) {
  // Destructure
  const { filters, ui, actions } = props

  // Store States
  const { vaspDirectoryFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const { setVaspDirectoryFilters, setIsPageTableUpdated } = actions

  // Variables
  const { sort, page, size } = vaspDirectoryFilters

  // States
  const [vaspsDataState, setVaspsDataState] = useState({ items: [], total: 0 })

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const { allVaspsData, getAllVasps, isAllVaspsLoading } = useFetchAllVasps()

  // Functions
  const fetchVasps = () => {
    getAllVasps({
      biz_id: userCredentials.Business_ID,
      sort,
      page,
      size,
      customer_status_id: 1,
    })
  }

  // UseEffects
  useEffect(() => {
    if (allVaspsData) setVaspsDataState(allVaspsData)
  }, [allVaspsData])

  useEffect(() => {
    if (vaspDirectoryFilters || isPageTableUpdated) {
      fetchVasps()
      setIsPageTableUpdated(false)
    }
  }, [vaspDirectoryFilters, isPageTableUpdated])

  return (
    <TableWrapper>
      <CardTable
        totalItems={vaspsDataState.total}
        minWidth={900}
        tableFilters={vaspDirectoryFilters}
        setTableFilters={setVaspDirectoryFilters}
      >
        {vaspsDataState.items.length > 0 && !isAllVaspsLoading ? (
          vaspsDataState.items.map((vaspDetails) => (
            <AdminVaspsTableItem key={vaspDetails?.id} vaspDetails={vaspDetails} isRequestedVasp />
          ))
        ) : (
          <div>
            {isAllVaspsLoading ? (
              <LoadingTablePlaceholder />
            ) : (
              <EmptyTablePlaceholder setFilter={setVaspDirectoryFilters} />
            )}
          </div>
        )}
      </CardTable>
    </TableWrapper>
  )
}

// Default Props
AdminCustomerVaspsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
AdminCustomerVaspsTable.propTypes = {
  filters: PropTypes.shape({
    vaspDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setVaspDirectoryFilters: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,
    setIsVaspDrawerOpen: PropTypes.func,
    setIsEntityVaspDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCustomerVaspsTable)
