import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { connect } from 'react-redux'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  CaseDetailsKYCFormWrapper,
  FormHeader,
  FormInputGroupWrapper,
  FormInputGroupItem,
  PolicyItem,
  ItemDataContainer,
  ItemTitle,
  ItemData,
} from './CaseDetailsMonitoringForm.elements'

// Views
import { Form, Select, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function CaseDetailsMonitoringForm(props) {
  // Destructure
  const { formRef, actions, customerId } = props

  // Store Actions
  const { showAlert } = actions

  // Hooks
  const [policyListStorage, setPolicyListStorage] = useLocalStorage('policies', [])
  const [assignedPolicies, setAssignedPolicies] = useLocalStorage('assignedPolicies', [])

  // States
  const [currentCasePolicies, setCurrentCasePolicies] = useState(null)
  const [currentCasePolicyIds, setCurrentCasePolicyIds] = useState([])
  const [policyOptions, setPolicyOptions] = useState([])
  const [policyOption, setPolicyOption] = useState()

  // Variables
  const initialValues = {}
  const validationSchema = Yup.object().shape({})

  // Functions
  const fetchPolicies = () => {
    const foundPolicies = []
    const foundPolicyList = assignedPolicies.find((item) => item.customerId === customerId)

    if (foundPolicyList) {
      setCurrentCasePolicyIds(foundPolicyList.policies)
      foundPolicyList.policies.map((item) => {
        const foundPolicyItem = policyListStorage.find((policyItem) => policyItem.policy_id === item)
        if (foundPolicyItem) {
          foundPolicies.push(foundPolicyItem)
        }
      })
    }
    setCurrentCasePolicies(foundPolicies || [])
  }

  const updatePolicyList = (policy_id, add) => {
    const foundPolicies = []

    if (add) {
      setCurrentCasePolicyIds((prevState) => {
        const newState = [...prevState, policy_id]
        newState.map((item) => {
          const foundPolicyItem = policyListStorage.find((policyItem) => policyItem.policy_id === item)
          if (foundPolicyItem) foundPolicies.push(foundPolicyItem)
        })
        return newState
      })
    } else if (!add) {
      setCurrentCasePolicyIds((prevState) => {
        const newState = []
        prevState.map((policyItem) => {
          if (policyItem !== policy_id) {
            newState.push(policyItem)
          }
        })
        newState.map((item) => {
          const foundPolicyItem = policyListStorage.find((policyItem) => policyItem.policy_id === item)
          if (foundPolicyItem) foundPolicies.push(foundPolicyItem)
        })
        return newState
      })
    }

    setCurrentCasePolicies(foundPolicies)
  }
  const handleAdd = async () => {
    const foundPolicy = policyListStorage.find((item) => item.policy_id === policyOption)
    const duplicatePolicy = currentCasePolicyIds.find((item) => item === policyOption)

    if (duplicatePolicy) {
      return showAlert({ type: 'error', message: 'Policy already added' })
    }
    if (foundPolicy) {
      updatePolicyList(foundPolicy.policy_id, true)
    }
    if (!foundPolicy) {
      showAlert({ type: 'error', message: 'An error occurred, try again later.' })
    }
  }

  const updateAssignedPolicies = async () => {
    const foundPolicies = assignedPolicies.find((item) => item.customerId === customerId)
    if (!foundPolicies) {
      return setAssignedPolicies([...assignedPolicies, { customerId, policies: currentCasePolicyIds }])
    }
    const newAssignedPolicies = assignedPolicies.map((item) => {
      if (item.customerId === customerId) {
        return { customerId, policies: currentCasePolicyIds }
      }
      return item
    })
    await setAssignedPolicies(newAssignedPolicies)
  }

  // UseEffects
  useEffect(() => {
    // getting the created policies from localstorage
    const filteredPolicies = []
    policyListStorage.map((item) => {
      const newItem = {
        value: item.policy_id,
        label: item.policy_info.policyTitle,
      }
      filteredPolicies.push(newItem)
    })
    setPolicyOptions(filteredPolicies)
    fetchPolicies()
  }, [])

  return (
    <CaseDetailsKYCFormWrapper>
      <Form
        enableReinitialize
        formRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={updateAssignedPolicies}
      >
        <FormHeader>This case is being monitored by the following policies:</FormHeader>
        <FormInputGroupWrapper>
          {currentCasePolicies &&
            currentCasePolicies.map(({ policy_info: { policyTitle, policyDescription, status }, policy_id }) => (
              <PolicyItem key={policy_id}>
                <ItemDataContainer>
                  <ItemTitle>{policyTitle}</ItemTitle>
                  <ItemData>{policyDescription}</ItemData>
                </ItemDataContainer>
                <ItemDataContainer>
                  <ItemTitle color={status === 'active' ? 'green' : 'red'}>{status}</ItemTitle>
                  <Button
                    size="small"
                    color="default"
                    type="button"
                    onClick={() => {
                      updatePolicyList(policy_id, false)
                    }}
                  >
                    Remove
                  </Button>
                </ItemDataContainer>
              </PolicyItem>
            ))}

          <FormInputGroupItem>
            <Select
              placeholder="Select Policy"
              options={policyOptions}
              onChange={(value) => {
                setPolicyOption(value)
              }}
            />
            <Button color="default" type="button" onClick={handleAdd}>
              Add
            </Button>
          </FormInputGroupItem>
        </FormInputGroupWrapper>
      </Form>
    </CaseDetailsKYCFormWrapper>
  )
}

// Default Props
CaseDetailsMonitoringForm.defaultProps = {
  formRef: createRef(),
  actions: {},
  customerId: '',
}

// Proptypes Validation
CaseDetailsMonitoringForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
  }),
  customerId: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetailsMonitoringForm)
