import styled from 'styled-components'

export const TableFilterFormWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  // If there is space then it's for the child
  // If there is no space then it's for the current component
  &#accountAddressTableFilter {
    & > div {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  & #travelruleDateFilter {
    justify-content: center;
    & > div {
      max-width: none !important;
    }
  }
  & #addressDateFilter {
    & > div {
      max-width: none !important;
      padding: 0;
      height: 100%;
      gap: 10px;

      & p {
        font-size: 12px !important;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
`
export const FilterWrapper = styled.div``
export const TravelRuleTableFilterContainer = styled.div`
  transition: 0.3s ease-in-out;
  max-width: ${({ active }) => (active ? '2000px' : '0')};
  max-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  overflow: ${({ active }) => (active ? 'visible' : 'hidden')};
`
export const TravelRuleTableFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  width: 100%;

  & > div {
    margin: 0;
  }
`
