import { useMutation } from 'react-query'

// Services
import { GetAllProviders } from 'services/api/Business'

export default () => {
  const {
    mutate: getAllProviders,
    data: getAllProvidersData,
    error: getAllProvidersError,
    isLoading: isGetAllProvidersLoading,
    isSuccess: isGetAllProvidersSuccess,
    isError: isGetAllProvidersError,
  } = useMutation(GetAllProviders)

  return {
    getAllProviders,
    getAllProvidersData,
    getAllProvidersError,
    isGetAllProvidersLoading,
    isGetAllProvidersSuccess,
    isGetAllProvidersError,
  }
}
