import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Components
import { Wrapper } from './AdminVaspDetails.elements'

// Views
import { Container } from 'views/components'
import { App, Header, Content, PageWrapper, AdminVaspDetails as VaspDetailsWrapper, Sidebar } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AdminVaspDetails() {
  return (
    <App>
      <Sidebar />
      <Header link="/admin/vasps" pageTitle="Vasps" />
      <Content>
        <PageWrapper>
          <Container>
            <Wrapper>
              <VaspDetailsWrapper />
            </Wrapper>
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
AdminVaspDetails.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
AdminVaspDetails.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminVaspDetails)
