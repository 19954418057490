import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchAllTransfers } from 'core/hooks/api'

// Styled Elements
import { TravelRuleTableWrapper } from './AccountTransfersTable.elements'

// Views
import { AccordionTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

// Layouts
import { TravelRuleTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AccountTransfersTable(props) {
  // Destructure
  const { actions, filters, ui } = props
  const { travelRuleFilters } = filters
  const { isPageTableUpdated } = ui
  const { setTravelRuleFilters, setIsPageTableUpdated } = actions

  // Hooks
  const [transfersData, setTransfersData] = useState({ items: [], total: 0 })
  const { getAllTransfers, allTransferData, isAllTransferLoading } = useFetchAllTransfers()

  // Functions
  const fetchData = async () => {
    getAllTransfers(travelRuleFilters)
  }

  // UseEffects
  useEffect(() => fetchData(), [travelRuleFilters])
  useEffect(() => allTransferData && setTransfersData(allTransferData), [allTransferData])
  useEffect(() => {
    if (isPageTableUpdated) {
      fetchData()
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])

  return (
    <TravelRuleTableWrapper>
      <AccordionTable
        minWidth={700}
        totalItems={transfersData.total}
        tableFilters={travelRuleFilters}
        setTableFilters={setTravelRuleFilters}
      >
        {transfersData.items.length > 0 &&
          !isAllTransferLoading &&
          transfersData.items.map((singleData) => <TravelRuleTableItem key={singleData?.id} data={singleData} />)}
        {isAllTransferLoading && <LoadingTablePlaceholder />}
        {transfersData?.items?.length < 1 && !isAllTransferLoading && <EmptyTablePlaceholder />}
      </AccordionTable>
    </TravelRuleTableWrapper>
  )
}

// Default Props
AccountTransfersTable.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
AccountTransfersTable.propTypes = {
  actions: PropTypes.shape({
    setAddressChains: PropTypes.func,
    setTravelRuleFilters: PropTypes.func,
    setIsTransferDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleTransferReportModal: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    travelRuleFilters: PropTypes.instanceOf(Object),
  }),
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountTransfersTable)
