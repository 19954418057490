/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchTransfer } from 'core/hooks/api'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'

// Constants
import {
  STANDARD_DATETIME_FORMAT,
  // STANDARD_DATE_FORMAT
} from 'common/constants/dateFormat'

// Assets
import OutIcon from 'assets/images/out'
import InIcon from 'assets/images/in'
import ArrowLeftIcon from 'assets/images/arrowLeft'
import ArrowRightIcon from 'assets/images/arrowRight'

// Styled Elements
import {
  TableButtonWrapper,
  TableRowWrapper,
  TableColWrapper,
  TableRowContainer,
  TableText,
  TableSubText,
  TablePanelWrapper,
  IconWrapper,
} from './TravelRuleTableItem.elements'

// Views
import { AccordionTableItem, ProgressBar } from 'views/components'
import { TravelRuleTableItemDetails } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function TravelRuleTableItem(props) {
  // Destructure
  const { data, ui, actions } = props
  const { isRecordUpdated } = ui
  const { setIsRecordUpdated } = actions

  // Hooks
  const { getTransfer, transferData, isTransferLoading } = useFetchTransfer()

  // States
  const [barWidth, setBarWidth] = useState('0%')
  const [isExpanded, setIsExpanded] = useState(false)
  const [details, setDetails] = useState({ transfer_status: null, transfer_details: null })
  const [localData, setLocalData] = useState(data)
  const {
    direction_id,
    dti_name_short,
    amount,
    amount_usd,
    last_modified,
    in_account_name,
    account_name,
    out_account_name,
    transfer_status_id,
    transfer_status_desc,
    id,
    protocol_code,
    in_address_is_self_hosted,
    out_address_is_self_hosted,
    table_data,
  } = localData
  // NOTE: this is a temporary fix. API should return `direction_id` in the response or better state handling.
  // currently when updating status of transfer, 'direction_id' is not included in the response,
  // so we need to use the 'table_data' to get the direction_id in this scenario.
  const actual_direction_id = direction_id || table_data?.direction_id

  // Functions
  const fetchData = () => getTransfer({ transfer_id: id })

  // useEffects
  useEffect(() => isExpanded && !details.transfer_status && !details.transfer_details && fetchData(), [isExpanded])
  useEffect(() => transferData && setDetails(transferData), [transferData])
  useEffect(() => {
    if (isRecordUpdated && isExpanded) {
      fetchData()
      setIsRecordUpdated(false)
    }
  }, [isRecordUpdated])
  useEffect(() => {
    if (transfer_status_id + 1 === 1) return setBarWidth('20%')
    if (transfer_status_id + 1 === 2) return setBarWidth('40%')
    if (transfer_status_id + 1 === 3) return setBarWidth('60%')
    if (transfer_status_id + 1 === 4) return setBarWidth('80%')
    if (transfer_status_id + 1 === 5) return setBarWidth('100%')
    if (transfer_status_id < 0) return setBarWidth('100%')
  }, [localData])
  useEffect(() => data && setLocalData(data), [data])
  return (
    <AccordionTableItem
      setIsExpanded={setIsExpanded}
      expandPanel={isExpanded}
      button={
        <TableButtonWrapper>
          <TableRowWrapper isExpanded={isExpanded}>
            <TableRowContainer>
              {actual_direction_id === 1 ? (
                <IconWrapper>
                  <InIcon size={24} />
                  <TableSubText>In</TableSubText>
                </IconWrapper>
              ) : (
                <IconWrapper>
                  <OutIcon size={24} />
                  <TableSubText>Out</TableSubText>
                </IconWrapper>
              )}
              <TableColWrapper>
                <TableRowContainer style={{ gap: '10px' }}>
                  <TableText overflowHidden>{in_account_name || account_name || '-'}</TableText>
                  {actual_direction_id === 1 ? <ArrowLeftIcon size={24} /> : <ArrowRightIcon size={24} />}
                  <TableText overflowHidden>{out_account_name || '-'}</TableText>
                </TableRowContainer>
                <TableSubText>
                  {protocol_code?.toUpperCase()} | {in_address_is_self_hosted ? 'Self-Hosted' : 'VASP'} to{' '}
                  {out_address_is_self_hosted ? 'Self-Hosted' : 'VASP'}
                </TableSubText>
                {/* <TableSubText>{direction_id === 1 ? 'Received' : 'Sent'}</TableSubText> */}
              </TableColWrapper>
            </TableRowContainer>

            <TableRowContainer>
              <TableText>{moment(last_modified).format(STANDARD_DATETIME_FORMAT)}</TableText>
            </TableRowContainer>
          </TableRowWrapper>

          <TableRowWrapper isExpanded={isExpanded}>
            <TableRowContainer>
              <TableText>{transfer_status_desc}</TableText>
              <ProgressBar failed={transfer_status_id < -1} width={barWidth} />
            </TableRowContainer>

            <TableRowContainer>
              <TableColWrapper>
                <TableText className="transfer_item_amount">
                  {dti_name_short?.[0]}: {amount}
                </TableText>
                <TableSubText>{`USD ${numberWithCommas(Math.round(amount_usd * 100) / 100)}`}</TableSubText>
              </TableColWrapper>
            </TableRowContainer>
          </TableRowWrapper>
        </TableButtonWrapper>
      }
      panel={
        <TablePanelWrapper>
          {details && isExpanded && (
            <TravelRuleTableItemDetails
              key={localData}
              isLoading={isTransferLoading}
              transfer_status={details.transfer_status}
              transfer_details={details.transfer_details}
              table_data={localData}
              setTableData={setLocalData}
              getTransfer={getTransfer}
            />
          )}
        </TablePanelWrapper>
      }
    />
  )
}

// Default Props
TravelRuleTableItem.defaultProps = {
  actions: {},
  data: {
    direction_id: 0,
    dti_name_short: [''],
    amount: '',
    amount_usd: 0,
    last_modified: '',
    in_account_name: '',
    account_name: '',
    out_account_name: '',
    transfer_status_id: 0,
    transfer_status_desc: '',
    id: '',
    protocol_code: '',
    in_address_is_self_hosted: false,
    out_address_is_self_hosted: false,
  },
}

// Proptypes Validation
TravelRuleTableItem.propTypes = {
  actions: PropTypes.shape({
    setIsRecordUpdated: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isRecordUpdated: PropTypes.bool,
  }),
  data: PropTypes.shape({
    direction_id: PropTypes.number,
    dti_name_short: PropTypes.arrayOf(PropTypes.string),
    amount: PropTypes.string,
    amount_usd: PropTypes.number,
    last_modified: PropTypes.string,
    in_account_name: PropTypes.string,
    account_name: PropTypes.string,
    out_account_name: PropTypes.string,
    transfer_status_id: PropTypes.number,
    transfer_status_desc: PropTypes.string,
    protocol_code: PropTypes.string,
    in_address_is_self_hosted: PropTypes.bool,
    out_address_is_self_hosted: PropTypes.bool,
    id: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelRuleTableItem)
