import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './ApproveVaspModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Hooks
import { useApproveVasp } from 'core/hooks/api'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function ApproveVaspModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isApproveVaspModalOpen, approveVaspDetails } = ui

  // Store Actions
  const { setApproveVaspModal, showAlert, setIsPageTableUpdated } = actions

  // Hooks
  const { approveVasp, approveVaspData, isApproveVaspLoading, isApproveVaspError, isApproveVaspSuccess } =
    useApproveVasp()

  // Functions
  const toggleModal = () => {
    setApproveVaspModal()
  }
  const handleNotify = () => {
    approveVasp({ vasp_id: approveVaspDetails.vasp_id })
  }

  // UseEffect
  useEffect(() => {
    if (approveVaspData && !isApproveVaspError) {
      showAlert({ type: 'success', message: 'Successfully approved VASP' })
      toggleModal()
      setIsPageTableUpdated(true)
    }
  }, [approveVaspData])
  useEffect(() => {
    if (isApproveVaspError) {
      showAlert({ type: 'error', message: 'An error occurred in approving VASP' })
      toggleModal()
    }
  }, [isApproveVaspError])
  return (
    <Modal isOpen={isApproveVaspModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Notify Vasp
      </ModalHeader>
      <ModalBody>
        {isApproveVaspLoading && <ModalBodyWrapper>Approving...</ModalBodyWrapper>}
        {approveVaspDetails?.vasp_id && !isApproveVaspLoading && !isApproveVaspError && !isApproveVaspSuccess && (
          <ModalBodyWrapper>
            Are you sure you want to approve this VASP: {approveVaspDetails.vasp_name}?
          </ModalBodyWrapper>
        )}
        {!isApproveVaspLoading && isApproveVaspError && !isApproveVaspSuccess && (
          <ModalBodyWrapper>An error occurred in approving VASP, please try again later.</ModalBodyWrapper>
        )}
        {!isApproveVaspLoading && isApproveVaspSuccess && (
          <ModalBodyWrapper>Congratulations! VASP has been approved</ModalBodyWrapper>
        )}
      </ModalBody>
      {!isApproveVaspError && !isApproveVaspSuccess && (
        <ModalFooter>
          <ModalFooterWrapper>
            <ModalFooterButton onClick={toggleModal}>
              <Button color="secondary" disabled={isApproveVaspLoading}>
                Cancel
              </Button>
            </ModalFooterButton>
            <ModalFooterButton onClick={handleNotify}>
              <Button color="default" disabled={isApproveVaspLoading}>
                Approve
              </Button>
            </ModalFooterButton>
          </ModalFooterWrapper>
        </ModalFooter>
      )}
    </Modal>
  )
}

// Default Props
ApproveVaspModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
ApproveVaspModal.propTypes = {
  ui: PropTypes.shape({
    isApproveVaspModalOpen: PropTypes.bool,
    approveVaspDetails: PropTypes.shape({
      vasp_name: PropTypes.string,
      vasp_id: PropTypes.string,
    }),
  }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  address: PropTypes.shape({
    addressRemove: PropTypes.shape({ address_id: PropTypes.string }),
  }),
  actions: PropTypes.shape({
    setApproveVaspModal: PropTypes.func,
    showAlert: PropTypes.func,
    setAddressRemove: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveVaspModal)
