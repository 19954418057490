import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import { Wrapper, GeneralWrapper, RowWrapper, ColWrapper, Title, Text } from './BusinessDetails.elements'

// Components
import { Container, Button } from 'views/components'

// layouts
import {
  App,
  Header,
  Sidebar,
  Content,
  PageWrapper,
  BusinessDetailsSubHeader,
  UsersTable,
  BusinessDetailsTab,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function BusinessDetails(props) {
  const { actions } = props

  // destructuring
  const { setIsUserDrawerOpen } = actions

  // State
  const [page, setPage] = useState(0)
  const [usersLength, setUsersLength] = useState(0)

  // hooks
  const history = useHistory()
  const [currentUser] = useLocalStorage('userCredentials', {})

  useEffect(() => {
    if (currentUser?.role_id !== 1) {
      history.push('/')
    }
  }, [])
  return (
    <App>
      <Sidebar />
      <Header pageTitle="Business Details" />
      <Content>
        <PageWrapper>
          <Container>
            <Wrapper>
              <BusinessDetailsSubHeader page={page} setPage={setPage} />
              {page === 0 && (
                <GeneralWrapper>
                  <BusinessDetailsTab />
                </GeneralWrapper>
              )}
              {page === 1 && (
                <GeneralWrapper>
                  <RowWrapper>
                    <ColWrapper>
                      <Title>Created Users - ({usersLength} / 5 Max Users)</Title>
                      <Text>
                        The list of users under the company. Managers can create users and update their email and roles.
                      </Text>
                    </ColWrapper>
                    <Button
                      onClick={() => {
                        setIsUserDrawerOpen(true)
                      }}
                    >
                      Create User
                    </Button>
                  </RowWrapper>
                  <UsersTable setUsersLength={setUsersLength} />
                </GeneralWrapper>
              )}
            </Wrapper>
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
BusinessDetails.defaultProps = {
  actions: {},
}

// Proptypes Validation
BusinessDetails.propTypes = {
  actions: PropTypes.shape({
    setIsUserDrawerOpen: PropTypes.func,
    setIsIndividualUserDrawerOpen: PropTypes.func,
    setIsEntityUserDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetails)
