import styled from 'styled-components'

export const TextSlicedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`
export const TextContainer = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const SliceBlock = styled.div`
  height: 7px;
  width: 7px;
  background-color: ${({ theme }) => theme.colors.darkGray};
  border-radius: 2px;
`
