import React from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Constants
import { ROLE_OPTIONS } from 'common/constants/formOptions'

// Styled Elements
import { FormInputGroupItem, FormGroupWrapper, TablePanelWrapper } from './AdminUserDetailsForm.elements'

// Views
import { Form, FormTextField, FormSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AdminUserDetailsForm(props) {
  // Destructure
  const { values, viewMode, formRef } = props

  // Variables
  const BooleanOptions = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ]
  const validationSchema = Yup.object().shape({
    user_id: Yup.string().nullable(),
    role_id: Yup.number(),
    email: Yup.string().email().nullable(),
    name_first: Yup.string().nullable(),
    name_last: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    timezone_code: Yup.string().nullable(),
    business_id: Yup.string().nullable(),

    is_active: Yup.bool().nullable(),
    is_verified: Yup.bool().nullable(),
    is_external: Yup.bool().nullable(),
    has_2fa: Yup.bool().nullable(),
  })

  return (
    <Form formRef={formRef} initialValues={values} validationSchema={validationSchema}>
      <TablePanelWrapper>
        <FormGroupWrapper>
          <FormInputGroupItem>
            <FormSelect disabled={viewMode} label="User Role" options={ROLE_OPTIONS} name="role_id" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField disabled={viewMode} label="First Name" name="name_first" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField disabled={viewMode} label="Last Name" name="name_last" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField disabled={viewMode} label="Email" type="email" name="email" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField disabled={viewMode} label="Phone" name="phone" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField disabled={viewMode} label="Timezone Code" name="timezone_code" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormSelect disabled={viewMode} label="Is Active" options={BooleanOptions} name="is_active" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormSelect disabled label="Is Verified" options={BooleanOptions} name="is_verified" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormSelect disabled={viewMode} label="Is External" options={BooleanOptions} name="is_external" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormSelect disabled label="Has Two-Factor Authentication" options={BooleanOptions} name="has_2fa" />
          </FormInputGroupItem>
        </FormGroupWrapper>
      </TablePanelWrapper>
    </Form>
  )
}

// Default Props
AdminUserDetailsForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {},
  viewMode: true,
}

// Proptypes Validation
AdminUserDetailsForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.func,
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    user_id: PropTypes.string,
    role_id: PropTypes.number,
    email: PropTypes.string,
    name_first: PropTypes.string,
    name_last: PropTypes.string,
    phone: PropTypes.string,
    timezone_code: PropTypes.string,
    business_id: PropTypes.string,

    is_active: PropTypes.bool,
    is_verified: PropTypes.bool,
    is_external: PropTypes.bool,
    has_2fa: PropTypes.bool,
  }),
  viewMode: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserDetailsForm)
