import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useUpdatePassword } from 'core/hooks/api'

// Styled Elements
import {
  FormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  // FormInputTimezoneGroupItem,
  FormTitle,
  // ErrorTitle,
  ButtonWrapper,
} from './UpdatePasswordForm.elements'

// Views
import { Form, FormTextField, LoaderCircle, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UpdatePasswordForm(props) {
  // Destructure
  const { actions } = props

  // Store Actions
  const { showAlert } = actions

  // Hooks
  const { updatePassword, updatePasswordData, updatePasswordError, isUpdatePasswordLoading } = useUpdatePassword()

  // Ref
  const formRef = useRef()

  // Local State
  const [formValues] = useState({
    old_password: '',
    new_password: '',
    confirm_password: '',
  })

  // Variables
  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required('Old Password is required'),
    new_password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirm_password: Yup.string().when('new_password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('new_password')], 'Both password need to be the same'),
    }),
  })

  // Functions
  const handleOnSubmit = (values) => {
    if (values?.old_password) {
      updatePassword({ old_password: values?.old_password, new_password: values?.new_password })
    }
  }

  // UseEffects
  useEffect(() => {
    if (updatePasswordError) showAlert({ type: 'error', message: 'An error occurred in updating password' })
  }, [updatePasswordError])
  return (
    <FormWrapper>
      {updatePasswordData && !updatePasswordError && <FormTitle>Successfully updated password.</FormTitle>}
      {updatePasswordData && !updatePasswordError && (
        <ButtonWrapper href="/">
          <Button fullWidth type="button">
            Go back
          </Button>
        </ButtonWrapper>
      )}
      {isUpdatePasswordLoading && <LoaderCircle />}
      {!updatePasswordData && !isUpdatePasswordLoading && (
        <Form
          initialValues={formValues}
          formRef={formRef}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField label="Old Password" type="password" name="old_password" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="New Password" type="password" name="new_password" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Confirm Password" type="password" name="confirm_password" />
            </FormInputGroupItem>
          </FormGroupWrapper>

          <Button fullWidth type="submit" disabled={isUpdatePasswordLoading}>
            {isUpdatePasswordLoading ? 'Updating...' : 'Update'}
          </Button>
          <ButtonWrapper href="/">
            <Button fullWidth variant="outlined" type="button">
              Go back
            </Button>
          </ButtonWrapper>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
UpdatePasswordForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
}

// Proptypes Validation
UpdatePasswordForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    TIMEZONES: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.func,
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordForm)
