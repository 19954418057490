import { authAxios, createAxiosInstance } from 'common/utils/axios'
import { REPORT_ENDPOINTS } from '../../common/constants/apiUrls'

import { LOCAL_URL } from 'common/constants/apiUrls'

const { GET_REPORTS, GET_REPORT, GET_REPORTS_TRANSACTION, GET_REPORTS_TRAVELRULE } = REPORT_ENDPOINTS

export const authAxiosLocalServer = createAxiosInstance(LOCAL_URL)

const handler = authAxios

export const GetReports = async ({ start_range, end_range, sort, page, size }) => {
  const response = await handler.get(GET_REPORTS, {
    params: {
      start_range,
      end_range,
      sort,
      page,
      size,
    },
  })

  const { data } = response.data

  return data
}
export const GetReport = async ({ format, report_id }) => {
  const response = await handler.get(`${GET_REPORT}/${report_id}`, {
    params: { format },
  })

  return response.data
}

export const GetReportsTransaction = async ({ start_range, end_range, sort, page, size }) => {
  const response = await handler.get(GET_REPORTS_TRANSACTION, {
    params: {
      start_range,
      end_range,
      sort,
      page,
      size,
    },
  })

  const { data } = response.data

  return data
}

export const GetReportsTravelRule = async ({ page, size }) => {
  const response = await handler.get(GET_REPORTS_TRAVELRULE, {
    params: {
      page,
      size,
    },
  })

  const { data } = response.data

  return data
}

export const DownloadReportsTravelRule = async ({ id, format }) => {
  const response = await handler.get(`${GET_REPORTS_TRAVELRULE}/${id}`, { params: { format } })

  const { data } = response

  return data
}
