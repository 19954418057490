import styled from 'styled-components'

export const ButtonFilterWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${({ label }) => (label ? '-5px;' : '40px;')};
  /* background: ${({ theme }) => theme.colors.white};

  border-radius: ${({ open }) => (open ? '10px 10px 0 0' : '10px')};
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.07); */
`
export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const FilterContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  max-height: 35.5px;

  opacity: 1;
  transition: 0.3s;

  max-width: ${({ collapseFilter }) => (collapseFilter ? '0px' : '2000px')};
  overflow: ${({ collapseFilter }) => (collapseFilter ? 'hidden' : 'visible')};
`
