import { useMutation } from 'react-query'

// Services
import { PushRequestVasp } from 'services/api/Business'

export default () => {
  const {
    mutate: pushRequestVasp,
    data: pushRequestVaspData,
    error: pushRequestVaspError,
    isLoading: isPushRequestVaspLoading,
    isSuccess: isPushRequestVaspSuccess,
    isError: isPushRequestVaspError,
  } = useMutation(PushRequestVasp)

  return {
    pushRequestVasp,
    pushRequestVaspData,
    pushRequestVaspError,
    isPushRequestVaspLoading,
    isPushRequestVaspSuccess,
    isPushRequestVaspError,
  }
}
