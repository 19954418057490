import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Utils
import { removeSameValues } from 'common/utils/removeSameValues'

// Store
import { actions } from 'core/store'

// Constants
import { ROLE_MAPPING } from 'common/constants/formOptions'

// Hooks
import { useUpdateUser, useCreateUserActivationLink } from 'core/hooks/api'

// Styled Elements
import {
  FormWrapper,
  TableButtonWrapper,
  TableRowWrapper,
  ButtonWrapper,
  Title,
  SubTitle,
  ColWrapper,
} from './UsersTableItem.elements'

// Views
import { UserDetailsForm } from 'views/layouts'
import { AccordionTableItem, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function UsersTabItem(props) {
  // Destructure
  const { actions, values } = props

  // Store Actions
  const { showAlert, toggleDeactivateUserModal, setUserToDeact } = actions
  const { updateUser, updateUserData, updateUserError, isUpdateUserLoading, isUpdateUserSuccess, isUpdateUserError } =
    useUpdateUser()
  const {
    createUserActivationLink,
    createUserActivationLinkError,
    isCreateUserActivationLinkLoading,
    isCreateUserActivationLinkSuccess,
  } = useCreateUserActivationLink()

  // Variables
  const isLoading = isCreateUserActivationLinkLoading || isUpdateUserLoading

  // state
  const [initialData, setInitialData] = useState()
  const [viewMode, setViewMode] = useState(true)
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false)

  // Refs
  const formRef = useRef()

  // Functions
  const handleCancel = () => {
    setViewMode(true)
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }
  const handleSave = (initial) => {
    const finalValues = removeSameValues(formRef?.current?.values, initial)
    delete finalValues.is_active
    delete finalValues.is_verified

    updateUser({ user_id: initialData?.id, ...finalValues })
  }
  const handleSuccess = () => {
    if (formRef.current) {
      formRef.current.resetForm({ values: updateUserData })
    }
    setInitialData(updateUserData)
    showAlert({ type: 'success', message: 'Successfully updated record' })
    setViewMode(true)
  }
  const handleFailure = () => {
    if (updateUserError?.response?.data?.context) {
      showAlert({ type: 'error', message: updateUserError?.response?.data?.context })
    } else {
      showAlert({ type: 'error', message: 'An error occurred in updating record' })
    }
  }

  // UseEffects
  useEffect(() => {
    setInitialData(values)
    if (formRef.current) formRef.current.values = values
  }, [values])
  useEffect(() => {
    if (isUpdateUserSuccess) handleSuccess()
  }, [isUpdateUserSuccess])
  useEffect(() => {
    if (isUpdateUserError) handleFailure()
  }, [isUpdateUserError])
  useEffect(() => {
    if (createUserActivationLinkError) {
      showAlert({ type: 'error', message: 'An error occurred in sending activation link' })
    }
  }, [createUserActivationLinkError])
  useEffect(() => {
    if (isCreateUserActivationLinkSuccess) {
      showAlert({ type: 'success', message: 'Successfully sent activation link' })
    }
  }, [isCreateUserActivationLinkSuccess])
  return (
    <FormWrapper>
      {initialData && (
        <AccordionTableItem
          setIsExpanded={(val) => {
            setIsDetailsExpanded(val)
          }}
          expandPanel={isDetailsExpanded}
          button={
            <TableButtonWrapper>
              <TableRowWrapper isExpanded={isDetailsExpanded}>
                <ColWrapper>
                  <Title>
                    {`${initialData?.name_first || 'First Name'} ${initialData?.name_last || 'Last Name'} `}
                  </Title>
                  <SubTitle>Name of User</SubTitle>
                </ColWrapper>
                <ColWrapper>
                  <Title>{`${ROLE_MAPPING?.[initialData?.role_id]?.label || 'User Role'}`}</Title>
                  <SubTitle>User Role</SubTitle>
                </ColWrapper>
                <ColWrapper>
                  <Title>{`${initialData?.is_active ? 'Active' : 'Inactive'}`}</Title>
                  <SubTitle>Status</SubTitle>
                </ColWrapper>
              </TableRowWrapper>
            </TableButtonWrapper>
          }
          panel={
            <>
              {initialData && <UserDetailsForm formRef={formRef} values={initialData} viewMode={viewMode} />}
              <ButtonWrapper>
                {initialData?.is_active === true && (
                  <Button
                    onClick={() => {
                      toggleDeactivateUserModal()
                      setUserToDeact({ user_id: initialData?.id, is_active: false })
                    }}
                    disabled={isLoading}
                    color="secondary"
                  >
                    Deactivate User
                  </Button>
                )}
                {initialData?.is_active === false && (
                  <Button onClick={() => createUserActivationLink({ user_id: initialData?.id })} disabled={isLoading}>
                    Send Activation
                  </Button>
                )}
                {viewMode && (
                  <Button color="primary" onClick={() => setViewMode(false)}>
                    Edit
                  </Button>
                )}
                {!viewMode && (
                  <>
                    <Button variant="outlined" onClick={handleCancel} disabled={isLoading}>
                      Cancel
                    </Button>
                    <Button onClick={() => handleSave(initialData)} disabled={isLoading}>
                      Save
                    </Button>
                  </>
                )}
              </ButtonWrapper>
            </>
          }
        />
      )}
    </FormWrapper>
  )
}

// Default Props
UsersTabItem.defaultProps = {
  actions: {},
  values: {},
}

// Proptypes Validation
UsersTabItem.propTypes = {
  values: PropTypes.shape({}),
  actions: PropTypes.shape({
    setUserToDeact: PropTypes.func,
    toggleDeactivateUserModal: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTabItem)
