import { authAxios, createAxiosInstance } from 'common/utils/axios'

import { TRAVEL_RULE_ENDPOINTS, TRANSFER_SERVICE_URL } from 'common/constants/apiUrls'

export const authAxiosLocalServer = createAxiosInstance(TRANSFER_SERVICE_URL)

const handler = authAxios

export const GetAssetConversion = async (values) => {
  const response = await handler.get(TRAVEL_RULE_ENDPOINTS.GET_ASSET_CONVERSION, {
    params: { ...values },
  })

  const { data } = response

  return data
}

export const GetAllTransfers = async (values) => {
  const response = await handler.get(TRAVEL_RULE_ENDPOINTS.GET_ALL_TRANSFERS, {
    params: { ...values },
  })

  const { data } = response

  return data
}

export const GetAccountTransferSummary = async (values) => {
  const response = await handler.get(TRAVEL_RULE_ENDPOINTS.GET_ACCOUNT_SUMMARY, {
    params: { ...values },
  })

  const { data } = response

  return data
}

export const GetTransfer = async ({ transfer_id }) => {
  const response = await handler.get(`${TRAVEL_RULE_ENDPOINTS.GET_TRANSFER}${transfer_id}`)

  const { data } = response

  return data
}
export const SearchTransfer = async (values) => {
  const response = await handler.get(TRAVEL_RULE_ENDPOINTS.SEARCH_TRANSFER, {
    params: { ...values },
  })

  const { data } = response

  return data
}

export const GetRecipientVasp = async ({ out_hash_address, asset_code }) => {
  const response = await handler.get(`${TRAVEL_RULE_ENDPOINTS.GET_RECIPIENT_VASP}`, {
    params: { out_hash_address, asset_code },
  })

  const { data } = response

  return data
}

export const CreateTransfer = async (values) => {
  const response = await handler.post(TRAVEL_RULE_ENDPOINTS.CREATE_TRANSFER, values)

  const { data } = response

  return data
}

export const UpdateTransfer = async ({ transfer_id, status_id }) => {
  const response = await handler.patch(`${TRAVEL_RULE_ENDPOINTS.UPDATE_TRANSFER}${transfer_id}/status/${status_id}`)

  const { data } = response

  return data
}

export const CreateTransferReport = async (values) => {
  const response = await handler.post(TRAVEL_RULE_ENDPOINTS.POST_TRANSFER_REPORT, values)

  const { data } = response

  return data
}
