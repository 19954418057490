/* eslint-disable max-len */
import React from 'react'

export default function CheckIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.6899 2.9707V22.9707C24.6899 24.0707 23.7899 24.9707 22.6899 24.9707H13.6899C13.1399 24.9707 12.6899 24.5207 12.6899 23.9707C12.6899 23.4207 13.1399 22.9707 13.6899 22.9707H22.6899V2.9707H2.68994V14.9707C2.68994 15.5207 2.23994 15.9707 1.68994 15.9707C1.13994 15.9707 0.689941 15.5207 0.689941 14.9707V2.9707C0.689941 1.8707 1.58994 0.970703 2.68994 0.970703H22.6899C23.7899 0.970703 24.6899 1.8707 24.6899 2.9707ZM12.3999 16.2707C12.0099 15.8807 11.3799 15.8807 10.9899 16.2707L4.69995 22.5607L2.40994 20.2707C2.01994 19.8807 1.38993 19.8807 0.989929 20.2707C0.589929 20.6607 0.599929 21.2907 0.989929 21.6907L3.98993 24.6907C4.37993 25.0807 5.00993 25.0807 5.39993 24.6907L12.3999 17.6907C12.7899 17.3007 12.7899 16.6707 12.3999 16.2807V16.2707Z"
        fill="#FFFFFF"
      />
    </svg>
  )
}
