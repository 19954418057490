import { authAxios, createAxiosInstance } from 'common/utils/axios'

import { GLEIF_ENDPOINTS, LOCAL_URL } from 'common/constants/apiUrls'

export const authAxiosLocalServer = createAxiosInstance(LOCAL_URL)

const handler = authAxios

export const GetGleif = async () => {
  const response = await handler.get(GLEIF_ENDPOINTS.GET_GLEIF)
  const { data } = response
  return data
}
