/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import { ExternalLinkIcon, HelpIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Text,
  Title,
  RowWrapper,
  TextRowContainer,
  BlockContainer,
  FlowWrapper,
  ExternalLink,
  BlurWrapper,
} from '../../AddressesTableItemDetails.elements'

// Components
import { TextLoading, SkeletonText } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function LinkedAccount(props) {
  // Destructure
  const { actions, accountDataProp, setAccountDataProp, isLoading, isFetchError, address } = props
  const { addressLinkData } = address

  // Store
  const { toggleInfoModal, setInfoModalDetails, showAlert, setAddressLinkData } = actions

  // Functions
  const handleToggleInfo = (title, content) => {
    setInfoModalDetails({ title, content })
    toggleInfoModal(true)
  }

  // UseEffects
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (isFetchError) showAlert({ type: 'error', message: 'An error occurred in fetching linkage' })
  }, [isFetchError])
  useEffect(() => {
    if (addressLinkData) {
      setAddressLinkData()
      setAccountDataProp(addressLinkData)
    }
  }, [addressLinkData])
  return (
    <FlowWrapper>
      <RowWrapper
        style={{
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '20px 10px 10px 10px',
          cursor: 'pointer',
          width: 'fit-content',
        }}
        onClick={() => {
          handleToggleInfo(
            'Account Information',
            // eslint-disable-next-line max-len
            'Identifies the owner or controller of an account, including its type, linked addresses, and compliance status.'
          )
        }}
      >
        <Title style={{ fontWeight: '600', fontSize: '15px', cursor: 'pointer', margin: 0 }}>Account Information</Title>
        <HelpIcon size={16} />
      </RowWrapper>
      <BlockContainer style={{ padding: '10px', borderWidth: 2 }}>
        {!isLoading && accountDataProp && (
          <RowWrapper>
            <BlockContainer>
              <TextRowContainer>
                <Text>Account ID: </Text>
                <Text>
                  <ExternalLink
                    exact
                    target="_blank"
                    to={
                      accountDataProp?.is_recipient
                        ? `/directory/recipients/details/${accountDataProp.id}`
                        : `/directory/accounts/details/${accountDataProp.id}`
                    }
                  >
                    {accountDataProp.reference_id}
                    <ExternalLinkIcon />
                  </ExternalLink>
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>Account Type: </Text>
                <Text>{accountDataProp.account_type_id === 1 ? 'Individual' : 'Entity'}</Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>Status: </Text>
                <Text>{accountDataProp.status_id === 1 ? 'Active' : 'Inactive'}</Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>Email: </Text>
                <Text>{accountDataProp.email}</Text>
              </TextRowContainer>
            </BlockContainer>

            <BlockContainer>
              <TextRowContainer>
                <Text>{accountDataProp.account_type_id === 1 ? 'First Name' : 'Business Name'}: </Text>
                <Text>
                  {accountDataProp.account_type_id === 1 ? accountDataProp.name_first : accountDataProp.name_business}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>{accountDataProp.account_type_id === 1 ? 'Last Name' : 'Legal Name'}: </Text>
                <Text>
                  {accountDataProp.account_type_id === 1 ? accountDataProp.name_last : accountDataProp.name_legal}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>{accountDataProp.account_type_id === 1 ? 'Nationality' : 'Country of Incorporation'}: </Text>
                <Text>{accountDataProp?.display_country && accountDataProp.display_country}</Text>
              </TextRowContainer>
              {accountDataProp.account_type_id === 1 && (
                <TextRowContainer>
                  <Text>Date of Birth: </Text>
                  <Text>{accountDataProp?.date_of_birth && accountDataProp.date_of_birth}</Text>
                </TextRowContainer>
              )}
            </BlockContainer>

            <BlockContainer>
              <TextRowContainer>
                <Text>Street: </Text>
                <Text>{accountDataProp.address_street_name}</Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>City: </Text>
                <Text>{accountDataProp.address_city}</Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>Region: </Text>
                <Text>{accountDataProp.address_region}</Text>
              </TextRowContainer>
              <TextRowContainer>
                <Text>Postcode: </Text>
                <Text>{accountDataProp.address_postcode}</Text>
              </TextRowContainer>
            </BlockContainer>
          </RowWrapper>
        )}

        {isLoading && !accountDataProp && (
          <RowWrapper>
            <BlockContainer>
              <TextRowContainer>
                <Text>Account ID: </Text>
                <TextLoading width={180} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Account Type: </Text>
                <TextLoading width={120} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Status: </Text>
                <TextLoading width={80} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Email: </Text>
                <TextLoading width={150} />
              </TextRowContainer>
            </BlockContainer>

            <BlockContainer>
              <TextRowContainer>
                <TextLoading width={120} height={16} />
                <TextLoading width={130} />
              </TextRowContainer>
              <TextRowContainer>
                <TextLoading width={120} height={16} />
                <TextLoading width={140} />
              </TextRowContainer>
              <TextRowContainer>
                <TextLoading width={120} height={16} />
                <TextLoading width={100} />
              </TextRowContainer>
              <TextRowContainer>
                <TextLoading width={120} height={16} />
                <TextLoading width={150} />
              </TextRowContainer>
            </BlockContainer>

            <BlockContainer>
              <TextRowContainer>
                <Text>Street: </Text>
                <TextLoading width={180} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>City: </Text>
                <TextLoading width={180} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Region: </Text>
                <TextLoading width={180} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Postcode: </Text>
                <TextLoading width={180} />
              </TextRowContainer>
            </BlockContainer>
          </RowWrapper>
        )}

        {!isLoading && !accountDataProp && (
          <RowWrapper style={{ position: 'relative' }}>
            <BlurWrapper>
              <Title>Address is not Linked to an Account</Title>
            </BlurWrapper>
            <BlockContainer>
              <TextRowContainer>
                <Text>Account ID: </Text>
                <SkeletonText width={180} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Account Type: </Text>
                <SkeletonText width={120} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Status: </Text>
                <SkeletonText width={80} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Email: </Text>
                <SkeletonText width={150} />
              </TextRowContainer>
            </BlockContainer>

            <BlockContainer>
              <TextRowContainer>
                <SkeletonText width={120} height={16} />
                <SkeletonText width={130} />
              </TextRowContainer>
              <TextRowContainer>
                <SkeletonText width={120} height={16} />
                <SkeletonText width={140} />
              </TextRowContainer>
              <TextRowContainer>
                <SkeletonText width={120} height={16} />
                <SkeletonText width={100} />
              </TextRowContainer>
              <TextRowContainer>
                <SkeletonText width={120} height={16} />
                <SkeletonText width={150} />
              </TextRowContainer>
            </BlockContainer>

            <BlockContainer>
              <TextRowContainer>
                <Text>Street: </Text>
                <SkeletonText width={180} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>City: </Text>
                <SkeletonText width={180} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Region: </Text>
                <SkeletonText width={180} />
              </TextRowContainer>
              <TextRowContainer>
                <Text>Postcode: </Text>
                <SkeletonText width={180} />
              </TextRowContainer>
            </BlockContainer>
          </RowWrapper>
        )}
      </BlockContainer>
    </FlowWrapper>
  )
}

// Default Props
LinkedAccount.defaultProps = {
  actions: {},
  address_data: {},
  address: {},
}

// Proptypes Validation
LinkedAccount.propTypes = {
  setAccountDataProp: PropTypes.func,
  actions: PropTypes.shape({
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
    showAlert: PropTypes.func,
    setAddressLinkData: PropTypes.func,
  }),
  address: PropTypes.shape({
    addressLinkData: PropTypes.shape({}),
  }),
  accountDataProp: PropTypes.shape({
    id: PropTypes.string,
    reference_id: PropTypes.string,
    business_id: PropTypes.string,
    account_type_id: PropTypes.number,
    name_first: PropTypes.string,
    name_middle: PropTypes.string,
    name_last: PropTypes.string,
    date_of_birth: PropTypes.string,
    nationality_code: PropTypes.string,
    display_country: PropTypes.string,
    name_legal: PropTypes.string,
    name_business: PropTypes.string,
    inc_country_code: PropTypes.string,
    inc_date: PropTypes.string,
    email: PropTypes.string,
    address_street_name: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    address_country_code: PropTypes.string,
    primary_activity_code: PropTypes.number,
    account_risk_value: PropTypes.number,
    sanction_id: PropTypes.number,
    pep_id: PropTypes.number,
    adverse_media_id: PropTypes.number,
    comments: PropTypes.string,
    status_id: PropTypes.number,
    created: PropTypes.string,
    last_modified: PropTypes.string,
    is_recipient: PropTypes.bool,
  }),
  address_data: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    link_type: PropTypes.number,
    link_id: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  isFetchError: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedAccount)
