import { useMutation } from 'react-query'

// Services
import { DeleteVaspProvider } from 'services/api/Business'

export default () => {
  const {
    mutate: deleteVaspProvider,
    data: deleteVaspProviderData,
    error: deleteVaspProviderError,
    isLoading: isDeleteVaspProviderLoading,
    isSuccess: isDeleteVaspProviderSuccess,
    isError: isDeleteVaspProviderError,
  } = useMutation(DeleteVaspProvider)

  return {
    deleteVaspProvider,
    deleteVaspProviderData,
    deleteVaspProviderError,
    isDeleteVaspProviderLoading,
    isDeleteVaspProviderSuccess,
    isDeleteVaspProviderError,
  }
}
