import { authAxios, createAxiosInstance } from 'common/utils/axios'

import { ADMIN_ENDPOINTS, DIRECTORY_SERVICE_URL } from 'common/constants/apiUrls'

export const authAxiosLocalServer = createAxiosInstance(DIRECTORY_SERVICE_URL)

const handler = authAxios

export const NotifyVasp = async ({ vasp_id }) => {
  const response = await handler.post(`${ADMIN_ENDPOINTS.NOTIFY_VASP}/${vasp_id}`, {
    vasp_id,
  })

  const { data } = response

  return data
}

export const RejectVasp = async ({ vasp_id }) => {
  const response = await handler.post(`${ADMIN_ENDPOINTS.REJECT_VASP}/${vasp_id}`, {
    vasp_id,
  })

  const { data } = response

  return data
}
