import moment from 'moment'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'
import { SUPPORTED_CHAIN_SYMBOL_MAPPING } from 'common/constants/formOptions'

// Utils
import { capitalizeWords } from 'common/utils/valueFormat'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  TransferFormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormWrapper,
  FormLabel,
  OptionLabel,
} from './AccountTransferForm.elements'

// Hooks
import { useSearchAccount, useFetchAccount, useGetAddresses } from 'core/hooks/api'

// Views
import { TextField, Button, Search, Select } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AccountTransferForm(props) {
  // Destructure
  const { formData, setFormData, actions } = props

  // Store Actions
  const {
    setIsIndividualAccountDrawerOpen,
    setIsEntityAccountDrawerOpen,
    setShowHeaderLoader,
    setToBeUpdatedRecipient,
  } = actions

  // hooks
  const { searchAccount, accountSearchData, isAccountSearchLoading } = useSearchAccount()
  const { getAccount, accountData, isAccountLoading } = useFetchAccount()
  const { getAddresses, getAddressesData, isGetAddressesLoading } = useGetAddresses()

  // Internal State
  const [searchResults, setSearchResults] = useState([])
  const [addresses, setAddresses] = useState()
  const [addressesMap, setAddressesMap] = useState()

  // Constants
  const filter = {
    sort: '-last_modified',
    page: 1,
    size: 100,
    start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
    end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  }

  // functions
  const handleTransactionChange = (value) => {
    setFormData({ ...formData, transfer: { ...formData.transfer, ...value } })
  }
  const handleAccountSearch = debounce((value) => {
    if (!value) return setSearchResults([])
    if (value) return searchAccount({ search_term: value })
  }, 500)
  const handleOnAutoCompleteSelect = (value) => {
    getAccount({ account_id: value })
  }
  function formatAddressOptions(items) {
    if (items) {
      const newArr = []
      const newMap = {}
      items.forEach((item) => {
        newMap[item?.address] = item
        // Filter out addresses that are not of the same chain as the selected asset
        if (item?.chain === SUPPORTED_CHAIN_SYMBOL_MAPPING?.[formData?.transfer?.asset_code]) {
          newArr.push({
            value: item?.address,
            label: item?.address,
            data: item,
          })
        }
      })
      setAddressesMap(newMap)
      setAddresses(newArr)
    }
  }
  // NOTE: move this function to a common utility file
  const shortenAddress = (address) => {
    if (!address) return ''
    // Arbitrary value to filter out non wallet address strings like 'None'
    if (address.length < 10) return address
    return `${address.slice(0, 5)}...${address.slice(-5)}`
  }

  // useEffects
  useEffect(() => {
    if (accountData) {
      setFormData({
        ...formData,
        account: { ...formData.account, ...accountData },
        transfer: { ...formData.transfer, in_hash_address: null, selected_account_address: null },
      })
      getAddresses({
        ...filter,
        chain: SUPPORTED_CHAIN_SYMBOL_MAPPING?.[formData?.transfer?.asset_code],
        link_id: accountData.id,
      })
      setAddresses([])
      setAddressesMap()
    }
  }, [accountData])
  useEffect(() => {
    if (getAddressesData?.items) {
      formatAddressOptions(getAddressesData.items)
    }
  }, [getAddressesData])
  useEffect(() => {
    if (accountSearchData) {
      setSearchResults(() =>
        accountSearchData.map(({ reference_id, display_name, id }) => ({
          label: reference_id,
          subLabel: display_name,
          value: id,
        }))
      )
    }
    if (!accountSearchData) setSearchResults([])
  }, [accountSearchData])
  useEffect(() => {
    if (isAccountLoading) {
      setShowHeaderLoader(true)
      setFormData({ ...formData, account: null })
    }
    if (!isAccountLoading) setShowHeaderLoader(false)
  }, [isAccountLoading])

  return (
    <TransferFormWrapper>
      <FormWrapper>
        <FormGroupWrapper>
          <FormInputGroupItem>
            <Search
              placeholder={formData?.account ? formData?.account?.reference_id : 'Search for account'}
              onInputChange={handleAccountSearch}
              options={searchResults}
              setOptions={setSearchResults}
              onSelect={handleOnAutoCompleteSelect}
              isLoading={isAccountSearchLoading}
              bottomOptions={
                <FormGroupWrapper>
                  <OptionLabel>Create New Account</OptionLabel>
                  <FormInputGroupItem>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setToBeUpdatedRecipient(null)
                        setIsIndividualAccountDrawerOpen(true)
                      }}
                    >
                      Individual
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setToBeUpdatedRecipient(null)
                        setIsEntityAccountDrawerOpen(true)
                      }}
                    >
                      Business
                    </Button>
                  </FormInputGroupItem>
                </FormGroupWrapper>
              }
            />
          </FormInputGroupItem>
          <FormInputGroupItem>
            {isGetAddressesLoading && <FormLabel>Loading...</FormLabel>}
            {!isGetAddressesLoading && addresses && (
              <Select
                key={formData.account}
                longValue={false}
                placeholder={addresses.length > 0 ? 'Address Hash' : "Account isn't linked with any address"}
                label={addresses && 'Select address'}
                onChange={(v) => {
                  if (addressesMap[v]) {
                    handleTransactionChange({ in_hash_address: v, selected_account_address: addressesMap[v] })
                  }
                }}
                value={formData.transfer.in_hash_address ? formData.transfer.in_hash_address : null}
                options={addresses.map((item) => ({
                  value: item.value,
                  label: shortenAddress(item.label),
                  data: item.data,
                }))}
                disabled={addresses.length === 0}
              />
            )}
          </FormInputGroupItem>

          {formData.account && !isAccountLoading && (
            <>
              <FormLabel>Selected Account</FormLabel>
              <FormInputGroupItem>
                <TextField label="External ID" disabled value={formData.account.reference_id} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Account Type" disabled value={formData.account.account_type_desc} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Name" disabled value={formData.account.display_name} />
              </FormInputGroupItem>
            </>
          )}
          {formData?.transfer?.selected_account_address && !isGetAddressesLoading && (
            <>
              <FormLabel>Selected address</FormLabel>
              <FormInputGroupItem>
                <TextField
                  label="Chain"
                  disabled
                  value={capitalizeWords(formData?.transfer?.selected_account_address?.chain || '')}
                />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField
                  label="Address"
                  disabled
                  value={shortenAddress(formData?.transfer?.selected_account_address?.address)}
                />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField
                  label="Self Hosted Wallet"
                  disabled
                  value={formData?.transfer?.selected_account_address?.is_self_hosted ? 'Yes' : 'No'}
                />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField
                  label="Risk Score"
                  disabled
                  value={formData?.transfer?.selected_account_address?.risk_ratio}
                />
              </FormInputGroupItem>
            </>
          )}
          {(isAccountLoading || isGetAddressesLoading) && <FormLabel>Loading...</FormLabel>}
        </FormGroupWrapper>
      </FormWrapper>
    </TransferFormWrapper>
  )
}

// Default Props
AccountTransferForm.defaultProps = {
  formData: {},
  setFormData: () => {},
  actions: {},
  form: {},
}

// Proptypes Validation
AccountTransferForm.propTypes = {
  formData: PropTypes.shape({
    transfer: PropTypes.shape({
      in_hash_address: PropTypes.string,
      asset_code: PropTypes.string,
      selected_account_address: PropTypes.shape({
        chain: PropTypes.string,
        address: PropTypes.string,
        risk_ratio: PropTypes.number,
        is_self_hosted: PropTypes.bool,
      }),
    }),
    account: PropTypes.shape({
      reference_id: PropTypes.string,
      account_type_desc: PropTypes.string,
      display_name: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdAccount: PropTypes.shape({}),
  }),
  setFormData: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountTransferForm)
