import styled from 'styled-components'

export const FormInputGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > div > div {
    margin-bottom: 0 !important;
  }
`
export const FormGroupWrapper = styled.div`
  margin: 0 0 40px 0;
`
