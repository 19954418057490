/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useCreateOrUpdateAddress } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  Wrapper,
  SearchBarWrapper,
  SearchInputWrapper,
  SearchBarContainer,
  Backdrop,
  CloseIconWrapper,
  Title,
  ChoiceWrapper,
  ProviderChoiceWrapper,
  ProviderChoiceContainer,
} from './AddressSearchBar.elements'

// Views
import { TextField, Button, Radio } from 'views/components'

// Assets
import { ScanIcon, CloseIcon } from 'assets/images'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AddressSearchBar(props) {
  // props
  const { actions, address } = props

  // destructure
  const { showAlert, setScannedAddress, toggleInfoModal, setInfoModalDetails } = actions
  const {
    addressChains,
    // defaultAddressProvider
  } = address

  // local states
  const [isActive, setIsActive] = useState(false)
  const [addressInput, setAddressInput] = useState('')
  const [chain, setChain] = useState('')
  const [chainSymbol, setChainSymbol] = useState('')
  const [selectedProvider, setSelectedProvider] = useState()
  const [allChains, setAllChains] = useState()
  const [availableChains, setAvailableChains] = useState()
  const [isSelfHosted, setIsSelfHosted] = useState(false)

  // hooks
  const {
    createOrUpdateAddress,
    createOrUpdateAddressData,
    createOrUpdateAddressError,
    isCreateOrUpdateAddressLoading,
  } = useCreateOrUpdateAddress()
  const [userCredentials] = useLocalStorage('userCredentials', {})

  // Functions
  function handleSubmit() {
    const firstChild = Object.keys(availableChains?.[selectedProvider])[0]
    const firstChain = availableChains?.[selectedProvider]?.[firstChild]
    const provider_id = firstChain?.provider_id
    createOrUpdateAddress({
      address: addressInput,
      chain,
      name_first: userCredentials.name_first,
      name_last: userCredentials.name_last,
      provider_id,
      is_self_hosted: isSelfHosted,
    })
    setAddressInput('')
    setChain('')
    setChainSymbol()
    setSelectedProvider()
  }

  function handleAddressChange(inputValue) {
    setAddressInput(inputValue)
  }
  function handleAvailableChains() {
    const providers = { FDBDM: { ...addressChains?.financial?.FDBDM }, ...addressChains?.risk }
    const allChains = {}
    let literallyAllChains = {}
    Object.keys(providers).forEach((providerCode) => {
      Object.keys(providers[providerCode]).forEach((chainCode) => {
        if (providers?.FDBDM?.[chainCode] && providerCode !== 'FDBDM') {
          allChains[providerCode] = { ...allChains[providerCode], [chainCode]: providers[providerCode][chainCode] }
          literallyAllChains = { ...literallyAllChains, [chainCode]: providers[providerCode][chainCode] }
        }
      })
    })
    setAvailableChains(allChains)
    setAllChains(literallyAllChains)
  }
  useEffect(() => {
    handleAvailableChains()
  }, [addressChains])
  useEffect(() => chain && selectedProvider && addressInput && handleSubmit(), [selectedProvider])
  useEffect(() => {
    if (!isActive) {
      setIsSelfHosted()
      setAddressInput('')
      setChain()
      setChainSymbol()
    }
  }, [isActive])
  useEffect(() => {
    if (createOrUpdateAddressData) {
      setScannedAddress(createOrUpdateAddressData)
      setIsActive(false)
    }
  }, [createOrUpdateAddressData])
  useEffect(() => {
    if (createOrUpdateAddressError?.response?.data?.data) {
      showAlert({ type: 'error', message: createOrUpdateAddressError?.response?.data?.data })
    } else if (createOrUpdateAddressError) {
      showAlert({ type: 'error', message: 'An error occurred in screening address' })
    }
  }, [createOrUpdateAddressError])
  useEffect(() => {
    if (isCreateOrUpdateAddressLoading) {
      toggleInfoModal(true)
      setInfoModalDetails({
        title: 'Scanning Address',
        content: 'This process may take over 60 seconds, depending on the volume of data.',
        loading: true,
      })
    } else {
      toggleInfoModal(false)
      setInfoModalDetails()
    }
  }, [isCreateOrUpdateAddressLoading])
  return (
    <Wrapper>
      <SearchBarWrapper isActive={isActive}>
        <SearchBarContainer isActive={isActive}>
          <SearchInputWrapper isActive={isActive}>
            {isActive && (
              <CloseIconWrapper onClick={() => setIsActive(false)}>
                <CloseIcon />
              </CloseIconWrapper>
            )}
            <ScanIcon />
            <TextField
              placeholder={isCreateOrUpdateAddressLoading ? 'loading...' : !isActive && 'Screen a wallet address'}
              onClick={() => {
                setIsActive(true)
              }}
              value={addressInput}
              onChange={(e) => handleAddressChange(e.currentTarget.value)}
            />
          </SearchInputWrapper>
          {isActive && addressInput?.trim() && !isCreateOrUpdateAddressLoading && (
            <ChoiceWrapper isActive={isActive}>
              {allChains && (
                <ProviderChoiceWrapper>
                  <Title>Select Chain</Title>
                  <ProviderChoiceContainer>
                    {Object.keys(allChains)?.map((key) => (
                      <Button
                        onClick={() => {
                          setChainSymbol(key)
                          setChain(allChains[key]?.chain_name)
                        }}
                        variant={chainSymbol === key ? 'primary' : 'outlined'}
                        key={key}
                      >
                        {allChains[key]?.chain_name}
                      </Button>
                    ))}
                  </ProviderChoiceContainer>
                </ProviderChoiceWrapper>
              )}
              {allChains && chain && addressInput && (
                <ProviderChoiceWrapper>
                  <Title>Is Wallet Self-Hosted?</Title>
                  <ProviderChoiceContainer>
                    <Radio
                      onChange={(v) => setIsSelfHosted(v)}
                      value={isSelfHosted}
                      horizontal
                      options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ]}
                    />
                  </ProviderChoiceContainer>
                </ProviderChoiceWrapper>
              )}
              {allChains && chain && addressInput && (
                <ProviderChoiceWrapper>
                  <Title>Select Provider</Title>
                  <ProviderChoiceContainer>
                    {Object.keys(availableChains)?.map((key) => {
                      if (availableChains?.[key]?.[chainSymbol]) {
                        return (
                          <Button
                            onClick={() => {
                              setSelectedProvider(key)
                            }}
                            variant={selectedProvider === key ? 'primary' : 'outlined'}
                            key={key}
                          >
                            {key}
                          </Button>
                        )
                      }
                    })}
                  </ProviderChoiceContainer>
                </ProviderChoiceWrapper>
              )}
            </ChoiceWrapper>
          )}
        </SearchBarContainer>
      </SearchBarWrapper>
      <Backdrop
        onClick={() => setIsActive(false)}
        style={{ zIndex: isActive ? '5' : '-1', opacity: isActive ? '0.5' : '0' }}
      />
    </Wrapper>
  )
}

// Default Props
AddressSearchBar.defaultProps = {
  actions: {},
}

// Proptypes Validation
AddressSearchBar.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setScannedAddress: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
    defaultAddressProvider: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressSearchBar)
