import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  TransferFormWrapper,
  FormGroupWrapper,
  FormRowContainer,
  FormGroupTitle,
  FormGroupText,
  FormWrapper,
  FormButtonWrapper,
} from './SummaryTransferForm.elements'

// Views
import { Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function SummaryTransferForm(props) {
  // Destructure
  const { formData, setFormData, actions, setFormPage, form } = props
  const { createdAccount, createdRecipient, createdVasp } = form

  // Store Actions
  const {
    setToBeUpdatedAccount,
    setToBeUpdatedRecipient,

    setCreatedAccount,
    setCreatedRecipient,
    setCreatedVasp,

    setIsIndividualAccountDrawerOpen,
    setIsEntityAccountDrawerOpen,
    setIsIndividualRecipientDrawerOpen,
    setIsEntityRecipientDrawerOpen,
  } = actions

  // Internal State
  const [formState, setFormState] = useState(formData)

  // functions
  const handleEditAccount = () => {
    setToBeUpdatedAccount(formData.account)
    if (formState.account.account_type_id === 1) {
      setIsIndividualAccountDrawerOpen(true)
    } else if (formState.account.account_type_id === 2) {
      setIsEntityAccountDrawerOpen(true)
    }
  }
  const handleEditRecipient = () => {
    setToBeUpdatedRecipient(formState.recipient)
    if (formState.recipient.account_type_id === 1) {
      setIsIndividualRecipientDrawerOpen(true)
    } else if (formState.recipient.account_type_id === 2) {
      setIsEntityRecipientDrawerOpen(true)
    }
  }
  // NOTE: move this function to a common utility file
  const shortenAddress = (address) => {
    if (!address) return ''
    return `${address.slice(0, 5)}...${address.slice(-5)}`
  }

  // useEffect
  useEffect(() => setFormState(formData), [formData])
  useEffect(() => {
    if (createdAccount) {
      setFormData({ ...formData, account: createdAccount })
      setCreatedAccount(null)
    }
    if (createdRecipient) {
      setFormData({ ...formData, account: createdRecipient })
      setCreatedRecipient(null)
    }
    if (createdVasp) {
      setFormData({ ...formData, account: createdVasp })
      setCreatedVasp(null)
    }
  }, [createdAccount, createdRecipient, createdVasp])
  return (
    <TransferFormWrapper>
      <FormWrapper>
        <FormGroupWrapper>
          <FormRowContainer>
            <FormGroupTitle>Amount</FormGroupTitle>
            <FormButtonWrapper>
              <Button size="tiny" variant="outlined" onClick={() => setFormPage(1)}>
                Change
              </Button>
            </FormButtonWrapper>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Amount: </FormGroupText>
            <FormGroupText>{formState.transfer?.amount}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Asset: </FormGroupText>
            <FormGroupText>{formState.transfer?.asset_code}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Transfer Method: </FormGroupText>
            <FormGroupText>{formState.transfer?.protocol_code}</FormGroupText>
          </FormRowContainer>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FormRowContainer>
            <FormGroupTitle>Sender</FormGroupTitle>
            <FormButtonWrapper>
              <Button size="tiny" variant="outlined" onClick={handleEditAccount} disabled={!formState.account}>
                Edit
              </Button>
              <Button size="tiny" variant="outlined" onClick={() => setFormPage(2)}>
                Change
              </Button>
            </FormButtonWrapper>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Sender Address Hash: </FormGroupText>
            <FormGroupText>{shortenAddress(formState.transfer?.in_hash_address)}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>
              {formState.account?.account_type_id === 1 ? 'First Name: ' : 'Business Name: '}
            </FormGroupText>
            <FormGroupText>
              {formState.account?.account_type_id === 1
                ? formState.account?.name_first
                : formState.account?.name_business}
            </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>{formState.account?.account_type_id === 1 ? 'Last Name: ' : 'Legal Name: '}</FormGroupText>
            <FormGroupText>
              {formState.account?.account_type_id === 1 ? formState.account?.name_last : formState.account?.name_legal}
            </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Address: </FormGroupText>
            <FormGroupText>
              {`${formState.account?.address_line ? ` ${formState.account?.address_line}, ` : ''}${
                formState.account?.address_street_no ? ` ${formState.account?.address_street_no}, ` : ''
              }${formState.account?.address_street_name ? `${formState.account?.address_street_name}, ` : ''}${
                formState.account?.address_building_name ? `${formState.account?.address_building_name}, ` : ''
              }${formState.account?.address_floor ? `${formState.account?.address_floor}, ` : ''}${
                formState.account?.address_room ? `${formState.account?.address_room}, ` : ''
              }${formState.account?.address_department ? `${formState.account?.address_department}, ` : ''}${
                formState.account?.address_postbox ? `${formState.account?.address_postbox}, ` : ''
              }${formState.account?.address_city_location ? `${formState.account?.address_city_location}, ` : ''}${
                formState.account?.address_city ? `${formState.account?.address_city}, ` : ''
              }${formState.account?.address_region ? `${formState.account?.address_region}, ` : ''}${
                formState.account?.address_district ? `${formState.account?.address_district}, ` : ''
              }${formState.account?.address_postcode ? `${formState.account?.address_postcode}, ` : ''}${
                formState.account?.address_country_code ? `${formState.account?.address_country_code}, ` : ''
              }${formState.account?.address_country_desc ? `${formState.account?.address_country_desc} ` : ''}`}
            </FormGroupText>
          </FormRowContainer>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FormRowContainer>
            <FormGroupTitle>Recipient</FormGroupTitle>
            <FormButtonWrapper>
              <Button size="tiny" variant="outlined" onClick={handleEditRecipient} disabled={!formState.recipient}>
                Edit
              </Button>
              <Button size="tiny" variant="outlined" onClick={() => setFormPage(3)}>
                Change
              </Button>
            </FormButtonWrapper>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Recipient Address Hash: </FormGroupText>
            <FormGroupText>{shortenAddress(formState.transfer?.out_hash_address)}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>
              {formState.recipient?.account_type_id === 1 ? 'First Name: ' : 'Business Name: '}
            </FormGroupText>
            <FormGroupText>
              {formState.recipient?.account_type_id === 1
                ? formState.recipient?.name_first
                : formState.recipient?.name_business}
            </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>{formState.recipient?.account_type_id === 1 ? 'Last Name: ' : 'Legal Name: '}</FormGroupText>
            <FormGroupText>
              {formState.recipient?.account_type_id === 1
                ? formState.recipient?.name_last
                : formState.recipient?.name_legal}
            </FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Address: </FormGroupText>
            <FormGroupText>
              {`${formState.recipient?.address_line ? ` ${formState.recipient?.address_line}, ` : ''}${
                formState.recipient?.address_street_no ? ` ${formState.recipient?.address_street_no}, ` : ''
              }${formState.recipient?.address_street_name ? `${formState.recipient?.address_street_name}, ` : ''}${
                formState.recipient?.address_building_name ? `${formState.recipient?.address_building_name}, ` : ''
              }${formState.recipient?.address_floor ? `${formState.recipient?.address_floor}, ` : ''}${
                formState.recipient?.address_room ? `${formState.recipient?.address_room}, ` : ''
              }${formState.recipient?.address_department ? `${formState.recipient?.address_department}, ` : ''}${
                formState.recipient?.address_postbox ? `${formState.recipient?.address_postbox}, ` : ''
              }${formState.recipient?.address_city_location ? `${formState.recipient?.address_city_location}, ` : ''}${
                formState.recipient?.address_city ? `${formState.recipient?.address_city}, ` : ''
              }${formState.recipient?.address_region ? `${formState.recipient?.address_region}, ` : ''}${
                formState.recipient?.address_district ? `${formState.recipient?.address_district}, ` : ''
              }${formState.recipient?.address_postcode ? `${formState.recipient?.address_postcode}, ` : ''}${
                formState.recipient?.address_country_code ? `${formState.recipient?.address_country_code}, ` : ''
              }${formState.recipient?.address_country_desc ? `${formState.recipient?.address_country_desc} ` : ''}`}
            </FormGroupText>
          </FormRowContainer>
        </FormGroupWrapper>

        <FormGroupWrapper>
          <FormRowContainer>
            <FormGroupTitle>Counterparty VASP</FormGroupTitle>
            <FormButtonWrapper>
              <Button size="tiny" variant="outlined" onClick={() => setFormPage(4)}>
                Change
              </Button>
            </FormButtonWrapper>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Beneficiary VASP Name: </FormGroupText>
            <FormGroupText>{formState.vasp?.name_legal}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Website: </FormGroupText>
            <FormGroupText>{formState.vasp?.website}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Legal Name: </FormGroupText>
            <FormGroupText>{formState.vasp?.name_legal}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Country: </FormGroupText>
            <FormGroupText>{formState.vasp?.address_country_code}</FormGroupText>
          </FormRowContainer>
          <FormRowContainer>
            <FormGroupText>Contact Email: </FormGroupText>
            <FormGroupText>{formState.vasp?.email_contact}</FormGroupText>
          </FormRowContainer>
        </FormGroupWrapper>
      </FormWrapper>
    </TransferFormWrapper>
  )
}

// Default Props
SummaryTransferForm.defaultProps = {
  formData: {},
  actions: {},
  form: {},
}

// Proptypes Validation
SummaryTransferForm.propTypes = {
  formData: PropTypes.shape({
    account: PropTypes.shape({}),
    recipient: PropTypes.shape({}),
    vasp: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,

    setCreatedAccount: PropTypes.func,
    setCreatedRecipient: PropTypes.func,
    setCreatedVasp: PropTypes.func,

    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setIsVaspDrawerOpen: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdAccount: PropTypes.shape({}),
    createdRecipient: PropTypes.shape({}),
    createdVasp: PropTypes.shape({}),
  }),
  setFormData: PropTypes.func,
  setFormPage: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTransferForm)
