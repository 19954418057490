import styled from 'styled-components'
import Select from '../Inputs/Select/Select'

export const DateRangePickerWrapper = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  position: relative;
`
export const DateRangePickerHeader = styled.div`
  display: flex;
  gap: 20px;
`
export const DateRangePickerTitle = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  align-items: ${({ vertical }) => (vertical ? 'flex-start' : 'center')};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
  gap: ${({ vertical }) => (vertical ? '0px' : '5px')};
  cursor: pointer;
  min-width: 90px;
`
export const DateRangePickerDate = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 14px;
  font-weight: 400;
`
export const Label = styled.div`
  font-weight: 200;
`

export const DatePickerWrapper = styled.div`
  min-width: 490px;
  top: 100%;
  margin-top: 15px;
  position: absolute;
  height: ${({ open }) => (open ? 'fit-content' : 0)} !important;
  padding: ${({ open }) => (open ? '20px' : '0 20px')} !important;
  overflow: ${({ open }) => (open ? 'auto' : 'hidden')} !important;
  transition: 0.3s;

  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  width: fit-content;
  border-radius: 10px;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
`
export const DatePickerWrapperHeader = styled.div`
  min-width: 490px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: fit-content;

  & button {
    height: 40px;
    width: 40px;
    padding: 5px;
    border: none;
    & svg {
      max-width: 100% !important;
      max-height: 100% !important;
    }
    &:hover {
      border: none;
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }
`
export const DateChangerWrapper = styled.div`
  min-width: 290px;
  display: flex;
  gap: 10px;
  width: 100%;

  & div {
    margin: 0 !important;
    font-size: 12px !important;
  }
`
export const DateChanger = styled(Select)``
export const DateChangerContainer = styled.div`
  display: flex;
  width: 100%;

  & > div > div > div:last-child {
    max-height: 200px;
    & div > div {
      padding: 3px 5px;
    }
  }
`

export const DateContentWrapper = styled.div`
  display: flex;
  gap: 30px;
  min-width: 490px;
`
export const DateGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0 0 0;
  min-width: 230px;
`
export const DateGroupTitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const DateWeekWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px !important;
  min-width: 230px;
`
export const DateDayWrapper = styled.div`
  display: grid !important;
  grid-template-columns: repeat(7, minmax(20px, 1fr)) !important;
  gap: 10px !important;
  min-width: 230px;
`
export const DateDayContainer = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${({ theme, disabled }) => (disabled ? `${theme.colors.gray} !important` : theme.colors.darkGray)};
  color: ${({ theme, active }) => active && theme.colors.white};
  cursor: pointer;

  padding: 3px 5px;
  border-radius: 5px;
  text-decoration: ${({ forbidden }) => forbidden && 'line-through'};

  background-color: ${({ active, theme }) => active && theme.colors.blue};
  background-color: ${({ disabled }) => disabled && 'transparent !important'};
  &:hover {
    background-color: ${({ disabled, theme }) => !disabled && theme.colors.gray};
  }
`

export const DatePickerWrapperFooter = styled.div`
  min-width: 490px;
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  height: fit-content;

  & button {
    font-size: 14px !important;
    padding: 5px 10px !important;
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    box-shadow: none;
  }
`
export const FooterGroupWrapper = styled.div`
  display: flex;
  gap: 10px;
  max-width: none !important;
`
