import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useRequestVasp } from 'core/hooks/api'

// Styled Elements
import { FormWrapper, FormContainer, FormInputGroupItem, FormFooter, FormInfo } from '../DirectoryForms.elements'

// Views
import {
  Form,
  FormTextField,
  Button,
  // FormSelect
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function RequestVaspForm(props) {
  // Destructure
  const { actions, setIsPushRequest, setEmail } = props

  // Hooks
  const { requestVasp, requestVaspError, isRequestVaspLoading, isRequestVaspSuccess } = useRequestVasp()

  // Store Actions
  const { setIsRequestVaspModalOpen, showAlert } = actions

  // Internal State
  const [initialData, setInitialData] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [isExisting, setIsExisting] = useState()

  // Variables
  const formRef = useRef()
  const initialValues = {
    email: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
  })

  // Functions
  const triggerSubmit = () => {
    formRef.current.submitForm()
  }
  const handleOnSubmit = (values) => {
    const payload = {
      ...values,
    }
    requestVasp(payload)
  }

  const handleLoadingChange = () => {
    if (isRequestVaspLoading) return
    if (!isRequestVaspLoading && initialData) {
      if (isRequestVaspSuccess) {
        setIsRequestVaspModalOpen(false)
        showAlert({ type: 'success', message: 'Successfully Requested for VASP!' })
      } else if (requestVaspError) {
        if (requestVaspError?.response?.status === 429) {
          setErrorMessage(
            'VASP has already been requested within the past 10 days. Do you wish to push through with the request?'
          )
          setIsExisting(true)
          return
        }
        if (requestVaspError?.response?.data?.context?.message) {
          setErrorMessage(requestVaspError?.response?.data?.context?.message)
          return
        }
        setErrorMessage('An occurred in Requesting for VASP')
      }
    }
  }
  // UseEffects
  useEffect(() => {
    setInitialData(initialValues)
  }, [])
  useEffect(() => handleLoadingChange(), [isRequestVaspLoading])
  return (
    <FormWrapper style={{ padding: '25px' }}>
      {initialData && (
        <Form
          formRef={formRef}
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {errorMessage && <FormInfo>{errorMessage}</FormInfo>}
          {!errorMessage && (
            <>
              <FormContainer style={{ minHeight: '110px' }}>
                <FormInputGroupItem>
                  <FormTextField label="Email" type="email" name="email" />
                </FormInputGroupItem>
              </FormContainer>

              <FormFooter>
                <Button type="button" onClick={triggerSubmit} disabled={isRequestVaspLoading} fullWidth>
                  {isRequestVaspLoading ? 'Sending Request...' : 'Submit'}
                </Button>
              </FormFooter>
            </>
          )}

          {isExisting && (
            <FormFooter>
              <Button type="button" onClick={() => setIsRequestVaspModalOpen(false)} variant="outlined" fullWidth>
                Cancel
              </Button>
              <Button
                type="button"
                fullWidth
                onClick={() => {
                  if (setIsPushRequest && setEmail) {
                    setEmail(formRef.current?.values?.email)
                    setIsPushRequest(true)
                  }
                }}
              >
                Push Request
              </Button>
            </FormFooter>
          )}
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
RequestVaspForm.defaultProps = {
  formRef: {},
  form: {
    toBeUpdatedVasp: null,
  },
  actions: {},
}

// Proptypes Validation
RequestVaspForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    countryListOptions: PropTypes.instanceOf(Array),
    toBeUpdatedVasp: PropTypes.shape({
      vasp_id: PropTypes.string,
    }),
  }),
  setIsPushRequest: PropTypes.func,
  setEmail: PropTypes.func,
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,
    setCreatedVasp: PropTypes.func,
    showAlert: PropTypes.func,
    setIsRequestVaspModalOpen: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setIsRecordUpdated: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestVaspForm)
