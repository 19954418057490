/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/close'

// Constants
// import { TRAVELRULE_NAVIGATION_HEADERS } from 'common/constants/travelRule'

// Hooks
import { useLinkTransaction } from 'core/hooks/api'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  CreateEntityDrawerWrapper,
  DrawerNavWrapper,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  DrawerCloseIcon,
  DrawerTitle,
  Divider,
} from './LinkAccountDrawer.elements'

// Views
import { Drawer, Button, LoadingTablePlaceholder } from 'views/components'

import { LinkTransactionForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function LinkAccountDrawer(props) {
  // Destructure
  const { ui, actions, transaction } = props

  // Store State
  const { isLinkTransactionDrawerOpen } = ui
  const { transactionLinkDetails } = transaction

  // Store Actions
  const { setIsLinkTransactionDrawerOpen, showAlert, setTransactionLinkData } = actions

  // Hooks
  const { linkTransaction, linkTransactionData, linkTransactionError, isLinkTransactionLoading } = useLinkTransaction()

  // State
  const [formData, setFormData] = useState({
    account: null,
  })
  const [formData2, setFormData2] = useState({
    account: null,
  })

  // Functions
  const handleSubmit = () => {
    const { transaction_id } = transactionLinkDetails
    linkTransaction({
      transaction_id,
      sender_link_id: formData.account ? formData.account.account_id : null,
      sender_link_type: formData.account ? 1 : null,
      recipient_link_id: formData2.account ? formData2.account.account_id : null,
      recipient_link_type: formData2.account ? 1 : null,
    })
  }
  const handleCloseDrawer = () => {
    setFormData({
      account: null,
    })
    setFormData2({
      account: null,
    })
    setIsLinkTransactionDrawerOpen(false)
  }

  // UseEffects
  useEffect(() => {
    if (linkTransactionData) {
      setTransactionLinkData({ sender: formData?.account, recipient: formData2?.account })
      handleCloseDrawer()
    }
  }, [linkTransactionData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (linkTransactionError) showAlert({ type: 'error', message: 'An error occurred in linking transaction' })
  }, [linkTransactionError])

  return (
    <Drawer open={isLinkTransactionDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <CreateEntityDrawerWrapper>
        <DrawerNavWrapper>
          <DrawerCloseIcon onClick={handleCloseDrawer}>
            <CloseIcon />
          </DrawerCloseIcon>
        </DrawerNavWrapper>

        <DrawerHeader>
          <DrawerTitle>Link Transaction To An Account</DrawerTitle>
        </DrawerHeader>

        <DrawerContent>
          {isLinkTransactionLoading && <LoadingTablePlaceholder />}
          {!isLinkTransactionLoading && (
            <LinkTransactionForm
              label="Link Sender to an Account"
              title="Selected Sender Account"
              formData={formData}
              setFormData={setFormData}
            />
          )}
          {!isLinkTransactionLoading && <Divider />}
          {!isLinkTransactionLoading && (
            <LinkTransactionForm
              label="Link Recipient to an Account"
              title="Selected Recipient Account"
              formData={formData2}
              setFormData={setFormData2}
            />
          )}
        </DrawerContent>
        <DrawerFooter>
          <Button
            disabled={(formData?.account === null && formData2?.account === null) || isLinkTransactionLoading}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </DrawerFooter>
      </CreateEntityDrawerWrapper>
    </Drawer>
  )
}

// Default Props
LinkAccountDrawer.defaultProps = {
  ui: {},
  actions: {},
  transaction: {},
}

// Proptypes Validation
LinkAccountDrawer.propTypes = {
  ui: PropTypes.shape({ isLinkTransactionDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({
    setIsLinkTransactionDrawerOpen: PropTypes.func,
    setCreatedAccount: PropTypes.func,
    setCreatedRecipient: PropTypes.func,
    setCreatedVasp: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setTransactionLinkDetails: PropTypes.func,
    setTransactionLinkData: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  transaction: PropTypes.shape({
    transactionLinkDetails: PropTypes.shape({
      transaction_id: PropTypes.string,
    }),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkAccountDrawer)
