import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Library Components
import Dropzone from 'react-dropzone'

// Assets
import CloseIcon from 'assets/images/x.svg'
import UploadIcon from 'assets/images/icon-upload.svg'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useBatchUploadCases, useBatchUploadAddresses, useBatchUploadTransactions } from 'core/hooks/api'

// Helper Functions
import { saveExportableFile, handleOnFileDrop, handleFileError } from './UploadCaseModalHelpers'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  ModalFooterWrapper,
  ModalBodyWrapper,
  ModalFooterButton,
  UploadFileWrapper,
  UploadFileText,
  UploadFileSubtext,
  UploadFileInfoWrapper,
  UploadFileInfoItem,
  UploadFileInfoItemErrorText,
  UploadFileInfoItemHeader,
  UploadFileInfoItemText,
  UploadFileInfoDownload,
  UploadFileInfoLink,
  LoaderWrapper,
} from './UploadCaseModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button, LoaderCircle } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UploadCaseModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isUploadCaseModalOpen, activeModule, activeCase } = ui

  // Store Actions
  const {
    toggleUploadCaseModal,
    setIsNotifUpdated,
    toggleChooseKYCUploadModal,
    setWhitelistingFilters,
    setMonitoringFilters,
    setCaseManagementFilters,
    setNewCaseCreated,
    showAlert,
  } = actions

  // States
  const [uploadData, setUploadData] = useState([])
  // Error handling states
  const [caseDataLength, setCaseDataLength] = useState(0)
  const [invalidHeaders, setInvalidHeaders] = useState([])
  const [rejectedFile, setRejectedFile] = useState(false)
  const [acceptedFilesName, setAcceptedFilesName] = useState({})

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const {
    batchUploadCases,
    batchUploadCasesError,
    isBatchUploadCasesLoading,
    isBatchUploadCasesSuccess,
    isBatchUploadCasesError,
  } = useBatchUploadCases()
  const {
    batchUploadAddresses,
    batchUploadAddressesError,
    isBatchUploadAddressesLoading,
    isBatchUploadAddressesSuccess,
    isBatchUploadAddressesError,
  } = useBatchUploadAddresses()
  const {
    batchUploadTransactions,
    batchUploadTransactionsError,
    isBatchUploadTransactionsLoading,
    isBatchUploadTransactionsSuccess,
    isBatchUploadTransactionsError,
  } = useBatchUploadTransactions()

  // Variables
  const notLoading = !isBatchUploadCasesLoading && !isBatchUploadAddressesLoading && !isBatchUploadTransactionsLoading
  const isSuccess = isBatchUploadCasesSuccess || isBatchUploadTransactionsSuccess || isBatchUploadAddressesSuccess
  const isError = isBatchUploadCasesError || isBatchUploadTransactionsError || isBatchUploadAddressesError

  // Functions
  // handling on file drop
  const onFileDrop = (files) => {
    handleOnFileDrop({
      files,
      activeModule,
      setRejectedFile,
      setCaseDataLength,
      setUploadData,
      setInvalidHeaders,
      activeCase,
      userCredentials,
      setAcceptedFilesName,
    })
  }

  const toggleModal = () => {
    if (notLoading) toggleUploadCaseModal()
  }

  const handleOnSubmit = () => {
    switch (activeModule) {
      case 'transactions':
        batchUploadTransactions(uploadData)
        break
      case 'addresses':
        batchUploadAddresses(uploadData)
        break
      case 'cases':
        batchUploadCases(uploadData)
        break
      default:
    }
  }
  // saving sample file
  const handleSave = () => {
    saveExportableFile(activeModule)
  }
  // handling rejected file
  const onDropRejected = (rejectedFiles) => {
    console.log(rejectedFiles)
    setRejectedFile(true)
    setCaseDataLength(0)
  }
  // triggering update
  const triggerUpdate = () => {
    switch (activeModule) {
      case 'transactions':
        return setMonitoringFilters({ pageIndex: 0 })
      case 'addresses':
        return setWhitelistingFilters({ pageIndex: 0 })
      case 'cases':
        setCaseManagementFilters({ pageIndex: 0 })
        return setNewCaseCreated(true)
      default:
    }
  }

  useEffect(() => {
    if (isSuccess) {
      triggerUpdate()
      toggleUploadCaseModal()
      if (isBatchUploadCasesSuccess) {
        toggleChooseKYCUploadModal()
      }
    }
    if (isError) {
      if (isBatchUploadTransactionsError) {
        handleFileError(batchUploadTransactionsError, acceptedFilesName)
      }
      if (isBatchUploadAddressesError) {
        handleFileError(batchUploadAddressesError, acceptedFilesName)
      }
      if (isBatchUploadCasesError) {
        handleFileError(batchUploadCasesError, acceptedFilesName)
        toggleChooseKYCUploadModal()
      }
      setIsNotifUpdated(true)
      toggleUploadCaseModal()
      showAlert({ type: 'error', message: 'An error occurred with your request, check the notifications' })
    }
  }, [isBatchUploadCasesLoading, isBatchUploadTransactionsLoading, isBatchUploadAddressesLoading])

  return (
    <Modal isOpen={isUploadCaseModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Upload your file
      </ModalHeader>
      <ModalBody>
        <ModalBodyWrapper>
          <Dropzone
            onDrop={onFileDrop}
            onDropRejected={onDropRejected}
            accept=".xlsx, .xlsm, .csv, application/vnd.ms-excel, text/csv,"
          >
            {({ acceptedFiles, getRootProps, getInputProps }) => (
              <>
                {!isBatchUploadCasesLoading && !isBatchUploadAddressesLoading && !isBatchUploadTransactionsLoading ? (
                  <UploadFileWrapper {...getRootProps()} style={{ outline: 'none' }}>
                    <input {...getInputProps()} />
                    <Image src={UploadIcon} width={80} height={80} />
                    <UploadFileText>Drag and drop or click here</UploadFileText>
                    <UploadFileSubtext>All .xlsx, .xlsm and .csv file types are supported.</UploadFileSubtext>
                  </UploadFileWrapper>
                ) : (
                  <LoaderWrapper>
                    <LoaderCircle />
                  </LoaderWrapper>
                )}
                <UploadFileInfoWrapper>
                  <UploadFileInfoItem>
                    {acceptedFiles.map((file) => (
                      <UploadFileInfoItemText key={file.path}>
                        {file.path}-{file.size} bytes
                      </UploadFileInfoItemText>
                    ))}
                    <UploadFileInfoItemErrorText>
                      {(rejectedFile || invalidHeaders.length > 0) &&
                        'File format not supported. Verify the column names, or check the file format and try again.'}
                      {caseDataLength === 0 && acceptedFiles.length > 0 && <>File with no records.</>}
                      {caseDataLength > 250 && 'Maximum length of rows reached. (max 250)'}
                    </UploadFileInfoItemErrorText>
                    <UploadFileInfoItemHeader>Download a sample spreadsheet</UploadFileInfoItemHeader>
                    <UploadFileInfoItemText>
                      Need to see this in action first? Download this small
                      <UploadFileInfoDownload onClick={handleSave}> {' excel sample file '}</UploadFileInfoDownload>
                      and test the import process so there are no surprises.
                    </UploadFileInfoItemText>
                  </UploadFileInfoItem>
                  <UploadFileInfoItem>
                    <UploadFileInfoItemHeader>Have questions?</UploadFileInfoItemHeader>
                    <UploadFileInfoItemText>
                      Migrating data should be easy. Read the
                      <UploadFileInfoLink href="https://www.ospree.io/ospree/help-desk" target="_blank">
                        &nbsp;answer&nbsp;
                      </UploadFileInfoLink>
                      to all your questions about data security, file, type and troubleshooting.
                    </UploadFileInfoItemText>
                  </UploadFileInfoItem>
                </UploadFileInfoWrapper>
              </>
            )}
          </Dropzone>
        </ModalBodyWrapper>
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton>
            <Button
              color="default"
              onClick={() => {
                toggleUploadCaseModal()
              }}
              disabled={isBatchUploadCasesLoading || isBatchUploadAddressesLoading || isBatchUploadTransactionsLoading}
            >
              Cancel
            </Button>
          </ModalFooterButton>
          <ModalFooterButton>
            <Button
              color="primary"
              onClick={handleOnSubmit}
              disabled={
                rejectedFile ||
                caseDataLength > 250 ||
                caseDataLength < 1 ||
                invalidHeaders.length > 0 ||
                isBatchUploadCasesLoading ||
                isBatchUploadAddressesLoading ||
                isBatchUploadTransactionsLoading
              }
            >
              Submit
            </Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
UploadCaseModal.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
UploadCaseModal.propTypes = {
  ui: PropTypes.shape({
    isUploadCaseModalOpen: PropTypes.bool,
    activeModule: PropTypes.string,
    activeCase: PropTypes.string,
    activePage: PropTypes.string,
  }),

  actions: PropTypes.shape({
    toggleUploadCaseModal: PropTypes.func,
    toggleChooseKYCUploadModal: PropTypes.func,
    setIsNotifUpdated: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
    setWhitelistingFilters: PropTypes.func,
    setMonitoringFilters: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadCaseModal)
