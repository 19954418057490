import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Utils
import { parseJwt } from 'common/utils/jwt'

// Hooks
import { useLoginTwoFa, useGetUser } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'
import { useParams } from 'react-router'

// Store
import { actions } from 'core/store'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Views
import { LoaderCircle, Button } from 'views/components'

// Styled Elements
import {
  LoginWrapper,
  LoginInnerWrapper,
  LoginLogoLink,
  LoginTitle,
  LoginError,
  LoginFormWrapper,
  LoginFormInnerWrapper,
  LoginFormContent,
  LoadingWrapper,
  ButtonWrapper,
  // LoginFormFooterText,
  // LoginFormFooterLink,
} from './LoginTwoFa.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function LoginTwoFa(props) {
  // Props
  const { actions } = props

  // Store Actions
  const { showAlert } = actions

  // Hooks
  const { loginUserTwoFa, loginUserTwoFaData, loginUserTwoFaError, isLoginUserTwoFaLoading } = useLoginTwoFa()
  const { getUser, getUserData, isGetUserLoading } = useGetUser()
  const { auth_hash } = useParams()

  // Local States
  const [isLoggedIn, setIsloggedIn] = useLocalStorage('isLoggedIn', false)
  const [userCredentials, setUserCredentials] = useLocalStorage('userCredentials', {})
  const [, setTheme] = useLocalStorage('theme', 'light')

  const [userData, setUserData] = useState()

  const handleOnSubmit = (auth_hash) => {
    loginUserTwoFa({ auth_hash })
  }
  const getUserInfo = () => {
    setUserData(loginUserTwoFaData)
    localStorage.setItem('userCredentials', JSON.stringify(loginUserTwoFaData))
    const { uid } = parseJwt(loginUserTwoFaData.access_token)
    if (uid) {
      getUser({ user_id: uid })
    }
  }
  const login = async () => {
    // setting the data in order to make user logged in
    if (loginUserTwoFaData && !loginUserTwoFaError) {
      await setTheme('dark')
      await setUserCredentials({ ...userData, ...getUserData })
      await setIsloggedIn(true)
    }
  }
  const checkIfSessionExpired = async () => {
    // Checking if user session is expired
    if (isLoggedIn && !userCredentials?.access_token) {
      await localStorage.setItem('isLoggedIn', 'false')
      await localStorage.setItem('userCredentials', '{}')
      await localStorage.setItem('reportStrings', '[]')
      await localStorage.setItem('reportTitles', '[]')
      showAlert({ type: 'error', message: 'Session Expired' })
    }
  }
  // useEffect
  useEffect(() => loginUserTwoFaData && getUserInfo(), [loginUserTwoFaData])
  useEffect(() => getUserData && login(), [getUserData])
  useEffect(() => isLoggedIn && window.location.replace(window.location.origin), [isLoggedIn])
  useEffect(() => checkIfSessionExpired(), [])
  useEffect(() => {
    if (!loginUserTwoFaError) return
    if (loginUserTwoFaError?.response?.status === 400) {
      showAlert({ type: 'error', message: 'Username and password did not match' })
    } else showAlert({ type: 'error', message: 'An error occurred in logging in' })
  }, [loginUserTwoFaError])

  useEffect(() => {
    handleOnSubmit(auth_hash)
  }, [auth_hash])
  return (
    <LoginWrapper>
      <LoginInnerWrapper>
        <LoginFormWrapper>
          <LoginFormInnerWrapper>
            <LoginFormContent>
              <LoginLogoLink>
                <OspreeLogoIcon />
              </LoginLogoLink>
              {(isLoginUserTwoFaLoading || isGetUserLoading) && (
                <LoadingWrapper>
                  <LoginTitle>Signing in...</LoginTitle>
                  <LoaderCircle />
                </LoadingWrapper>
              )}
              {!isLoginUserTwoFaLoading && loginUserTwoFaError && (
                <>
                  <LoginError>The sign in link is invalid.</LoginError>
                  <ButtonWrapper href="/login">
                    <Button fullWidth>Get a new link</Button>
                  </ButtonWrapper>
                </>
              )}
            </LoginFormContent>
          </LoginFormInnerWrapper>
        </LoginFormWrapper>
      </LoginInnerWrapper>
    </LoginWrapper>
  )
}

// Default Props
LoginTwoFa.defaultProps = {
  actions: {},
}

// Proptypes Validation
LoginTwoFa.propTypes = {
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginTwoFa)
