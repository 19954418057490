import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Constants
// import { COUNTRIES_LIST } from 'common/constants/countries'

// Store
import { actions } from 'core/store'

// Hooks
import { useRegisterVasp, useGetUserAuth, useGetGleif } from 'core/hooks/api'

// Views
import { FormTextField, FormSearchSelect, LoaderCircle, Button, FormNavigation } from 'views/components'

// Styled Elements
import {
  Form,
  FormWrapper,
  FormInputGroupItem,
  FormGroupContainer,
  FormError,
  FormTitle,
  ButtonContainer,
  ButtonWrapper,
  FormGroupWrapper,
} from './VaspSignupForm.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function VaspSignupForm(props) {
  // Destructure
  const { actions } = props

  // Store Actions
  const { showAlert } = actions

  // Refs
  const formRef = useRef()

  // Hooks
  const { registerVasp, registerVaspData, registerVaspError, isRegisterVaspSuccess, isRegisterVaspLoading } =
    useRegisterVasp()
  const { getUserAuth, getUserAuthData, getUserAuthError, isGetUserAuthLoading } = useGetUserAuth()
  const { getGleif, getGleifData } = useGetGleif()
  const { token } = useParams()

  const [initialValues, setInitialValues] = useState()
  const [page, setPage] = useState(1)
  const [formError, setFormError] = useState('')
  const [gleifCountryListOptions, setGleifCountryListOptions] = useState()
  const [gleifList, setGleifList] = useState()
  const [, forceUpdate] = useState(0)

  // Variables
  const validationSchema = Yup.object().shape({
    name_legal: Yup.string().required('Legal Name is required'),
    national_id: Yup.string().required('Business ID is required'),
    national_id_issue_country: Yup.string().required('Registration Country is required'),
    national_id_registration_authority: Yup.string().required('Registration Authority is required'),
    website: Yup.string().required('Website is required'),
    address_line: Yup.string().required('Address Line is required'),

    email_contact: Yup.string().email().required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirm_password: Yup.string().when('password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    }),
    name_first: Yup.string().required('First Name is required'),
    name_last: Yup.string().required('Last Name is required'),
    timezone_code: Yup.string().required('Timezone is required'),
    token: Yup.string(),
  })
  const date = new Date()
  const offset = date.getTimezoneOffset()

  // Functions
  const handleOnSubmit = (values) => {
    registerVasp({
      token,
      user: {
        email: values?.email_contact,
        password: values?.password,
        phone: values?.phone,
        name_first: values?.name_first,
        name_last: values?.name_last,
        timezone_code: offset,
      },
      vasp: {
        email_contact: values?.email_contact,
        name_business: values?.name_business,
        name_legal: values?.name_legal,
        national_id: values?.national_id,
        national_id_issue_country: values?.national_id_issue_country,
        national_id_registration_authority: values?.national_id_registration_authority,
        website: values?.website,
        address_line: values?.address_line,
      },
    })
  }
  const handleUserAuthData = () => {
    setInitialValues({
      email_contact: getUserAuthData?.email,
      password: '',
      phone: '',
      name_first: '',
      name_last: '',
      timezone_code: offset,
      token,

      name_business: '',
      name_legal: '',
      website: '',
      address_street: '',
      address_city: '',
      address_region: '',
      address_postcode: '',
      address_country_code: '',
    })
  }
  const handleNext = async () => {
    const validationSchema = Yup.object().shape({
      name_legal: Yup.string().required('Legal Name is required'),
      national_id: Yup.string().required('Business ID is required'),
      national_id_issue_country: Yup.string().required('Registration Country is required'),
      national_id_registration_authority: Yup.string().required('Registration Authority is required'),
      website: Yup.string().required('Website is required'),
      address_line: Yup.string().required('Address Line is required'),
    })
    const res = formRef.current.values
    validationSchema
      .validate(res, { abortEarly: false })
      .then(() => {
        setPage(2)
        setFormError('')
      })
      .catch(() => {
        setFormError('Error: Missing or invalid fields')
      })
  }
  const handleGleifData = (data) => {
    const countryList = []
    const tempGleifList = {}
    if (data && data?.length > 0) {
      data?.forEach((item) => {
        if (item?.country_code) {
          if (tempGleifList[item?.country_code]) {
            tempGleifList[item?.country_code] = [...tempGleifList[item?.country_code], item]
          } else {
            tempGleifList[item?.country_code] = [item]
          }
        }
      })
    }
    Object.keys(tempGleifList).forEach((key) => {
      countryList.push({ value: tempGleifList[key]?.[0]?.country_code, label: tempGleifList[key]?.[0]?.country })
    })
    setGleifCountryListOptions(countryList)
    setGleifList(tempGleifList)
  }

  // UseEffects
  useEffect(() => {
    if (registerVaspError) {
      if (registerVaspError?.response?.status < 500) {
        showAlert({ type: 'error', message: registerVaspError?.response?.data?.context })
      } else {
        showAlert({ type: 'error', message: 'An error occurred in registering' })
      }
    }
  }, [registerVaspError])
  useEffect(() => {
    getUserAuth({ hash: token })
    getGleif()
  }, [])
  useEffect(() => {
    if (getUserAuthData) handleUserAuthData()
  }, [getUserAuthData])
  useEffect(() => {
    if (getGleifData) handleGleifData(getGleifData)
  }, [getGleifData])
  return (
    <FormWrapper>
      {getUserAuthError && <FormError>Link has expired.</FormError>}

      {isRegisterVaspSuccess && !registerVaspError && (
        <>
          <FormTitle>
            Thank you for signing up! The Ospree team will verify your registration. We will get back to you as soon as
            possible.
          </FormTitle>
          <ButtonContainer href="/login">
            <Button fullWidth>Go Back</Button>
          </ButtonContainer>
        </>
      )}

      {registerVaspData && registerVaspError && <FormError>An error occurred in signing up.</FormError>}

      {isGetUserAuthLoading && <LoaderCircle />}
      {!isGetUserAuthLoading && !isRegisterVaspSuccess && !getUserAuthError && initialValues && (
        <Form
          initialValues={initialValues}
          formRef={formRef}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {!registerVaspData && <FormTitle>Sign up to Ospree</FormTitle>}
          {formError && <FormError>{formError}</FormError>}
          <FormNavigation pageNumber={page} headers={[{ label: 'VASP Details' }, { label: 'User Credentials' }]} />
          <FormGroupWrapper>
            <FormGroupContainer page={page}>
              <FormInputGroupItem>
                <FormTextField label="Legal Name *" name="name_legal" />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <FormTextField label="Business ID *" name="national_id" />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <FormSearchSelect
                  label="Registration Country *"
                  name="national_id_issue_country"
                  options={gleifCountryListOptions}
                />
              </FormInputGroupItem>
              <FormInputGroupItem onClick={forceUpdate}>
                <FormSearchSelect
                  label="Registration Authority *"
                  name="national_id_registration_authority"
                  options={gleifList?.[formRef?.current?.values?.national_id_issue_country]?.map((item) => ({
                    label: item?.display_name,
                    value: item?.registration_authority_code,
                  }))}
                />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <FormTextField label="Website *" name="website" />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <FormTextField label="Address Line *" name="address_line" />
              </FormInputGroupItem>
            </FormGroupContainer>

            {/* USER */}
            <FormGroupContainer page={page}>
              <FormInputGroupItem>
                <FormTextField label="Email *" type="email" name="email_contact" disabled />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <FormTextField label="First Name *" name="name_first" />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <FormTextField label="Last Name *" name="name_last" />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <FormTextField label="Password *" type="password" name="password" />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <FormTextField label="Confirm Password *" type="password" name="confirm_password" />
              </FormInputGroupItem>
            </FormGroupContainer>
          </FormGroupWrapper>
          {page === 1 && (
            <Button fullWidth onClick={handleNext}>
              Next
            </Button>
          )}
          {page === 2 && (
            <ButtonWrapper>
              <Button fullWidth disabled={isRegisterVaspLoading} onClick={() => setPage(1)} variant="outlined">
                Back
              </Button>
              <Button fullWidth type="submit" disabled={isRegisterVaspLoading}>
                {isRegisterVaspLoading ? 'Submitting...' : 'Signup'}
              </Button>
            </ButtonWrapper>
          )}
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
VaspSignupForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    external_id: '',
    email: '',
    name_first: '',
    name_last: '',
    incorporation_country: '',
    inc_date: '',
    primary_activity_id: 0,

    created: '',
    last_modified: '',

    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    timezone_code: '',
  },
}

// Proptypes Validation
VaspSignupForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    TIMEZONES: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.func,
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    external_id: PropTypes.string,
    email: PropTypes.string,
    name_first: PropTypes.string,
    name_last: PropTypes.string,
    incorporation_country: PropTypes.string,
    inc_date: PropTypes.string,
    primary_activity_id: PropTypes.number,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    timezone_code: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspSignupForm)
