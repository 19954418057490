/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Utils
import { notifyPaymentRequired } from 'common/utils/customerValidation'

// Styled Elements
import {
  SidebarWrapper,
  SidebarBrand,
  SidebarBrandLogo,
  SidebarMenuNav,
  SidebarMenuNavList,
  SidebarMenuNavItem,
  SidebarMenuNavLink,
  SidebarMenuNavText,
  SidebarHelpWrapper,
  SidebarHelpContainer,
  HelpMenuWrapper,
  HelpMenuContainer,
  Divider,
  SidebarMenuNavLinkDemo,
} from './Sidebar.elements'

// assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'
import BlockchainAnalyticsIcon from 'assets/images/blockchainAnalytics'
import CaseManagementIcon from 'assets/images/caseManagement'
import DirectoryIcon from 'assets/images/directory'
import PlusDocumentIcon from 'assets/images/plusDocument'
import TravelRuleIcon from 'assets/images/travelRule'
import SettingsIcon from 'assets/images/settings'
import MarkerIcon from 'assets/images/marker'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useGetAddressChains } from 'core/hooks/api'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, setShowOptions) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function Sidebar(props) {
  // Destructure
  const { actions, address, children } = props
  const { toggleUpgradeModal, setAddressChains, setDefaultAddressProvider } = actions
  const { addressChains } = address

  // Local State
  const [isHelpOpen, setIsHelpOpen] = useState(false)

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', {})
  const { getAddressChains, getAddressChainsData } = useGetAddressChains()

  // Refs
  const wrapperRef = useRef()

  // Functions
  function handleAddressChains() {
    if (!getAddressChainsData || Object?.keys(getAddressChainsData || {})?.length < 1) return
    // !Idk why but this is causing some bugs but we need this to optimize the process
    // if (addressChains && defaultAddressProvider) return
    const newAddressChains = {}
    const newDefaultAddressProvider = {}
    getAddressChainsData.forEach((item) => {
      // Destructure with default values
      const {
        data_type = '',
        provider_code = '',
        symbol = '',
        is_user_default = false,
        integration_id = '',
      } = item || {}

      // Initialize the data_type and provider_code if they don't exist
      if (!newAddressChains[data_type]) newAddressChains[data_type] = {}
      if (!newAddressChains[data_type][provider_code]) newAddressChains[data_type][provider_code] = {}
      // Insert the item directly
      newAddressChains[data_type][provider_code][symbol] = item
      // Handle the default address provider
      if (is_user_default && !newDefaultAddressProvider[data_type]) {
        newDefaultAddressProvider[data_type] = { provider_code, integration_id, data_type }
      }
    })

    // Sorting by symbol using .sort()
    Object.keys(newAddressChains).forEach((data_type) => {
      Object.keys(newAddressChains[data_type]).forEach((provider_code) => {
        // Get the symbols and sort them
        const symbols = Object.keys(newAddressChains[data_type][provider_code])
        const sortedSymbols = symbols.sort() // Sort the symbols
        // Create a new object to store sorted entries
        const sortedPartner = {}
        sortedSymbols.forEach((symbol) => {
          sortedPartner[symbol] = newAddressChains[data_type][provider_code][symbol]
        })
        // Replace the unsorted partner object with the sorted one
        newAddressChains[data_type][provider_code] = sortedPartner
      })
    })

    setAddressChains(newAddressChains)
    setDefaultAddressProvider(newDefaultAddressProvider)
  }

  // UseEffects
  useEffect(() => {
    if ((!addressChains || Object?.keys(addressChains || {})?.length < 1) && userCredentials.business_id) {
      getAddressChains()
    }
  }, [])
  useEffect(() => handleAddressChains(), [getAddressChainsData])
  // Closing options on click away
  useOutsideAlerter(wrapperRef, setIsHelpOpen)
  return (
    <SidebarWrapper>
      <SidebarMenuNav>
        <SidebarMenuNavList>
          <SidebarBrand>
            <NavLink to="/">
              <SidebarBrandLogo>
                <OspreeLogoIcon />
              </SidebarBrandLogo>
            </NavLink>
          </SidebarBrand>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/blockchain-analytics">
              <BlockchainAnalyticsIcon />
              <SidebarMenuNavText>Blockchain Analytics</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/travelrule">
              <TravelRuleIcon />
              <SidebarMenuNavText>Travel Rule</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/directory">
              <DirectoryIcon />
              <SidebarMenuNavText>Directory</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink activeClassName="active" to="/reports">
              <PlusDocumentIcon />
              <SidebarMenuNavText>Reports</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>

          <SidebarMenuNavItem>
            <SidebarMenuNavLinkDemo onClick={() => notifyPaymentRequired(toggleUpgradeModal)}>
              <CaseManagementIcon />
              <SidebarMenuNavText>Case Management</SidebarMenuNavText>
            </SidebarMenuNavLinkDemo>
          </SidebarMenuNavItem>

          {userCredentials?.role_id === 0 && (
            <SidebarMenuNavItem>
              <SidebarMenuNavLink activeClassName="active" to="/admin/vasps">
                <SettingsIcon />
                <SidebarMenuNavText>Admin Vasps</SidebarMenuNavText>
              </SidebarMenuNavLink>
            </SidebarMenuNavItem>
          )}
          {children}
        </SidebarMenuNavList>

        <SidebarMenuNavList ref={wrapperRef}>
          <SidebarHelpWrapper>
            <SidebarHelpContainer active={isHelpOpen} onClick={() => setIsHelpOpen(!isHelpOpen)}>
              <MarkerIcon />
              <SidebarMenuNavText>Get Help</SidebarMenuNavText>
            </SidebarHelpContainer>

            <HelpMenuWrapper active={isHelpOpen}>
              <HelpMenuContainer
                active={isHelpOpen}
                onClick={() => {
                  window.open('https://docs.ospree.io', '_blank')
                }}
              >
                Learn More
              </HelpMenuContainer>
              <Divider />
              <HelpMenuContainer
                active={isHelpOpen}
                onClick={() => {
                  window.open('https://www.ospree.io/contact/customers', '_blank')
                }}
              >
                Book a call
              </HelpMenuContainer>
              <Divider />
              <HelpMenuContainer active={isHelpOpen} onClick={() => window.open('https://ospree.io/help/', '_blank')}>
                Visit knowledge base
              </HelpMenuContainer>
            </HelpMenuWrapper>
          </SidebarHelpWrapper>

          <SidebarMenuNavItem>
            <SidebarMenuNavLink exact to="/settings">
              <SettingsIcon />
              <SidebarMenuNavText>Settings</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
        </SidebarMenuNavList>
      </SidebarMenuNav>
    </SidebarWrapper>
  )
}

// Default Props
Sidebar.defaultProps = {
  children: '',
  toggleUpgradeModal: () => {},
}

// Proptypes Validation
Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  toggleUpgradeModal: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
