import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  width: 100%;

  & button {
    margin-bottom: 20px;
  }
`
