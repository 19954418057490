import { useMutation } from 'react-query'

// Services
import { UpdateAddressHosted } from 'services/api/Addresses'

export default () => {
  const {
    mutate: updateAddressHosted,
    data: updateAddressHostedData,
    error: updateAddressHostedError,
    isLoading: isUpdateAddressHostedLoading,
    isSuccess: isUpdateAddressHostedSuccess,
    isError: isUpdateAddressHostedError,
  } = useMutation(UpdateAddressHosted)

  return {
    updateAddressHosted,
    updateAddressHostedData,
    updateAddressHostedError,
    isUpdateAddressHostedLoading,
    isUpdateAddressHostedSuccess,
    isUpdateAddressHostedError,
  }
}
