import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import moment from 'moment'

// Constants
// import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Assets
import { CloseIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Hooks
import { usePostTransactionReport } from 'core/hooks/api'

// Styled Elements
import {
  ModalFooterWrapper,
  ModalBodyWrapper,
  ModalFooterButton,
  RowContainer,
} from './ReportTransactionModal.elements'

// Views
import { LoadingTablePlaceholder, Modal, ModalHeader, ModalBody, ModalFooter, Button, Checkbox } from 'views/components'

// constants
import { REPORT_TRANSACTION_DATA_TYPE_OPTIONS } from 'common/constants/formOptions'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function ReportTransactionModal(props) {
  // Destructure
  const { ui, transaction, actions } = props

  // Store Actions
  const { toggleTransactionReportModal, setTransactionReportDetails } = actions

  // Store State
  const { isTransactionReportModalOpen } = ui
  const { transactionReportDetails } = transaction

  // Hooks
  const {
    postTransactionReport,
    postTransactionReportData,
    isPostTransactionReportError,
    isPostTransactionReportLoading,
  } = usePostTransactionReport()

  // Local State
  const [formValue, setFormValue] = useState({ sections: [] })
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  // Functions
  const toggleModal = () => {
    toggleTransactionReportModal()
    setTransactionReportDetails({})
  }
  const handleSubmit = () => {
    // let start_range = moment().subtract(30, 'days').format(DASHED_DATE_FORMAT)
    // const end_range = moment().add(1, 'days').format(DASHED_DATE_FORMAT)
    // if (REPORT_DATE_RANGE_OPTIONS[0].value === formValue.date_range) {
    //   start_range = moment().subtract(30, 'days').format(DASHED_DATE_FORMAT)
    // }
    // if (REPORT_DATE_RANGE_OPTIONS[1].value === formValue.date_range) {
    //   start_range = moment().subtract(180, 'days').format(DASHED_DATE_FORMAT)
    // }
    // if (REPORT_DATE_RANGE_OPTIONS[2].value === formValue.date_range) {
    //   start_range = moment().subtract(365, 'days').format(DASHED_DATE_FORMAT)
    // }
    postTransactionReport({
      transaction_id: transactionReportDetails.transaction_id,
      sections: formValue.sections,
      // start_range,
      // end_range,
    })
  }

  // UseEffects
  useEffect(() => postTransactionReportData && setIsSuccess(true), [postTransactionReportData])
  useEffect(() => isPostTransactionReportError && setIsError(true), [isPostTransactionReportError])
  useEffect(() => {
    if (isTransactionReportModalOpen) {
      setFormValue({ sections: [], format: '' })
      setIsError(false)
      setIsSuccess(false)
    }
  }, [isTransactionReportModalOpen])
  return (
    <Modal isOpen={isTransactionReportModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<CloseIcon onClick={toggleModal} />}>
        REQUEST REPORT
      </ModalHeader>
      <ModalBody>
        {isError && !isPostTransactionReportLoading && (
          <ModalBodyWrapper style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            An error occurred in generating a report. Please try again later.
          </ModalBodyWrapper>
        )}
        {isSuccess && !isPostTransactionReportLoading && (
          <ModalBodyWrapper style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            <span>
              Successfully requested for report, generating the report may take a few minutes. Please view the progress
              in the <a href="/reports/transaction">reports tab</a>.
            </span>
          </ModalBodyWrapper>
        )}
        {isPostTransactionReportLoading && (
          <ModalBodyWrapper style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            <LoadingTablePlaceholder style={{ padding: '45px 0' }} />
          </ModalBodyWrapper>
        )}
        {!isPostTransactionReportLoading && !isError && !isSuccess && (
          <ModalBodyWrapper>
            <RowContainer style={{ justifyContent: 'flex-start', gap: '50px' }}>
              <Checkbox
                value={formValue.sections}
                onChange={(value) => setFormValue({ ...formValue, sections: value })}
                label="DATA TYPE"
                options={REPORT_TRANSACTION_DATA_TYPE_OPTIONS}
                name="sections"
              />
            </RowContainer>
          </ModalBodyWrapper>
        )}
      </ModalBody>
      <ModalFooter>
        {!isPostTransactionReportLoading && !isError && !isSuccess && (
          <ModalFooterWrapper>
            <ModalFooterButton onClick={toggleModal}>
              <Button variant="outlined">Cancel</Button>
            </ModalFooterButton>
            <ModalFooterButton>
              <Button onClick={handleSubmit} variant="outlined" disabled={formValue.sections.length < 1}>
                Request
              </Button>
            </ModalFooterButton>
          </ModalFooterWrapper>
        )}
      </ModalFooter>
    </Modal>
  )
}

// Default Props
ReportTransactionModal.defaultProps = {
  ui: {},
  transaction: {},
  actions: {},
}

// Proptypes Validation
ReportTransactionModal.propTypes = {
  ui: PropTypes.shape({ isTransactionReportModalOpen: PropTypes.bool }),
  transaction: PropTypes.shape({
    transactionReportDetails: PropTypes.shape({
      transaction_id: PropTypes.string,
      transaction: PropTypes.string,
      chain: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    toggleTransactionReportModal: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setTransactionReportDetails: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportTransactionModal)
