import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

// Styled Elements
import { Text, Label, Title, SubTitle, TableColWrapper, TableRowContainer } from './RecipientsTableItem.elements'

// Constants
import { RISK_OPTIONS } from 'common/constants/formOptions'

// Views
import { CardTableItem, Button } from 'views/components'

function RecipientsTableItem(props) {
  // Destructure
  const { recipientDetails } = props

  const {
    reference_id,
    id,
    // account_risk_desc,
    account_risk_value,
    account_type_id,
    // address_country_code,
    address_country_desc,
    // created,
    display_country,
    display_date,
    display_name,
    transfers_in,
    transfers_out,
    status_desc,
    // status_id,
  } = recipientDetails

  // Functions

  // useEffect

  return (
    <NavLink to={`/directory/recipients/details/${id}`}>
      <CardTableItem key={reference_id}>
        <TableColWrapper pending={transfers_out > 0 || transfers_in > 0}>
          <Title>ID: {reference_id}</Title>
          <SubTitle>{account_type_id === 1 ? 'INDIVIDUAL RECIPIENT' : 'BUSINESS RECIPIENT'}</SubTitle>
          <Button variant="outlined" disabled={transfers_out < 1 && transfers_in < 1} size="tiny">
            PENDING TRANSFERS
          </Button>
        </TableColWrapper>

        <TableColWrapper>
          <TableRowContainer>
            <Label>{account_type_id === 1 ? 'Name: ' : 'Company Name: '}</Label>
            <Text>{display_name || '-'}</Text>
          </TableRowContainer>
          <TableRowContainer>
            <Label>Country of Domicile: </Label>
            <Text>{address_country_desc || '-'}</Text>
          </TableRowContainer>
          {account_type_id === 1 && (
            <TableRowContainer>
              <Label>{'Date of Birth: '} </Label>
              <Text>{display_date || '-'}</Text>
            </TableRowContainer>
          )}
          <TableRowContainer>
            <Label>{account_type_id === 1 ? 'Nationality: ' : 'Country of Incorporation: '} </Label>
            <Text>{display_country || '-'}</Text>
          </TableRowContainer>
        </TableColWrapper>

        <TableColWrapper>
          <TableRowContainer>
            <Label>Status: </Label>
            <Text>{status_desc}</Text>
          </TableRowContainer>
          <TableRowContainer>
            <Label>Recipient Risk: </Label>
            <Text>{account_risk_value ? RISK_OPTIONS[account_risk_value].label : '-'}</Text>
          </TableRowContainer>
          <TableRowContainer>
            <Label>Transfers In: </Label>
            <Text>{transfers_in}</Text>
          </TableRowContainer>
          <TableRowContainer>
            <Label>Transfers Out: </Label>
            <Text>{transfers_out}</Text>
          </TableRowContainer>
        </TableColWrapper>
      </CardTableItem>
    </NavLink>
  )
}

// Default Props
RecipientsTableItem.defaultProps = {}

// Proptypes Validation
RecipientsTableItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  recipientDetails: PropTypes.any,
}

export default RecipientsTableItem
