import { useMutation } from 'react-query'

// Services
import { UpdateVaspUser } from 'services/api/Business'

export default () => {
  const {
    mutate: updateVaspUser,
    data: updateVaspUserData,
    error: updateVaspUserError,
    isLoading: isUpdateVaspUserLoading,
    isSuccess: isUpdateVaspUserSuccess,
    isError: isUpdateVaspUserError,
  } = useMutation(UpdateVaspUser)

  return {
    updateVaspUser,
    updateVaspUserData,
    updateVaspUserError,
    isUpdateVaspUserLoading,
    isUpdateVaspUserSuccess,
    isUpdateVaspUserError,
  }
}
