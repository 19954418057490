import styled from 'styled-components'

export const SelectWrapper = styled.div`
  width: fit-content;
  min-height: 35.5px;
  display: flex;
  gap: ${({ vertical }) => (vertical ? '0px' : '5px')};
  margin: 0 !important;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  align-items: ${({ vertical }) => (vertical ? 'flex-start' : 'center')};
`

export const FormGroupWrapper = styled.div`
  position: relative;
  margin: 0 !important;
  width: fit-content;
`

export const Label = styled.label`
  position: relative;
  z-index: 1;
  padding: ${({ vertical }) => (vertical ? '0 20px 0 0' : '0')};

  width: fit-content;
  color: ${({ theme }) => theme.colors.darkGray};
  margin: 0 !important;
  font-size: 12px;
  font-weight: 200;
  word-break: keep-all;
`

export const SelectInput = styled.select`
  display: none;
`

export const SelectOption = styled.option`
  color: ${({ theme }) => theme.colors.darkGray};
`

export const SelectInputLabel = styled.div`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  position: relative;
  // TODO: Use theme functions
  &:after {
    content: '';

    position: absolute;
    top: 42%;
    right: 0px;

    display: ${({ disabled }) => (disabled ? 'none' : 'inline-block')};
    margin-left: 0.255em;
    vertical-align: 0.255em;

    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: ${({ theme }) => theme.colors.darkGray};
  }

  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : 'transparent')};
  height: fit-content;
  width: fit-content;

  font-size: 12px;
  font-weight: 400;
  box-shadow: none;
  padding: 0 20px 0 0;
`

export const SelectInputOptionsContainer = styled.div`
  position: absolute;
  top: 110%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.darkGray};

  padding: 5px;
  width: fit-content;
  max-height: 300px;
  min-width: 100px;
  overflow-y: auto;

  font-size: 14px;
  border-radius: 8px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  user-select: none;
  z-index: 2;
`

export const SelectInputOptions = styled.div`
  width: 100%;
  padding: 2px 10px;
  gap: 10px;
  word-break: keep-all;
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.darkGray};

  cursor: pointer;
  transition: 0.4s;

  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0);

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.blue}`};
    color: ${({ theme }) => `${theme.colors.blue}`};
  }
`

export const SelectPlaceholder = styled.div`
  color: ${(props) => (props.disabled ? props.theme.colors.darkGray : props.theme.colors.blue)};
  font-weight: 400;
  margin: 0 !important;
  font-size: 14px;
  word-break: keep-all;
`

export const Abbreviation = styled.div`
  color: ${({ theme }) => theme.colors.lightGray};
`
