import { useMutation } from 'react-query'

// Services
import { CreateVaspUserActivation } from 'services/api/Business'

export default () => {
  const {
    mutate: createVaspUserActivation,
    data: createVaspUserActivationData,
    error: createVaspUserActivationError,
    isLoading: isCreateVaspUserActivationLoading,
    isSuccess: isCreateVaspUserActivationSuccess,
    isError: isCreateVaspUserActivationError,
  } = useMutation(CreateVaspUserActivation)

  return {
    createVaspUserActivation,
    createVaspUserActivationData,
    createVaspUserActivationError,
    isCreateVaspUserActivationLoading,
    isCreateVaspUserActivationSuccess,
    isCreateVaspUserActivationError,
  }
}
