/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import { PlusWhiteIcon, CloseIcon, VerticalElipsisIcon } from 'assets/images'

// Constants
import { MODULE_ID_MAPPING } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useCreateNote } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  Text,
  Title,
  ColContainer,
  RowWrapper,
  RowContainer,
  BlockContainer,
  FlowWrapper,
  IconContainer,
  ColWrapper,
} from '../../TransactionsTableItemDetails.elements'

// Components
import {
  Button,
  TextArea,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  LoadingTablePlaceholder,
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function BusinessNotes(props) {
  // Destructure
  const { address_data, actions, businessNotes, setBusinessNotes, isLoading, isFetchError } = props

  const { toggleDeleteAddressNoteModal, showAlert } = actions

  // Local state
  const [isAddNoteActive, setIsAddNoteActive] = useState(false)
  const [notesValue, setNotesValue] = useState('')

  // Hooks
  const { createNote, createNoteData, createNoteError, isCreateNoteLoading } = useCreateNote()
  const [credentials] = useLocalStorage('userCredentials', {})

  function saveNote() {
    createNote({
      note: notesValue,
      module_id: MODULE_ID_MAPPING.address,
      record_id: address_data.id,
      business_id: credentials.business_id,
      created_by: `${credentials.name_first} ${credentials.name_last}`,
    })
  }
  function handleSavedNote() {
    setNotesValue('')
    setIsAddNoteActive(false)
    setBusinessNotes((prevValue) =>
      // ! DATA IS HARDCODED
      [createNoteData, ...prevValue]
    )
  }

  // UseEffects
  useEffect(() => createNoteData && handleSavedNote(), [createNoteData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (isFetchError) showAlert({ type: 'error', message: 'An error occurred in fetching notes' })
  }, [isFetchError])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (createNoteError) showAlert({ type: 'error', message: 'An error occurred in creating note' })
  }, [createNoteError])
  return (
    <FlowWrapper>
      {(isLoading || isCreateNoteLoading) && <LoadingTablePlaceholder />}
      {!isAddNoteActive && !isLoading && (
        <RowWrapper style={{ justifyContent: 'flex-end', margin: '-50px 0 20px 0' }}>
          <Button variant="outlined" size="medium" endIcon={<PlusWhiteIcon />} onClick={() => setIsAddNoteActive(true)}>
            Add note
          </Button>
        </RowWrapper>
      )}
      {isAddNoteActive && !isLoading && (
        <RowWrapper>
          <ColWrapper>
            <BlockContainer>
              <TextArea
                style={{ background: 'transparent', padding: '0', border: 'none' }}
                value={notesValue}
                onChange={(e) => setNotesValue(e.currentTarget.value)}
              />
            </BlockContainer>
            <RowContainer style={{ justifyContent: 'flex-end', padding: '10px 0 20px 0' }}>
              <Button
                variant="outlined"
                size="medium"
                endIcon={<CloseIcon />}
                onClick={() => {
                  setNotesValue('')
                  setIsAddNoteActive(false)
                }}
              >
                Cancel
              </Button>
              <Button variant="outlined" size="medium" endIcon={<PlusWhiteIcon />} onClick={() => saveNote()}>
                Save
              </Button>
            </RowContainer>
          </ColWrapper>
        </RowWrapper>
      )}
      {businessNotes[0] &&
        !isLoading &&
        businessNotes.map((data) => (
          <RowWrapper key={data.id}>
            <BlockContainer>
              <RowContainer style={{ paddingBottom: '20px' }}>
                <IconContainer>{data.created_by && `${data.created_by[0]}${data.created_by[1]}`}</IconContainer>
                <ColContainer style={{ width: 'auto', justifyContent: 'center' }}>
                  <Title style={{ margin: '0' }}>{data.created_by}</Title>
                </ColContainer>
              </RowContainer>
              <RowContainer>
                <Text>{data.note}</Text>
              </RowContainer>

              <UncontrolledDropdown direction="down" style={{ position: 'absolute', top: '20px', right: '20px' }}>
                <DropdownToggle>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<VerticalElipsisIcon />}
                    style={{ padding: '5px', borderRadius: '4px' }}
                  />
                </DropdownToggle>
                <DropdownMenu left style={{ boxShadow: ' 0px 0px 10px rgb(0 0 0 / 15%)' }}>
                  <DropdownItem onClick={toggleDeleteAddressNoteModal}>
                    <Text>Delete Note</Text>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </BlockContainer>
          </RowWrapper>
        ))}
    </FlowWrapper>
  )
}

// Default Props
BusinessNotes.defaultProps = {
  actions: {},
  address_data: {},
}

// Proptypes Validation
BusinessNotes.propTypes = {
  actions: PropTypes.shape({
    toggleDeleteAddressNoteModal: PropTypes.func,
    showAlert: PropTypes.func,
  }),

  address_data: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    create_tz: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  isFetchError: PropTypes.bool,
  businessNotes: PropTypes.arrayOf({}),
  setBusinessNotes: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessNotes)
