import { useMutation } from 'react-query'

// Services
import { AddVaspProvider } from 'services/api/Business'

export default () => {
  const {
    mutate: addVaspProvider,
    data: addVaspProviderData,
    error: addVaspProviderError,
    isLoading: isAddVaspProviderLoading,
    isSuccess: isAddVaspProviderSuccess,
    isError: isAddVaspProviderError,
  } = useMutation(AddVaspProvider)

  return {
    addVaspProvider,
    addVaspProviderData,
    addVaspProviderError,
    isAddVaspProviderLoading,
    isAddVaspProviderSuccess,
    isAddVaspProviderError,
  }
}
