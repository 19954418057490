/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import moment from 'moment'

// Store
import { actions } from 'core/store'

// Styled Elements
import { Wrapper, Container } from './AddressesTableItemDetails.elements'

// Hooks
import { useFetchAccount, useFetchRecipient, useGetAddressDetails, useCreateOrUpdateAddress } from 'core/hooks/api'

// Styled Elements
import { FlowWrapper } from './AddressesTableItemDetails.elements'
import RiskDataSection from './ItemDetailsComponents/RiskDataSection'
import LinkedAccount from './ItemDetailsComponents/LinkedAccount'
import SummarySection from './ItemDetailsComponents/SummarySection'
import FooterSection from './ItemDetailsComponents/FooterSection'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AddressesTableItemDetails(props) {
  // Destructure
  const {
    isExpanded,
    financialData,
    setFinancialData,
    riskData,
    setRiskData,
    addressData,
    setAddressData,
    accountDataProp,
    setAccountData,
    actions,
    address,
  } = props
  const { showAlert } = actions
  const { addressChains } = address

  // Hooks
  const { getAddressDetails, getAddressDetailsData, getAddressDetailsError, isGetAddressDetailsLoading } =
    useGetAddressDetails()
  const { getAccount, accountData, accountError, isAccountLoading } = useFetchAccount()
  const { getRecipient, recipientData, recipientError, isRecipientLoading } = useFetchRecipient()
  const {
    createOrUpdateAddress,
    createOrUpdateAddressData,
    createOrUpdateAddressError,
    isCreateOrUpdateAddressLoading,
  } = useCreateOrUpdateAddress()

  // local state
  const [currentIpUsed, setCurrentIpUsed] = useState()
  const [availableIps, setAvailableIps] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  // Functions
  const fetchData = () => {
    if (addressData.link_type === 1) getAccount({ account_id: addressData.link_id })
    else if (addressData.link_type === 2) getRecipient({ account_id: addressData.link_id })
  }
  const handleData = () => {
    if (accountData) setAccountData(accountData)
    if (recipientData) setAccountData(recipientData)
  }
  const handleAvailableIps = () => {
    if (availableIps) return
    if (!addressChains?.risk) return
    let newAvailableIps = {}
    Object.keys(addressChains?.risk).forEach((item) => {
      const ip = addressChains?.risk[item]
      Object.keys(ip).forEach((ipChain) => {
        if (ip[ipChain]?.chain_name === addressData?.chain) {
          newAvailableIps = {
            ...newAvailableIps,
            [ip[ipChain]?.provider_code]: {
              integration_id: ip[ipChain]?.integration_id,
              provider_code: ip[ipChain]?.provider_code,
            },
          }
        }
      })
    })
    setAvailableIps(newAvailableIps)
  }

  const handleCreateOrUpdateAddress = () => {
    if (createOrUpdateAddressData) {
      setRiskData((prevValue) => {
        console.log(prevValue)
        if (!prevValue) {
          return [createOrUpdateAddressData.risk_data]
        }
        const newRiskData = []
        const oldRiskData = []
        prevValue.forEach((item) => {
          if (item.provider_name === currentIpUsed) {
            newRiskData.push(createOrUpdateAddressData.risk_data)
          } else {
            oldRiskData.push(item)
          }
        })
        if (newRiskData?.length < 1) {
          return [createOrUpdateAddressData.risk_data, ...prevValue]
        }
        return [...newRiskData, ...oldRiskData]
      })
      setFinancialData(createOrUpdateAddressData.financial_data)
      const newAddressData = { ...addressData }
      if (
        createOrUpdateAddressData.financial_data?.balance_usd !== null &&
        createOrUpdateAddressData.financial_data?.balance_usd !== undefined
      ) {
        newAddressData.balance_usd = createOrUpdateAddressData.financial_data.balance_usd
      }
      if (
        createOrUpdateAddressData.financial_data?.balance !== null &&
        createOrUpdateAddressData.financial_data?.balance !== undefined
      ) {
        newAddressData.balance = createOrUpdateAddressData.financial_data.balance
      }
      if (
        createOrUpdateAddressData.risk_data?.risk_score !== null &&
        createOrUpdateAddressData.risk_data?.risk_score !== undefined
      ) {
        newAddressData.risk_score = createOrUpdateAddressData.risk_data.risk_score
      }
      setAddressData(newAddressData)
      setCurrentIpUsed()
    }
  }

  // UseEffect
  useEffect(() => handleData(), [accountData, recipientData])
  useEffect(() => addressData.link_id && isExpanded && !accountDataProp && fetchData(), [isExpanded])
  useEffect(() => {
    handleAvailableIps()
  }, [])
  useEffect(() => {
    if (isExpanded && !financialData && !riskData && Number.isInteger(addressData?.risk_score)) {
      getAddressDetails({ address_id: addressData.id })
    }
  }, [isExpanded])
  useEffect(() => {
    if (getAddressDetailsData) {
      setAddressData({ ...addressData, modified_by_name: getAddressDetailsData?.modified_by_name })
      setRiskData(getAddressDetailsData.risk_data)
      setFinancialData(getAddressDetailsData.financial_data)
    }
  }, [getAddressDetailsData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (getAddressDetailsError) showAlert({ type: 'error', message: 'An error occurred in fetching addresses' })
  }, [getAddressDetailsError])
  useEffect(() => {
    if (isGetAddressDetailsLoading) setIsLoading(true)
    else setIsLoading(false)
  }, [isGetAddressDetailsLoading])

  useEffect(() => {
    handleCreateOrUpdateAddress()
  }, [createOrUpdateAddressData])
  useEffect(() => {
    console.log('ERROR: ', createOrUpdateAddressError?.response)
  }, [createOrUpdateAddressError])

  return (
    <Wrapper>
      <Container>
        <FlowWrapper key={currentIpUsed}>
          {/* -------FINANCIAL DATA------- */}
          <SummarySection financialData={financialData} addressData={addressData} />

          {/* -------LINKED ACCOUNT DATA------- */}
          <LinkedAccount
            accountDataProp={accountDataProp}
            setAccountDataProp={setAccountData}
            address_data={addressData}
            isExpanded={isExpanded}
            isLoading={isAccountLoading || isRecipientLoading}
            isFetchError={accountError || recipientError}
          />

          {/* -------RISK DATA------- */}
          <RiskDataSection riskData={riskData} currentIpUsed={currentIpUsed} isLoading={isLoading} />

          {/* -------BOTTOM BUTTONS------- */}
          <FooterSection
            setFinancialData={setFinancialData}
            setRiskData={setRiskData}
            addressData={addressData}
            setAddressData={setAddressData}
            currentIpUsed={currentIpUsed}
            setCurrentIpUsed={setCurrentIpUsed}
            setIsLoading={setIsLoading}
            createOrUpdateAddress={createOrUpdateAddress}
            createOrUpdateAddressData={createOrUpdateAddressData}
            createOrUpdateAddressError={createOrUpdateAddressError}
            isCreateOrUpdateAddressLoading={isCreateOrUpdateAddressLoading}
          />
        </FlowWrapper>
      </Container>
    </Wrapper>
  )
}

// Default Props
AddressesTableItemDetails.defaultProps = {
  financialData: null,
  setFinancialData: () => {},
  riskData: null,
  setRiskData: () => {},
  addressData: null,
  setAddressData: () => {},
  actions: {},
  filters: {},
  address: {},
  ui: {},
  isExpanded: false,
}

// Proptypes Validation
AddressesTableItemDetails.propTypes = {
  financialData: PropTypes.instanceOf(Object),
  setFinancialData: PropTypes.func,
  riskData: PropTypes.instanceOf(Object),
  setRiskData: PropTypes.func,
  addressData: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    create_tz: PropTypes.string,
    link_type: PropTypes.number,
    link_id: PropTypes.string,
    modified_by_name: PropTypes.string,
  }),
  accountDataProp: PropTypes.shape({}),
  setAccountData: PropTypes.func,
  // businessNotes: PropTypes.arrayOf({}),
  // setBusinessNotes: PropTypes.func,
  setAddressData: PropTypes.func,
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    toggleAddressHistoryModal: PropTypes.func,
    toggleAddressReportModal: PropTypes.func,
    setAddressReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setIsLinkAddressDrawerOpen: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
    setAddressHistoryFilters: PropTypes.func,
    setAddressRemove: PropTypes.func,
    showAlert: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  address: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressesTableItemDetails)
