import styled from 'styled-components'

export const DrawerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 400px;
  padding: 20px;

  // Themed Properties
  background-color: ${({ theme }) => theme.colors.white};
`

export const DrawerCloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  cursor: pointer;

  // TODO: Use Theme Functions
  &:hover {
    opacity: 0.7;
  }
  & svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
`

export const FormWrapper = styled.div`
  padding-bottom: 40px;
`
export const FormTitle = styled.h4`
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0 0 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const ErrMsg = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0 10px 10px;
  color: ${({ theme }) => theme.colors.red};
`
