import { authAxios, createAxiosInstance } from 'common/utils/axios'

import { VASP_ENDPOINTS, LOCAL_URL } from 'common/constants/apiUrls'

export const authAxiosLocalServer = createAxiosInstance(LOCAL_URL)

const handler = authAxios

export const GetAllVasps = async (values) => {
  const response = await handler.get(VASP_ENDPOINTS.GET_ALL_VASPS, {
    params: { ...values },
  })

  const { data } = response.data

  return data
}

export const GetAllMidProspects = async (values) => {
  const response = await handler.get(VASP_ENDPOINTS.GET_ALL_MID_PROSPECTS, {
    params: { ...values },
  })

  const { data } = response.data

  return data
}

export const GetVasp = async ({ vasp_id }) => {
  const response = await handler.get(`${VASP_ENDPOINTS.GET_VASP}${vasp_id}`)

  const { data } = response.data

  return data
}
export const SearchVasp = async ({ search_term, ...rest }) => {
  const response = await handler.get(VASP_ENDPOINTS.SEARCH_VASP, {
    params: {
      search_term,
      ...rest,
    },
  })

  const { data } = response.data

  return data
}
export const SearchMidProspect = async ({ search_term }) => {
  const response = await handler.get(VASP_ENDPOINTS.SEARCH_MID_PROSPECT, {
    params: {
      search_term,
    },
  })

  const { data } = response.data

  return data
}

export const CreateVasp = async (values) => {
  const response = await handler.post(VASP_ENDPOINTS.CREATE_VASP, values)

  const { data } = response.data

  return data
}
export const UpdateVasp = async ({ vasp_id, values }) => {
  const response = await handler.patch(`${VASP_ENDPOINTS.UPDATE_VASP}/${vasp_id}`, { vasp_id, ...values })

  const { data } = response.data

  return data
}
