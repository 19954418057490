import React from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { ROLE_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import { FormInputGroupItem, FormGroupWrapper } from './UserForm.elements'

// Views
import { Form, FormTextField, FormSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UserForm(props) {
  // Destructure
  const { formRef, handleSubmit } = props

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')

  // Variables
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required.'),
    name_first: Yup.string().max(100).required('First name is required.'),
    name_last: Yup.string().max(100).required('Last name is required.'),
    role_id: Yup.number().required('Role is required.'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirm_password: Yup.string().when('password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    }),
  })

  return (
    <Form formRef={formRef} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <FormGroupWrapper>
        <FormInputGroupItem>
          <FormTextField label="Email (Required)" type="email" name="email" />
        </FormInputGroupItem>
        <FormInputGroupItem>
          <FormTextField label="First Name (Required)" name="name_first" />
        </FormInputGroupItem>
        <FormInputGroupItem>
          <FormTextField label="Last Name (Required)" name="name_last" />
        </FormInputGroupItem>
        <FormInputGroupItem>
          <FormSelect label="Role Type (Required)" name="role_id" options={ROLE_OPTIONS} />
        </FormInputGroupItem>
        {userCredentials?.role_id === 0 && (
          <>
            <FormInputGroupItem>
              <FormTextField label="Password" type="password" name="password" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Confirm Password" type="password" name="confirm_password" />
            </FormInputGroupItem>
          </>
        )}
      </FormGroupWrapper>
    </Form>
  )
}

// Default Props
UserForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    external_id: '',
    account_type_id: 0,
    status_id: 0,
    email: '',
    primary_activity_code: 0,
    nationality_code: '',
    name_first: '',
    name_middle: '',
    name_last: '',
    dob: '',

    created: '',
    last_modified: '',

    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    address_country_code: '',
  },
}

// Proptypes Validation
UserForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.shape({}),
  }),
  handleSubmit: PropTypes.func,
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setIsUserDrawerOpen: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    external_id: PropTypes.string,
    account_type_id: PropTypes.number,
    status_id: PropTypes.number,
    email: PropTypes.string,
    primary_activity_code: PropTypes.number,
    nationality_code: PropTypes.number,
    name_first: PropTypes.string,
    name_middle: PropTypes.string,
    name_last: PropTypes.string,
    dob: PropTypes.string,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    address_country_code: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
