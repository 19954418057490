import styled from 'styled-components'
// Views
import { Form as FormComponent } from 'views/components'

export const Form = styled(FormComponent)`
  background-color: ${({ theme }) => theme.colors.white};
`

export const FormWrapper = styled.div`
  & button {
    min-height: 56px;
    margin: 20px 0px 0px 0px;
  }
`

export const FormInputGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & input {
    background-color: ${({ theme }) => theme.colors.white};
  }
  & label {
    background-color: ${({ theme }) => theme.colors.lightGray};
    padding: 3px 10px;
  }
`
export const FormInputTimezoneGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > div > div > div {
    background-color: ${({ theme }) => theme.colors.white};
    min-height: 58px;
  }
  & label {
    background-color: ${({ theme }) => theme.colors.lightGray};
    padding: 3px 10px;
  }

  & > div > div :nth-child(1) {
    max-width: none;
  }
`
// export const FormInputGroupItem = styled.div`
//   margin: 0px 0px 20px 0px;
// `
export const FormGroupWrapper = styled.div`
  display: grid;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 20px;
`
export const FormGroupContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &:first-child {
    position: ${({ page }) => page !== 1 && 'absolute'};
    height: ${({ page }) => page !== 1 && '50%'};
    transform: ${({ page }) => page !== 1 && 'translateX(-500px)'};
  }
  &:last-child {
    position: ${({ page }) => page !== 2 && 'absolute'};
    height: ${({ page }) => page !== 2 && '50%'};
    transform: ${({ page }) => page !== 2 && 'translateX(500px)'};
  }
  transition: 0.5s;
`
export const FormTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGray};
  padding: 15px 20px;
  border-radius: 7px;
`
export const FormError = styled.h2`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.red};
  padding: 15px 20px;
  border-radius: 7px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`
export const ButtonContainer = styled.a`
  margin: 0;
  padding: 0;
`
