import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Store
import { actions } from 'core/store'

// Hooks
import { useGetVaspUsers } from 'core/hooks/api'

// Styled Elements
import { Container } from './UsersTab.elements'

// Views
import { Button } from 'views/components'
import UsersTabItem from '../UsersTabItem'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function UsersTab(props) {
  // Destructure
  const { actions, ui } = props
  const { isPageTableUpdated } = ui
  const { setIsUserDrawerOpen, setIsPageTableUpdated } = actions
  const { vasp_id } = useParams()

  // hooks
  const { getVaspUsers, getVaspUsersData, isGetVaspUsersLoading } = useGetVaspUsers()

  // state
  const [vaspUsers, setVaspUsers] = useState()

  // Functions
  const fetchData = () => {
    getVaspUsers({ vasp_id })
  }

  // UseEffects
  useEffect(() => fetchData(), [])
  useEffect(() => {
    if (isPageTableUpdated) {
      fetchData()
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])
  useEffect(() => {
    if (getVaspUsersData) {
      setVaspUsers(getVaspUsersData)
    }
  }, [getVaspUsersData])
  return (
    <Container>
      <Button onClick={() => setIsUserDrawerOpen(true)}>Add User</Button>
      {!isGetVaspUsersLoading && vaspUsers && vaspUsers?.map((item) => <UsersTabItem values={item} key={item?.id} />)}
    </Container>
  )
}

// Default Props
UsersTab.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
UsersTab.propTypes = {
  filters: PropTypes.shape({
    vaspDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setIsUserDrawerOpen: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,

    setIsEntityVaspDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
    setApproveVaspModal: PropTypes.func,
    setApproveVaspDetails: PropTypes.func,
    setRejectVaspModal: PropTypes.func,
    setRejectVaspDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab)
