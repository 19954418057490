import styled from 'styled-components'

export const TravelRuleTableWrapper = styled.div``
export const TravelRuleHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const TravelRuleHeaderContainer = styled.div`
  display: flex;
  gap: 15px;
`
