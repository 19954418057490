import styled from 'styled-components'

export const ButtonWrapper = styled.button`
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  font-weight: 400;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 7px;
  transition: 0.3s;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  /*-------------------------------
    Events Properties
  -------------------------------*/
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.darkGray};
    background: transparent;
    border: 1px solid ${({ theme }) => `${theme.colors.blue}40`};
    & svg > path {
      stroke: ${({ theme }) => theme.colors.blue};
      ${({ theme, variant }) => variant === 'outlined' && `stroke: ${theme.colors.darkGray} ;`}
      ${({ theme, color }) => color === 'default' && `stroke: ${theme.colors.white} ;`}
      ${({ theme, color }) => color === 'primary' && `stroke: ${theme.colors.blue} ;`}
      ${({ theme, color }) => color === 'secondary' && `stroke: ${theme.colors.red} ;`}
      ${({ theme, color }) => color === 'secondary-default' && `stroke: ${theme.colors.white} ;`}
      ${({ theme, color }) => color === 'tertiary' && `stroke: ${theme.colors.white} ;`}
    }
    & svg > line {
      stroke: ${({ theme }) => theme.colors.blue};
      ${({ theme, variant }) => variant === 'outlined' && `stroke: ${theme.colors.darkGray} ;`}
      ${({ theme, color }) => color === 'default' && `stroke: ${theme.colors.white} ;`}
      ${({ theme, color }) => color === 'primary' && `stroke: ${theme.colors.blue} ;`}
      ${({ theme, color }) => color === 'secondary' && `stroke: ${theme.colors.red} ;`}
      ${({ theme, color }) => color === 'secondary-default' && `stroke: ${theme.colors.white} ;`}
      ${({ theme, color }) => color === 'tertiary' && `stroke: ${theme.colors.white} ;`}
    }
    outline: none;

    ${({ theme, variant }) => variant === 'outlined' && `border: 1px solid ${theme.colors.blue}40;`}
    ${({ theme, variant }) => variant === 'outlined' && `background: ${theme.colors.white};`}
    ${({ theme, variant }) => variant === 'outlined' && `color: ${theme.colors.darkGray};`}
    
    ${({ theme, color }) => color === 'default' && `background: ${theme.colors.blue}00;`}
    ${({ theme, color }) => color === 'default' && `color: ${theme.colors.darkGray};`}
    ${({ theme, color }) => color === 'default' && `border: 1px solid ${theme.colors.blue}40;`}
    
    ${({ theme, color }) => color === 'primary' && `background: ${theme.colors.white};`}
    ${({ theme, color }) => color === 'primary' && `border: 1px solid ${theme.colors.white};`}
    
    ${({ theme, color }) => color === 'secondary' && `background: ${theme.colors.white};`}
    ${({ theme, color }) => color === 'secondary' && `color: ${theme.colors.red};`}
    ${({ theme, color }) => color === 'secondary' && `border: 1px solid ${theme.colors.red};`}
    
    ${({ theme, color }) => color === 'secondary-default' && `background: ${theme.colors.red};`}
    ${({ theme, color }) => color === 'secondary-default' && `color: ${theme.colors.white};`}
    ${({ theme, color }) => color === 'secondary-default' && `border: 1px solid ${theme.colors.red};`}
    
    ${({ theme, color }) => color === 'tertiary' && `background: ${theme.colors.blue};`}
    ${({ theme, color }) => color === 'tertiary' && `color: ${theme.colors.white};`}
    ${({ theme, color }) => color === 'tertiary' && `border: 1px solid ${theme.colors.blue};`}
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.darkGray} !important;
    background-color: ${({ theme }) => theme.colors.lightGray} !important;
    border: 1px solid ${({ theme }) => theme.colors.lightGray} !important;
    cursor: not-allowed !important;
    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGray} !important;
      border: 1px solid ${({ theme }) => theme.colors.lightGray} !important;
    }
  }
  /*-------------------------------
    Themed Properties
  -------------------------------*/
  border: 1px solid ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.darkGray};
  background: ${({ theme }) => `${theme.colors.blue}40`};
  & svg {
    max-height: 20px;
    max-width: 20px;
    ${({ size }) => size === 'tiny' && 'max-height: 8px !important; max-width: 8px !important;'}
    ${({ size }) => size === 'small' && 'max-height: 10px !important; max-width: 10px !important;'} 
    ${({ size }) => size === 'medium' && 'max-height: 15px !important; max-width: 15px !important;'}
  }
  & svg > path {
    stroke: ${({ theme }) => theme.colors.pureWhite};
    ${({ theme, variant }) => variant === 'outlined' && `stroke: ${theme.colors.darkGray} ;`}
    ${({ theme, color }) => color === 'default' && `stroke: ${theme.colors.darkGray} ;`}
    ${({ theme, color }) => color === 'primary' && `stroke: ${theme.colors.white} ;`}
    ${({ theme, color }) => color === 'secondary' && `stroke: ${theme.colors.white} ;`}
    ${({ theme, color }) => color === 'secondary-default' && `stroke: ${theme.colors.red} ;`}
    ${({ theme, color }) => color === 'tertiary' && `stroke: ${theme.colors.blue} ;`}
  }
  & svg > line {
    stroke: ${({ theme }) => theme.colors.pureWhite};
    ${({ theme, variant }) => variant === 'outlined' && `stroke: ${theme.colors.darkGray} ;`}
    ${({ theme, color }) => color === 'default' && `stroke: ${theme.colors.darkGray} ;`}
    ${({ theme, color }) => color === 'primary' && `stroke: ${theme.colors.white} ;`}
    ${({ theme, color }) => color === 'secondary' && `stroke: ${theme.colors.white} ;`}
    ${({ theme, color }) => color === 'secondary-default' && `stroke: ${theme.colors.red} ;`}
    ${({ theme, color }) => color === 'tertiary' && `stroke: ${theme.colors.blue} ;`}
  }

  /*-------------------------------
    Variant Colors
  -------------------------------*/
  ${({ theme, variant }) => variant === 'outlined' && `border: 1px solid ${theme.colors.gray};`}
  ${({ theme, variant }) => variant === 'outlined' && `color: ${theme.colors.darkGray};`}
  ${({ variant }) => variant === 'outlined' && 'background: transparent;'}

  ${({ theme, color }) => color === 'default' && `background: ${theme.colors.blue}40;`}
  ${({ theme, color }) => color === 'default' && `color: ${theme.colors.darkGray};`}
  ${({ theme, color }) => color === 'default' && `border: 1px solid ${theme.colors.blue};`}
    
  ${({ theme, color }) => color === 'primary' && `background: ${theme.colors.darkGray};`}
  ${({ theme, color }) => color === 'primary' && `border: 1px solid ${theme.colors.darkGray};`}
  ${({ theme, color }) => color === 'primary' && `color: ${theme.colors.white};`}

  ${({ theme, color }) => color === 'secondary' && `background: ${theme.colors.red};`}
  ${({ theme, color }) => color === 'secondary' && `color: ${theme.colors.pureWhite};`}
  ${({ theme, color }) => color === 'secondary' && `border: 1px solid ${theme.colors.red};`}

  ${({ theme, color }) => color === 'secondary-default' && `background: ${theme.colors.white};`}
  ${({ theme, color }) => color === 'secondary-default' && `color: ${theme.colors.red};`}
  ${({ theme, color }) => color === 'secondary-default' && `border: 1px solid ${theme.colors.red};`}
    
  ${({ theme, color }) => color === 'tertiary' && `background: ${theme.colors.lightGray};`}
  ${({ theme, color }) => color === 'tertiary' && `color: ${theme.colors.darkGray};`}
  ${({ theme, color }) => color === 'tertiary' && `border: 1px solid ${theme.colors.lightGray};`}
  /*-------------------------------
    Variant Sizes
  -------------------------------*/
  ${({ size }) => size === 'tiny' && 'padding: 0 5px !important; border-radius: 3px; font-size: 8px !important;'} 
  ${({ size }) => size === 'small' && 'font-size: 13px !important;'} 
  ${({ size }) => size === 'medium' && 'font-size: 16px !important;'} 

  /*-------------------------------
    Conditional Properties
  -------------------------------*/
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
`
