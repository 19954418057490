import styled from 'styled-components'

// control
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  font-size: 15px;
  color: ${({ theme, isChecked }) => (isChecked ? theme.colors.blue : theme.colors.darkGray)};

  ${({ horizontal }) => (horizontal ? 'flex-direction: row; gap: 15px !important;' : 'flex-direction: column;')};
`
export const Title = styled.h2`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const RadioInput = styled.input`
  display: none;

  &:checked ~ label > div > span {
    background: ${({ theme }) => theme.colors.blue};
  }
`
export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const RadioCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.darkGray};
`
export const RadioDot = styled.span`
  display: flex;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.darkGray};
`
