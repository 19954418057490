import styled from 'styled-components'

export const CreateEntityDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 400px;

  // Themed Properties
  background-color: ${({ theme }) => theme.colors.white};

  & button {
    width: 100%;
  }
`

export const DrawerNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 16px 20px;
`
export const DrawerHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  padding: 0 20px 16px 20px;
`
export const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  & button {
    display: flex;
    align-items: center;
    gap: 10px;

    max-height: 20px;
    font-size: 15px;
    text-align: left;
    border: none !important;
    padding: 0 !important;
    background-color: transparent !important;
    color: ${({ theme }) => theme.colors.darkGray} !important;
    & svg {
      max-height: none !important;
      max-width: none !important;
    }
  }
  & button:hover {
    color: ${({ theme }) => theme.colors.blue} !important;
    & svg > path {
      stroke: ${({ theme }) => theme.colors.blue} !important;
    }
  }
`

export const DrawerContent = styled.div`
  flex: 1;

  overflow-y: auto;

  width: 100%;
  padding: 20px;
`

export const DrawerFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding: 20px;

  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};

  & button {
    width: ${({ formPage }) => (formPage === 5 ? '100%' : 'auto')};
  }
`

export const DrawerCloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  // TODO: Use Theme Functions
  &:hover {
    opacity: 0.7;
  }
  & svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
`

export const DrawerTitle = styled.div`
  margin: 0;

  font-size: 20px;
  font-weight: 500;
  text-align: center;

  // TODO: Use Theme Color
  color: ${({ theme }) => theme.colors.darkGray};
`
