/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import { ScanIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Wrapper,
  SearchBarWrapper,
  SearchInputWrapper,
  SearchBarContainer,
  Title,
  ChoiceWrapper,
  ProviderChoiceWrapper,
  ProviderChoiceContainer,
  RowWrapper,
  Subtitle,
} from './AddressSearchModal.elements'

// Views
import { Modal, TextField, Button, Radio, LoaderCircle } from 'views/components'

// Hooks
import { useCreateOrUpdateAddress } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AddressSearchModal(props) {
  // Destructure
  const { ui, actions, address } = props

  // Store State
  const { isAddressSearchModalOpen } = ui
  const { addressSearchDetails, addressChains } = address

  // Store Actions
  const { setIsAddressSearchModalOpen, setAddressSearchDetails, showAlert, setScannedAddress } = actions

  // local states
  const [addressInput, setAddressInput] = useState('')
  const [chain, setChain] = useState('')
  const [chainSymbol, setChainSymbol] = useState('')
  const [selectedProvider, setSelectedProvider] = useState()
  const [allChains, setAllChains] = useState()
  const [availableChains, setAvailableChains] = useState()
  const [isSelfHosted, setIsSelfHosted] = useState(false)
  const [scanForRisk, setScanForRisk] = useState()
  const [isForcedRender, forceRender] = useState()
  const isModal = !!addressSearchDetails?.link_id

  // Refs
  const textInputRef = useRef()

  // hooks
  const {
    createOrUpdateAddress,
    createOrUpdateAddressData,
    createOrUpdateAddressError,
    isCreateOrUpdateAddressLoading,
  } = useCreateOrUpdateAddress()
  const [userCredentials] = useLocalStorage('userCredentials', {})

  // Functions
  const toggleModal = () => {
    setAddressSearchDetails({
      link_id: null,
      link_type: null,
    })
    setIsAddressSearchModalOpen()
  }
  function handleSubmit() {
    const firstChild = Object.keys(availableChains?.[selectedProvider])[0]
    const firstChain = availableChains?.[selectedProvider]?.[firstChild]
    const provider_id = firstChain?.provider_id
    const requestData = {
      address: addressInput,
      chain,
      name_first: userCredentials.name_first,
      name_last: userCredentials.name_last,
      provider_id,
      is_self_hosted: isSelfHosted,
    }
    if (scanForRisk === false) {
      requestData.scan_for_risk = scanForRisk
    }
    if (addressSearchDetails?.link_id && addressSearchDetails?.link_type) {
      requestData.link_id = addressSearchDetails?.link_id
      requestData.link_type = addressSearchDetails?.link_type
    }
    createOrUpdateAddress(requestData)
    setAddressInput('')
    setChain('')
    setChainSymbol()
    setSelectedProvider()
    setIsSelfHosted()
    setScanForRisk()
  }
  function handleRegister() {
    const requestData = {
      address: addressInput,
      chain,
      name_first: userCredentials.name_first,
      name_last: userCredentials.name_last,
      is_self_hosted: isSelfHosted,
      scan_for_risk: false,
    }
    if (addressSearchDetails?.link_id && addressSearchDetails?.link_type) {
      requestData.link_id = addressSearchDetails?.link_id
      requestData.link_type = addressSearchDetails?.link_type
    }
    createOrUpdateAddress(requestData)
    setAddressInput('')
    setChain('')
    setChainSymbol()
    setSelectedProvider()
    setIsSelfHosted()
    setScanForRisk()
  }
  function handleAddressChange(inputValue) {
    setAddressInput(inputValue)
  }
  function handleAvailableChains() {
    const providers = { FDBDM: { ...addressChains?.financial?.FDBDM }, ...addressChains?.risk }
    const allChains = {}
    let literallyAllChains = {}
    Object.keys(providers).forEach((providerCode) => {
      Object.keys(providers[providerCode]).forEach((chainCode) => {
        if (providers?.FDBDM?.[chainCode] && providerCode !== 'FDBDM') {
          allChains[providerCode] = { ...allChains[providerCode], [chainCode]: providers[providerCode][chainCode] }
          literallyAllChains = { ...literallyAllChains, [chainCode]: providers[providerCode][chainCode] }
        }
      })
    })
    setAvailableChains(allChains)
    setAllChains(literallyAllChains)
  }

  // UseEffects
  useEffect(() => {
    handleAvailableChains()
  }, [addressChains])
  useEffect(() => chain && selectedProvider && addressInput && handleSubmit(), [selectedProvider])
  useEffect(() => {
    if (!isAddressSearchModalOpen) {
      setAddressInput('')
      setChain()
      setChainSymbol()
      setIsSelfHosted()
      setScanForRisk()
    }
    if (isAddressSearchModalOpen) {
      forceRender(true)
    }
  }, [isAddressSearchModalOpen])
  useEffect(() => {
    // This is to auto focus to input once modal is opened
    if (isForcedRender) {
      if (textInputRef.current) {
        const inputElement = textInputRef.current?.querySelector('input')
        inputElement?.focus()
      }
      forceRender(null)
    }
  }, [isForcedRender])
  useEffect(() => {
    if (createOrUpdateAddressData) {
      setScannedAddress(createOrUpdateAddressData)
      setIsAddressSearchModalOpen()
    }
  }, [createOrUpdateAddressData])
  useEffect(() => {
    if (createOrUpdateAddressError) {
      setIsSelfHosted()
      setScanForRisk()
    }
    if (createOrUpdateAddressError?.response?.data?.data) {
      showAlert({ type: 'error', message: createOrUpdateAddressError?.response?.data?.data })
    } else if (createOrUpdateAddressError) {
      showAlert({ type: 'error', message: 'An error occurred in screening address' })
    }
  }, [createOrUpdateAddressError])

  return (
    <Modal isOpen={isAddressSearchModalOpen} toggle={toggleModal} style={{ width: '100%', maxWidth: '900px' }}>
      <Wrapper>
        <SearchBarWrapper>
          {!isCreateOrUpdateAddressLoading && (
            <SearchBarContainer>
              <SearchInputWrapper ref={textInputRef}>
                <ScanIcon />
                <TextField
                  placeholder={isCreateOrUpdateAddressLoading ? 'loading...' : 'Screen a wallet address'}
                  value={addressInput}
                  disabled={isCreateOrUpdateAddressLoading}
                  onChange={(e) => handleAddressChange(e.currentTarget.value)}
                />
              </SearchInputWrapper>
              <ChoiceWrapper>
                {allChains && (
                  <ProviderChoiceWrapper>
                    <Title>Select Chain</Title>
                    <ProviderChoiceContainer>
                      {Object.keys(allChains)?.map((key) => (
                        <Button
                          onClick={() => {
                            setChainSymbol(key)
                            setChain(allChains[key]?.chain_name)
                          }}
                          variant={chainSymbol === key ? 'primary' : 'outlined'}
                          key={key}
                          disabled={!addressInput?.trim()}
                        >
                          {allChains[key]?.chain_name}
                        </Button>
                      ))}
                    </ProviderChoiceContainer>
                  </ProviderChoiceWrapper>
                )}
                {allChains && chain && addressInput && (
                  <RowWrapper>
                    {isModal && (
                      <ProviderChoiceWrapper>
                        <Title>Scan For Risk?</Title>
                        <ProviderChoiceContainer>
                          <Radio
                            onChange={(v) => {
                              if (v === true) setScanForRisk(v)
                              else if (v === false) {
                                setScanForRisk(false)
                                handleRegister()
                              }
                            }}
                            value={scanForRisk}
                            horizontal
                            options={[
                              { label: 'Yes', value: true },
                              { label: 'No', value: false },
                            ]}
                          />
                        </ProviderChoiceContainer>
                      </ProviderChoiceWrapper>
                    )}
                    <ProviderChoiceWrapper>
                      <Title>Is Wallet Self-Hosted?</Title>
                      <ProviderChoiceContainer>
                        <Radio
                          onChange={(v) => setIsSelfHosted(v)}
                          value={isSelfHosted}
                          horizontal
                          options={[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                          ]}
                        />
                      </ProviderChoiceContainer>
                    </ProviderChoiceWrapper>
                  </RowWrapper>
                )}
                {allChains && chain && addressInput && (!isModal || scanForRisk) && (
                  <ProviderChoiceWrapper>
                    <Title>Select Provider</Title>
                    <ProviderChoiceContainer>
                      {Object.keys(availableChains)?.map((key) => {
                        if (availableChains?.[key]?.[chainSymbol]) {
                          return (
                            <Button
                              onClick={() => {
                                setSelectedProvider(key)
                              }}
                              variant={selectedProvider === key ? 'primary' : 'outlined'}
                              key={key}
                            >
                              {key}
                            </Button>
                          )
                        }
                      })}
                    </ProviderChoiceContainer>
                  </ProviderChoiceWrapper>
                )}
              </ChoiceWrapper>
            </SearchBarContainer>
          )}
          {isCreateOrUpdateAddressLoading && (
            <SearchBarContainer>
              <LoaderCircle />
              <Subtitle style={{ marginTop: '20px' }}>
                This process may take over 60 seconds, depending on the volume of data.
              </Subtitle>
            </SearchBarContainer>
          )}
        </SearchBarWrapper>
      </Wrapper>
    </Modal>
  )
}

// Default Props
AddressSearchModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
AddressSearchModal.propTypes = {
  ui: PropTypes.shape({
    isAddressSearchModalOpen: PropTypes.bool,
    infoModalDetails: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      loading: PropTypes.bool,
    }),
  }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setIsAddressSearchModalOpen: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setAddressSearchDetails: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressSearchModal)
