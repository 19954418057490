/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Assets
import { HelpIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Constants
import {
  // STANDARD_DATETIME_FORMAT,
  STANDARD_DATE_FORMAT,
} from 'common/constants/dateFormat'

// Styled Elements
import {
  Text,
  ColoredText,
  Title,
  ColWrapper,
  ColContainer,
  RowContainer,
  GridRowContainer,
  TextRowContainer,
  GroupRowContainer,
  BlockContainer,
  InfoTitle,
  RiskContainer,
  RowWrapper,
  FlowWrapper,
  BlurWrapper,
} from '../../AddressesTableItemDetails.elements'

// Components
import { CircularProgressBar, TextLoading, SkeletonText } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function RiskDataSection(props) {
  // Destructure
  const { actions, riskData, isLoading, currentIpUsed } = props

  // Store Actions
  const { toggleInfoModal, setInfoModalDetails } = actions

  // Functions
  const formatVaspNames = (vaspName) => {
    let nameArray = vaspName.split(',').join(', ').split('_').join(' ')
    if (nameArray[nameArray.length - 2] === ',') {
      nameArray = nameArray.substring(0, nameArray.length - 2)
    }
    return nameArray
  }
  const handleToggleInfo = (title, content) => {
    setInfoModalDetails({ title, content })
    toggleInfoModal(true)
  }

  return (
    <FlowWrapper>
      <RowWrapper
        style={{
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '20px 10px 10px 10px',
          cursor: 'pointer',
          width: 'fit-content',
        }}
        onClick={() => {
          handleToggleInfo(
            'Wallet Address Risk',
            // eslint-disable-next-line max-len
            'Evaluates the potential risk of a blockchain address based on transaction behavior, links to illicit activities, and regulatory compliance.'
          )
        }}
      >
        <Title style={{ fontWeight: '600', fontSize: '15px', cursor: 'pointer', margin: 0 }}>Wallet Address Risk</Title>
        <HelpIcon size={16} />
      </RowWrapper>
      <BlockContainer
        style={{ padding: '10px', borderWidth: 2, display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        {isLoading && (
          <BlockContainer style={{ padding: '10px', borderWidth: 1 }}>
            <GridRowContainer style={{ gridTemplateColumns: '320px 1fr 0.79fr' }}>
              <RowContainer style={{ maxWidth: '320px', alignItems: 'center' }}>
                <BlockContainer style={{ display: 'flex', padding: 0 }}>
                  <ColWrapper>
                    <RiskContainer>
                      <CircularProgressBar id="LoadingRisk123" size={120} progress={0}>
                        <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                          <TextLoading height={24} width={40} />
                          <TextLoading height={14} width={60} />
                        </ColContainer>
                      </CircularProgressBar>
                    </RiskContainer>
                    <GroupRowContainer style={{ padding: '20px' }}>
                      <ColContainer style={{ gap: 10 }}>
                        <InfoTitle>
                          Risk Score
                          <HelpIcon />
                        </InfoTitle>
                        <TextLoading height={14} width={80} borderRadius={4} />
                      </ColContainer>
                      <ColContainer style={{ alignItems: 'center', gap: 10 }}>
                        <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                        <TextLoading height={14} width={80} borderRadius={4} />
                      </ColContainer>
                      <ColContainer style={{ alignItems: 'flex-end', gap: 10 }}>
                        <InfoTitle>
                          Source
                          <HelpIcon />
                        </InfoTitle>
                        <TextLoading height={14} width={80} borderRadius={4} />
                      </ColContainer>
                    </GroupRowContainer>
                  </ColWrapper>
                </BlockContainer>
              </RowContainer>

              <RowContainer>
                <BlockContainer>
                  <ColContainer>
                    <Title>Risk Score Descriptors: </Title>
                    <RowContainer style={{ gap: 5, maxHeight: '150px', overflowY: 'auto', paddingRight: '20px' }}>
                      <TextLoading width={100} />
                      <TextLoading width={70} />
                      <TextLoading width={170} />
                      <TextLoading width={140} />
                      <TextLoading width={130} />
                      <TextLoading width={80} />
                    </RowContainer>
                  </ColContainer>
                </BlockContainer>
              </RowContainer>
              <RowContainer style={{ maxWidth: '443px' }}>
                <BlockContainer>
                  <ColContainer>
                    <Title>Controlling Entity: </Title>
                    <TextRowContainer style={{ padding: '0 0 10px 0' }}>
                      <Text>Name: </Text>
                      <TextLoading width={180} />
                    </TextRowContainer>
                    <TextRowContainer>
                      <Text>Website: </Text>
                      <TextLoading width={180} />
                    </TextRowContainer>
                    <TextRowContainer>
                      <Text>Type: </Text>
                      <TextLoading width={180} />
                    </TextRowContainer>
                  </ColContainer>
                </BlockContainer>
              </RowContainer>
            </GridRowContainer>
          </BlockContainer>
        )}
        {/* -------RISK DATA------- */}
        {riskData &&
          riskData.map((data, i) => {
            if (data?.provider_name !== currentIpUsed) {
              return (
                <BlockContainer style={{ padding: '10px', borderWidth: 1 }} key={data.id}>
                  <GridRowContainer style={{ gridTemplateColumns: '320px 1fr 0.79fr' }}>
                    <RowContainer style={{ maxWidth: '320px', alignItems: 'center' }}>
                      <BlockContainer style={{ display: 'flex', padding: 0 }}>
                        <ColWrapper>
                          <RiskContainer>
                            <CircularProgressBar
                              key={data.id}
                              id={`${data.id}-${i}`}
                              size={120}
                              progress={!Number.isNaN(data.risk_ratio) && data.risk_ratio}
                            >
                              {!Number.isNaN(data.risk_ratio) && (
                                <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                                  <Text style={{ fontSize: '18px', fontWeight: 600 }}>{`${data.risk_ratio}%`}</Text>
                                  <Text style={{ fontSize: '12px', fontWeight: 400 }} id="rsSubTitle">
                                    Risk
                                  </Text>
                                </ColContainer>
                              )}
                            </CircularProgressBar>
                          </RiskContainer>
                          <GroupRowContainer style={{ padding: '20px' }}>
                            <ColContainer>
                              <InfoTitle
                                onClick={() => handleToggleInfo('Risk Score Information', data.risk_score_desc)}
                              >
                                Risk Score
                                <HelpIcon />
                              </InfoTitle>
                              <Text style={{ border: 'none', fontWeight: 600, fontSize: 15 }}>
                                {`${!Number.isNaN(data?.risk_score) ? data?.risk_score : ''} / ${
                                  !Number.isNaN(data?.max_score) ? data?.max_score : ''
                                }`}
                              </Text>
                            </ColContainer>
                            <ColContainer style={{ alignItems: 'center' }}>
                              <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                              <Text style={{ border: 'none', fontWeight: 600, fontSize: 15 }}>
                                {data.request_time && moment(data.request_time).format(STANDARD_DATE_FORMAT)}
                              </Text>
                            </ColContainer>
                            <ColContainer style={{ alignItems: 'flex-end' }}>
                              <InfoTitle
                                onClick={() =>
                                  handleToggleInfo('Risk Score Source Information', data.risk_provider_desc)
                                }
                              >
                                Source
                                <HelpIcon />
                              </InfoTitle>
                              <Text style={{ border: 'none', fontWeight: 600, fontSize: 15 }}>
                                {data.provider_name && data.provider_name}
                              </Text>
                            </ColContainer>
                          </GroupRowContainer>
                        </ColWrapper>
                      </BlockContainer>
                    </RowContainer>

                    <RowContainer>
                      <BlockContainer>
                        <ColContainer>
                          <Title>Risk Score Descriptors: </Title>
                          <RowContainer style={{ gap: 5, maxHeight: '150px', overflowY: 'auto', paddingRight: '20px' }}>
                            {data.risk_descriptors?.length > 0 &&
                              data.risk_descriptors.map((descriptor, i) => (
                                <ColoredText key={i}>{descriptor}</ColoredText>
                              ))}
                          </RowContainer>
                        </ColContainer>
                      </BlockContainer>
                    </RowContainer>

                    <RowContainer style={{ maxWidth: '443px' }}>
                      <BlockContainer>
                        <ColContainer>
                          <Title>Controlling Entity: </Title>
                          <TextRowContainer style={{ padding: '0 0 10px 0' }}>
                            <Text>Name: </Text>
                            <Text style={{ textAlign: 'right', textTransform: 'capitalize' }}>
                              {data.vasp_name_business ? formatVaspNames(data.vasp_name_business) : '-'}
                            </Text>
                          </TextRowContainer>
                          <TextRowContainer>
                            <Text>Website: </Text>
                            <Text style={{ textAlign: 'right', textTransform: 'lowercase' }}>
                              {data.vasp_website ? formatVaspNames(data.vasp_website) : '-'}
                            </Text>
                          </TextRowContainer>
                          <TextRowContainer>
                            <Text>Type: </Text>
                            <Text style={{ textAlign: 'right', textTransform: 'capitalize' }}>
                              {data.vasp_category ? formatVaspNames(data.vasp_category) : '-'}
                            </Text>
                          </TextRowContainer>
                        </ColContainer>
                      </BlockContainer>
                    </RowContainer>
                  </GridRowContainer>
                </BlockContainer>
              )
            }
          })}

        {/* -------EMPTY RISK DATA------- */}
        {!riskData && !isLoading && (
          <BlockContainer style={{ padding: '10px', borderWidth: 1, position: 'relative' }}>
            <BlurWrapper>
              <Title>No Risk Data Available</Title>
            </BlurWrapper>
            <GridRowContainer style={{ gridTemplateColumns: '320px 1fr 0.79fr' }}>
              <RowContainer style={{ maxWidth: '320px', alignItems: 'center' }}>
                <BlockContainer style={{ display: 'flex', padding: 0 }}>
                  <ColWrapper>
                    <RiskContainer>
                      <CircularProgressBar id="LoadingRisk123" size={120} progress={0}>
                        <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                          <SkeletonText height={24} width={40} />
                          <SkeletonText height={14} width={60} />
                        </ColContainer>
                      </CircularProgressBar>
                    </RiskContainer>
                    <GroupRowContainer style={{ padding: '20px' }}>
                      <ColContainer style={{ gap: 10 }}>
                        <InfoTitle>
                          Risk Score
                          <HelpIcon />
                        </InfoTitle>
                        <SkeletonText height={14} width={80} borderRadius={4} />
                      </ColContainer>
                      <ColContainer style={{ alignItems: 'center', gap: 10 }}>
                        <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                        <SkeletonText height={14} width={80} borderRadius={4} />
                      </ColContainer>
                      <ColContainer style={{ alignItems: 'flex-end', gap: 10 }}>
                        <InfoTitle>
                          Source
                          <HelpIcon />
                        </InfoTitle>
                        <SkeletonText height={14} width={80} borderRadius={4} />
                      </ColContainer>
                    </GroupRowContainer>
                  </ColWrapper>
                </BlockContainer>
              </RowContainer>

              <RowContainer>
                <BlockContainer>
                  <ColContainer>
                    <Title>Risk Score Descriptors: </Title>
                    <RowContainer style={{ gap: 5, maxHeight: '150px', overflowY: 'auto', paddingRight: '20px' }}>
                      <SkeletonText width={100} />
                      <SkeletonText width={70} />
                      <SkeletonText width={170} />
                      <SkeletonText width={140} />
                      <SkeletonText width={130} />
                      <SkeletonText width={80} />
                    </RowContainer>
                  </ColContainer>
                </BlockContainer>
              </RowContainer>
              <RowContainer style={{ maxWidth: '443px' }}>
                <BlockContainer>
                  <ColContainer>
                    <Title>Controlling Entity: </Title>
                    <TextRowContainer style={{ padding: '0 0 10px 0' }}>
                      <Text>Name: </Text>
                      <SkeletonText width={180} />
                    </TextRowContainer>
                    <TextRowContainer>
                      <Text>Website: </Text>
                      <SkeletonText width={180} />
                    </TextRowContainer>
                    <TextRowContainer>
                      <Text>Type: </Text>
                      <SkeletonText width={180} />
                    </TextRowContainer>
                  </ColContainer>
                </BlockContainer>
              </RowContainer>
            </GridRowContainer>
          </BlockContainer>
        )}
        {/* DO NOT REMOVE THIS LAST DIV */}
        <div />
      </BlockContainer>
    </FlowWrapper>
  )
}

// Default Props
RiskDataSection.defaultProps = {
  riskData: null,
  isLoading: false,
  currentIpUsed: '',
}

// Proptypes Validation
RiskDataSection.propTypes = {
  riskData: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  currentIpUsed: PropTypes.string,
  actions: PropTypes.shape({
    toggleAddressHistoryModal: PropTypes.func,
    toggleAddressReportModal: PropTypes.func,
    setAddressReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setIsLinkAddressDrawerOpen: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
    setAddressHistoryFilters: PropTypes.func,
    setAddressRemove: PropTypes.func,
    showAlert: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskDataSection)
