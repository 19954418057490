/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
// import { saveAs } from 'file-saver'

// Store
import { actions } from 'core/store'

// Hooks
// import { useGetReport } from 'core/hooks/api'

// Constants
import {
  STANDARD_DATETIME_FORMAT,
  // STANDARD_DATE_FORMAT
} from 'common/constants/dateFormat'

// Styled Elements
import {
  TableButtonWrapper,
  TableRowWrapper,
  TableRowContainer,
  TableText,
  StatusText,
} from './ReportsTransactionTableItem.elements'

// Views
import { TableItem, ButtonSplitSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function ReportsTransactionTableItem(props) {
  // Destructure
  const { data } = props
  // const { showAlert } = actions
  const { created, sections, status_name } = data

  // Hooks
  // const { getReport, getReportData, getReportError, isGetReportLoading } = useGetReport()

  // States
  const [format, setFormat] = useState('PDF')

  // function formatValue(number) {
  //   if (number < 1_000_000) {
  //     return numberWithCommas(Math.round(number * 100) / 100)
  //   }
  //   return compactNumberFormatter.format(number)
  // }

  // Constants
  const FORMAT_OPTIONS = [
    // {
    //   value: 'CSV',
    //   onClick: () => setFormat('CSV'),
    // },
    {
      value: 'PDF',
      onClick: () => setFormat('PDF'),
    },
    // {
    //   value: 'JSON',
    //   onClick: () => setFormat('JSON'),
    // },
  ]
  // Functions
  const handleDownload = async () => {
    // getReport({ format: format.toLowerCase(), report_id: id })
    if (format.toLowerCase() === 'pdf') {
      let report_url = data?.report_url
      if (report_url.split('https')?.length < 2) {
        report_url = report_url.split('http').join('https')
      }
      const response = await fetch(report_url)
      const blob = await response.blob()
      const report = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = report
      a.download = `Ospree-Report${created && ` (${moment(created).format(STANDARD_DATETIME_FORMAT)})`}.pdf`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(report)
    }
  }
  // const downloadFile = (data, inputFormat) => {
  //   let blob
  //   // if (inputFormat === 'CSV') {
  //   //   blob = new Blob([data], { type: `text/${inputFormat.toLowerCase()}; charset=utf-8` })
  //   // }
  //   // if (inputFormat === 'JSON') {
  //   //   blob = new Blob([JSON.stringify(data)], { type: 'text/plain;charset=utf-8' })
  //   // }
  //   if (inputFormat === 'PDF') {
  //     blob = new Blob([JSON.stringify(data)], { type: 'text/plain;charset=utf-8' })
  //   }
  //   saveAs(blob, `Ospree_Report.${format.toLowerCase()}`)
  // }

  // UseEffect
  // useEffect(() => getReportData && downloadFile(getReportData, format), [getReportData])
  // useEffect(() => {
  //   // TODO ADD PROPER ERROR MSG SENT FROM SERVER
  //   if (getReportError) showAlert({ type: 'error', message: 'An error occurred in fetching reports' })
  // }, [getReportError])
  return (
    <TableItem>
      {/* {isGetReportLoading ? (
        <TableButtonWrapper style={{ display: 'flex', justifyContent: 'center' }}>
          <TableText>Downloading...</TableText>
        </TableButtonWrapper>
      ) : ( */}
      <TableButtonWrapper>
        <TableRowWrapper style={{ gridTemplateColumns: '1fr 0.7fr 2fr' }}>
          <TableRowContainer>
            <TableText>{created && moment(created).format(STANDARD_DATETIME_FORMAT)}</TableText>
          </TableRowContainer>
          <TableRowContainer>
            <TableText>Transaction</TableText>
          </TableRowContainer>
          <TableRowContainer>
            <TableText>{sections && sections.split(',').join(', ').split('_').join(' ')}</TableText>
          </TableRowContainer>
        </TableRowWrapper>

        <TableRowWrapper style={{ gridTemplateColumns: '150px 1fr' }}>
          <TableRowContainer>
            <StatusText status={status_name}>{status_name}</StatusText>
          </TableRowContainer>

          <TableRowContainer>
            {status_name === 'Completed' ? (
              <ButtonSplitSelect onClick={handleDownload} options={FORMAT_OPTIONS}>
                {`Download (${format})`}
              </ButtonSplitSelect>
            ) : (
              <TableText>Available in a few minutes</TableText>
            )}
          </TableRowContainer>
        </TableRowWrapper>
      </TableButtonWrapper>
      {/* )} */}
    </TableItem>
  )
}

// Default Props
ReportsTransactionTableItem.defaultProps = {
  address: {},
  actions: {},
  data: {
    id: '',
    created: '',
    report_type: '',
    sections: [],
    status_name: '',
  },
}

// Proptypes Validation
ReportsTransactionTableItem.propTypes = {
  address: PropTypes.shape({
    addressLinkDetails: PropTypes.shape({
      address_id: PropTypes.string,
    }),
    addressLinkData: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
  }),
  data: PropTypes.shape({
    id: PropTypes.string,
    report_url: PropTypes.string,
    created: PropTypes.string,
    report_type: PropTypes.string,
    sections: PropTypes.string,
    status_name: PropTypes.string,
  }),
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportsTransactionTableItem)
