import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Assets
import CloseIcon from 'assets/images/close'

// Store
import { actions } from 'core/store'

// Hooks
import { useCreateUser, useAddVaspUser } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import { DrawerWrapper, DrawerCloseIcon, FormWrapper, FormTitle, ErrMsg } from './UserDrawer.elements'

// Views
import { Drawer, Button } from 'views/components'

// Layouts
import { UserForm } from 'views/layouts'
import { useRef } from 'react'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UserDrawer(props) {
  // Destructure
  const { ui, actions } = props
  const { vasp_id } = useParams()

  //  Store State
  const { isUserDrawerOpen } = ui

  // Store Actions
  const { setIsPageTableUpdated, showAlert, setIsUserDrawerOpen } = actions

  //  Refs
  const formRef = useRef()

  // Functions
  const handleCloseDrawer = () => {
    setIsUserDrawerOpen()
  }

  // Internal State
  const [errorMsg, setErrorMsg] = useState()

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const { createUser, createUserData, createUserError, isCreateUserLoading } = useCreateUser()
  const { addVaspUser, addVaspUserData, addVaspUserError, isAddVaspUserLoading } = useAddVaspUser() // Admin Endpoint

  // Functions
  const triggerSubmit = () => {
    formRef.current.submitForm()
  }
  const handleSubmit = (values) => {
    if (userCredentials?.role_id === 0) {
      addVaspUser({ ...values, vasp_id })
    } else if (userCredentials?.role_id === 1) {
      createUser(values)
    }
  }
  const handleSuccess = () => {
    setIsPageTableUpdated(true)
    showAlert({ type: 'success', message: 'Successfully created user' })
    setIsUserDrawerOpen()
  }
  const handleErr = () => {
    if (createUserError?.response?.data?.context && !createUserError?.response?.data?.context?.error) {
      setErrorMsg(createUserError?.response?.data?.context)
      return
    }
    showAlert({ type: 'error', message: 'Error: Could not create user' })
  }

  // UseEffects
  useEffect(() => {
    if (isCreateUserLoading) return
    if (createUserError) {
      handleErr()
      return
    }
    if (createUserData) handleSuccess()
  }, [createUserData, createUserError])
  useEffect(() => {
    if (isAddVaspUserLoading) return
    if (addVaspUserError) {
      handleErr()
      return
    }
    if (addVaspUserData) handleSuccess()
  }, [addVaspUserData, addVaspUserError])
  return (
    <Drawer open={isUserDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <DrawerWrapper>
        <DrawerCloseIcon onClick={handleCloseDrawer}>
          <CloseIcon />
        </DrawerCloseIcon>
        <FormWrapper>
          <FormTitle>Create User</FormTitle>
          {errorMsg && <ErrMsg>{errorMsg}</ErrMsg>}
          <UserForm formRef={formRef} handleSubmit={handleSubmit} />
          {isCreateUserLoading ? (
            <Button disabled>Creating user...</Button>
          ) : (
            <Button type="button" onClick={triggerSubmit}>
              Submit
            </Button>
          )}
        </FormWrapper>
      </DrawerWrapper>
    </Drawer>
  )
}

// Default Props
UserDrawer.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
UserDrawer.propTypes = {
  ui: PropTypes.shape({ isUserDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({
    setIsUserDrawerOpen: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDrawer)
