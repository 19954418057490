/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import moment from 'moment'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Assets
import DownloadIcon from 'assets/images/download'
import CloseIcon from 'assets/images/close'

// Constants
import {
  ACCOUNT_DETAILS_PAGES,
  ACCOUNT_DETAILS_OVERVIEW_PAGES,
  ACCOUNT_DETAILS_ACTIVITY_PAGES,
} from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchAccount, useUpdateAccountEntity, useUpdateAccountIndividual } from 'core/hooks/api'

// Styled Elements
import {
  Wrapper,
  Container,
  Title,
  Subtitle,
  EditBtnWrapper,
  FooterBtnWrapper,
  Spacer,
} from './AccountDetails.elements'

// Views
import { Button } from 'views/components'

import {
  AccountIndividualDetailsForm,
  AccountEntityDetailsForm,
  RiskDetailsForm,
  AccountDetailsSubHeader,
  AccountAddressesTable,
  AccountSummaryFilters,
  AccountTransfersTable,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AccountDetails(props) {
  // Destructure
  const { actions } = props
  const { account_id } = useParams()

  // store actions
  const { setShowHeaderLoader, showAlert, setAddressFilters } = actions

  // refs
  const accountFormRef = useRef(null)
  const riskFormRef = useRef(null)

  // hooks
  const { getAccount, accountData, isAccountLoading } = useFetchAccount()
  const {
    accountEntityUpdateData,
    updateAccountEntity,
    accountEntityUpdateError,
    isAccountEntityUpdateLoading,
    isAccountEntityUpdateSuccess,
  } = useUpdateAccountEntity()
  const {
    accountIndividualUpdateData,
    updateAccountIndividual,
    accountIndividualUpdateError,
    isAccountIndividualUpdateLoading,
    isAccountIndividualUpdateSuccess,
  } = useUpdateAccountIndividual()

  // state
  const [accountDataState, setAccountDataState] = useState()
  const [viewMode, setViewMode] = useState(true)
  const [fakeLoading, setFakeLoading] = useState(false)
  const [accountDetailsPage, setAccountDetailsPage] = useState(0)
  const [overviewPage, setOverviewPage] = useState(0)
  const [activityPage, setActivityPage] = useState(0)
  const [activeTable, setActiveTable] = useState(0)

  // functions
  const fetchData = () => {
    getAccount({ account_id })
  }
  const setAccountAddressFitlers = () => {
    setAddressFilters({
      sort: '-last_modified',
      page: 1,
      size: 10,
      start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
      link_id: account_id,
      identified: undefined,
    })
  }
  const handleOnSubmit = () => {
    const accountPayload = accountFormRef.current.values
    const riskPayload = riskFormRef.current.values
    const newPayload = {
      ...accountPayload,
      account_risk_value: riskPayload.account_risk_value,
      recipient_risk_value: riskPayload.recipient_risk_value,
      pep_id: riskPayload.pep_id,
      sanction_id: riskPayload.sanction_id,
      adverse_media_id: riskPayload.adverse_media_id,
      comments: riskPayload.comments,
    }
    if (!accountPayload && !riskPayload) return
    if (accountDataState.account_type_id === 1) {
      updateAccountIndividual({ account_id, values: newPayload })
    }
    if (accountDataState.account_type_id === 2) {
      updateAccountEntity({ account_id, values: newPayload })
    }
  }
  const handleCancel = () => {
    setViewMode(true)
    setFakeLoading(true)
    if (accountEntityUpdateData) return setAccountDataState(accountEntityUpdateData)
    if (accountIndividualUpdateData) return setAccountDataState(accountIndividualUpdateData)
    return setAccountDataState(accountData)
  }
  const handleLoadingUpdateChange = () => {
    if (isAccountEntityUpdateLoading || isAccountIndividualUpdateLoading) {
      setShowHeaderLoader(true)
    }
    if (!isAccountEntityUpdateLoading && !isAccountIndividualUpdateLoading) {
      setShowHeaderLoader(false)
      if (accountDataState) {
        if (accountDataState?.account_type_id === 1) {
          if (isAccountIndividualUpdateSuccess) {
            // setAccountDataState(accountIndividualUpdateData)
            showAlert({ type: 'success', message: 'Successfully updated record' })
          } else if (!isAccountIndividualUpdateSuccess) {
            if (typeof accountIndividualUpdateError?.response?.data?.data === 'string') {
              showAlert({ type: 'error', message: accountIndividualUpdateError?.response?.data?.data })
              return
            }
            handleCancel()
            showAlert({ type: 'error', message: 'An error occurred in updating record' })
          }
        } else if (accountDataState?.account_type_id === 2) {
          if (isAccountEntityUpdateSuccess) {
            // setAccountDataState(accountEntityUpdateData)
            showAlert({ type: 'success', message: 'Successfully updated record' })
          } else if (!isAccountEntityUpdateSuccess) {
            if (typeof accountEntityUpdateError?.response?.data?.data === 'string') {
              showAlert({ type: 'error', message: accountEntityUpdateError?.response?.data?.data })
              return
            }
            handleCancel()
            showAlert({ type: 'error', message: 'An error occurred in updating record' })
          }
        }
        setViewMode(true)
      }
    }
  }
  const handleLoadingAccount = () => {
    if (isAccountLoading) setShowHeaderLoader(true)
    if (!isAccountLoading) setShowHeaderLoader(false)
  }

  // useEffect
  useEffect(() => {
    fetchData()
    setAccountAddressFitlers()
  }, [])
  useEffect(() => handleCancel(), [accountDetailsPage, overviewPage])
  useEffect(() => accountData && setAccountDataState(accountData), [accountData])
  useEffect(() => handleLoadingAccount(), [isAccountLoading])
  useEffect(() => fakeLoading && setFakeLoading(false), [fakeLoading])
  useEffect(() => {
    if (accountFormRef?.current && riskFormRef?.current) handleLoadingUpdateChange()
  }, [isAccountEntityUpdateLoading, isAccountIndividualUpdateLoading])

  return (
    <Wrapper>
      {!isAccountLoading && accountDataState && (
        <>
          <Title>ID: {accountDataState.reference_id}</Title>{' '}
          <Subtitle>{accountDataState.account_type_id === 1 ? 'INDIVIDUAL ACCOUNT' : 'BUSINESS ACCOUNT'}</Subtitle>
          <Spacer />
          <AccountDetailsSubHeader
            page={accountDetailsPage}
            setPage={setAccountDetailsPage}
            pages={ACCOUNT_DETAILS_PAGES}
          />
          {accountDetailsPage === 0 && (
            <>
              <Container>
                <AccountDetailsSubHeader
                  isButton
                  page={overviewPage}
                  setPage={setOverviewPage}
                  pages={ACCOUNT_DETAILS_OVERVIEW_PAGES}
                />
                {overviewPage === 0 && (
                  <>
                    <Spacer />
                    <AccountSummaryFilters
                      account_id={account_id}
                      account_data={accountDataState}
                      activeTable={activeTable}
                      setActiveTable={setActiveTable}
                    />
                  </>
                )}
                {overviewPage === 1 && (
                  <>
                    {viewMode === true && (
                      <EditBtnWrapper>
                        <Button color="primary" onClick={() => setViewMode(false)}>
                          Edit
                        </Button>
                      </EditBtnWrapper>
                    )}
                    {accountDataState.account_type_id === 1
                      ? !fakeLoading && (
                          <>
                            <AccountIndividualDetailsForm
                              values={accountDataState}
                              viewMode={viewMode}
                              formRef={accountFormRef}
                              onSubmit={handleOnSubmit}
                            />
                            <RiskDetailsForm
                              values={accountDataState}
                              viewMode={viewMode}
                              formRef={riskFormRef}
                              onSubmit={handleOnSubmit}
                            />
                          </>
                        )
                      : !fakeLoading && (
                          <>
                            <AccountEntityDetailsForm
                              values={accountDataState}
                              viewMode={viewMode}
                              formRef={accountFormRef}
                              onSubmit={handleOnSubmit}
                            />
                            <RiskDetailsForm
                              values={accountDataState}
                              viewMode={viewMode}
                              formRef={riskFormRef}
                              onSubmit={handleOnSubmit}
                            />
                          </>
                        )}
                    {viewMode === false && (
                      <FooterBtnWrapper>
                        <Button type="button" onClick={handleCancel} variant="outlined" startIcon={<CloseIcon />}>
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          onClick={() => accountFormRef.current.submitForm()}
                          startIcon={<DownloadIcon />}
                        >
                          Save Changes
                        </Button>
                      </FooterBtnWrapper>
                    )}
                  </>
                )}
              </Container>
              {overviewPage === 0 && activeTable === 0 && <AccountAddressesTable />}
              {overviewPage === 0 && (activeTable === 1 || activeTable === 2) && <AccountTransfersTable />}
            </>
          )}
          {accountDetailsPage === 1 && (
            <>
              <Spacer />
              <AccountDetailsSubHeader
                isButton
                page={activityPage}
                setPage={setActivityPage}
                pages={ACCOUNT_DETAILS_ACTIVITY_PAGES}
              />
              {/* {activityPage === 0 && (
                <ActivityWrapper>
                  <Row>
                    <RowHeader>Account Activity</RowHeader> <RowHeader>Value</RowHeader>
                  </Row>
                  <Row>
                    <Text>Pending Transfers</Text> <Text>{accountDataState.open_transfers}</Text>
                  </Row>
                  <Row>
                    <Text>Open Alerts</Text> <Text>{accountDataState.open_alerts}</Text>
                  </Row>
                </ActivityWrapper>
              )} */}
            </>
          )}
        </>
      )}
    </Wrapper>
  )
}

// Default Props
AccountDetails.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
AccountDetails.propTypes = {
  filters: PropTypes.shape({
    accountDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
    setAddressFilters: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
