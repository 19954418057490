/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react'

export default function ArrowLeftIcon(props) {
  return (
    <svg
      width={props?.size || '22'}
      height={props?.size || '22'}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 7L7 11L11 15" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15 11H7" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
