import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { bannedEmailDomains } from 'common/constants/bannedEmails'

// Store
import { actions } from 'core/store'

// Hooks
import { useSignupTrial } from 'core/hooks/api'

// Views
import { Form, FormTextField, Button } from 'views/components'

// Styled Elements
import {
  FormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormError,
  FormTitle,
  SuccessTitle,
  SuccessText,
  ButtonContainer,
} from './TrialSignupForm.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function TrialSignupForm(props) {
  // Destructure
  const { formRef, actions } = props

  // Store Actions
  const { showAlert } = actions

  // Hooks
  const { signupTrial, signupTrialData, signupTrialError, isSignupTrialLoading } = useSignupTrial()

  const [initialValues] = useState({
    email: '',
    password: '',
    name_first: '',
    name_last: '',
  })

  // Variables
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required('Email is required')
      .test('restricted-domain', 'Email domain is not allowed', (value) => {
        if (!value) return true
        const domain = value.split('@')[1]
        return !bannedEmailDomains.includes(domain)
      }),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirm_password: Yup.string().when('password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    }),
    name_first: Yup.string().required('First Name is required'),
    name_last: Yup.string().required('Last Name is required'),
  })
  // Functions
  const handleOnSubmit = (values) => {
    signupTrial(values)
  }

  // UseEffects
  useEffect(() => {
    if (signupTrialError) showAlert({ type: 'error', message: 'An error occurred in registering' })
  }, [signupTrialError])
  return (
    <FormWrapper>
      {!signupTrialData && !signupTrialError && <FormTitle>Sign up For a Trial Account</FormTitle>}
      {signupTrialData && !signupTrialError && (
        <>
          <SuccessTitle>A link was sent to your email!</SuccessTitle>
          <SuccessText>Verify your business email in order to access Ospree</SuccessText>
          <ButtonContainer href="/login">
            <Button fullWidth variant="outlined">
              Go back
            </Button>
          </ButtonContainer>
        </>
      )}

      {signupTrialError && <FormError>An error occurred in signing up.</FormError>}

      {!signupTrialData && !signupTrialError && initialValues && (
        <Form
          initialValues={initialValues}
          formRef={formRef}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField label="Business Email" type="email" name="email" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="First Name" name="name_first" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Last Name" name="name_last" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Password" type="password" name="password" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Confirm Password" type="password" name="confirm_password" />
            </FormInputGroupItem>
          </FormGroupWrapper>

          <Button fullWidth type="submit" disabled={isSignupTrialLoading}>
            {isSignupTrialLoading ? 'Submitting...' : 'Signup'}
          </Button>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
TrialSignupForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    external_id: '',
    email: '',
    name_first: '',
    name_last: '',
    incorporation_country: '',
    inc_date: '',
    primary_activity_id: 0,

    created: '',
    last_modified: '',

    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    timezone_code: '',
  },
}

// Proptypes Validation
TrialSignupForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    TIMEZONES: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.func,
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    external_id: PropTypes.string,
    email: PropTypes.string,
    name_first: PropTypes.string,
    name_last: PropTypes.string,
    incorporation_country: PropTypes.string,
    inc_date: PropTypes.string,
    primary_activity_id: PropTypes.number,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    timezone_code: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialSignupForm)
