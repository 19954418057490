/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
// import PlusWhiteIcon from 'assets/images/plus-white'
// import UsersIcon from 'assets/images/users'
// import BriefCaseIcon from 'assets/images/briefcase'

// Store
import { actions } from 'core/store'

// Hooks
// import { useLocalStorage } from 'core/hooks/storage'
import { useGetAddresses } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './AddressesTable.elements'

// Views
import { AccordionTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

import { AddressesTableItem, AddressTableFilter } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AddressesTable(props) {
  // Destructure
  const { filters, ui, actions, address } = props

  // Store States
  const { addressFilters } = filters
  const {
    scannedAddress,
    // defaultAddressProvider
  } = address
  const { isPageTableUpdated } = ui
  const { setIsPageTableUpdated, setAddressFilters, showAlert, setScannedAddress, setScannedAddressDetails } = actions

  // Hooks
  const { getAddresses, getAddressesData, getAddressesError, isGetAddressesLoading } = useGetAddresses()

  // Local states
  const [addresses, setAddresses] = useState([])
  const [pagination, setPagination] = useState()
  const [openedTab, setOpenedTab] = useState()

  // Functions
  function handleScannedAddress() {
    const newAddress = {
      id: scannedAddress.address_id,
      address: scannedAddress.address,
      chain: scannedAddress.chain,
      symbol: scannedAddress.symbol,
      risk_score: scannedAddress.risk_data?.risk_score,
      risk_ratio: scannedAddress.risk_data?.risk_ratio,
      balance_usd: scannedAddress.financial_data?.balance_usd,
      balance: scannedAddress.financial_data?.balance,
      modify_tz: scannedAddress.request_tz,
      link_id: scannedAddress.link_id,
      link_type: scannedAddress.link_type,
      is_self_hosted: scannedAddress.is_self_hosted,
      modified_by_name: scannedAddress.modified_by_name,
    }
    const newAddressList = [newAddress]
    addresses.forEach((item) => {
      if (item.address !== scannedAddress.address) {
        newAddressList.push(item)
      }
    })
    // Changing it back to null
    setScannedAddress(null)
    setAddresses(newAddressList)
    setScannedAddressDetails(scannedAddress)
  }
  function fetchAddresses() {
    getAddresses(addressFilters)
  }

  // UseEffects
  useEffect(() => {
    setAddressFilters()
  }, [])
  useEffect(() => fetchAddresses(), [addressFilters])
  useEffect(() => {
    if (isPageTableUpdated) {
      fetchAddresses()
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])
  useEffect(() => {
    if (getAddressesData) {
      setAddresses(getAddressesData.items)
      setPagination(getAddressesData.pagination)
    }
  }, [getAddressesData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (getAddressesError) showAlert({ type: 'error', message: 'An error occurred in fetching addresses' })
  }, [getAddressesError])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (scannedAddress) handleScannedAddress()
  }, [scannedAddress])

  return (
    <TableWrapper>
      <AccordionTable
        totalItems={pagination?.total && pagination.total}
        minWidth={900}
        filterComponents={<AddressTableFilter />}
        tableFilters={addressFilters}
        setTableFilters={setAddressFilters}
      >
        {addresses.length > 0 &&
          !isGetAddressesLoading &&
          addresses.map((data, i) => (
            <AddressesTableItem
              openedTab={openedTab}
              setOpenedTab={setOpenedTab}
              key={`${data?.id}${i}`}
              data={data}
              getAddressesError={getAddressesError}
            />
          ))}
        {isGetAddressesLoading && <LoadingTablePlaceholder />}
        {addresses.length < 1 && !isGetAddressesLoading && <EmptyTablePlaceholder setFilter={setAddressFilters} />}
      </AccordionTable>
    </TableWrapper>
  )
}

// Default Props
AddressesTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
  address: { addressChains: null },
}

// Proptypes Validation
AddressesTable.propTypes = {
  filters: PropTypes.shape({
    addressFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setAddressFilters: PropTypes.func,
    showAlert: PropTypes.func,
    setScannedAddress: PropTypes.func,
    setScannedAddressDetails: PropTypes.func,
    setAddressChains: PropTypes.func,
    setDefaultAddressProvider: PropTypes.func,
  }),
  address: PropTypes.shape({
    scannedAddress: PropTypes.shape({
      address_id: PropTypes.string,
      address: PropTypes.string,
      chain: PropTypes.string,
      symbol: PropTypes.string,
      symbol_icon: PropTypes.string,
      financial_data: {
        provider_name: PropTypes.string,
        balance: PropTypes.number,
        balance_usd: PropTypes.number,
        spent: PropTypes.number,
        spent_usd: PropTypes.number,
        received: PropTypes.number,
        received_usd: PropTypes.number,
        transaction_count: PropTypes.number,
        latest_activity: PropTypes.string,
      },
      risk_data: {
        provider_name: PropTypes.string,
        risk_score: PropTypes.number,
        vasp_name_legal: PropTypes.string,
        vasp_name_business: PropTypes.string,
        vasp_website: PropTypes.string,
        vasp_category: PropTypes.string,
        api_result_id: PropTypes.string,
      },
      request_tz: PropTypes.string,
      modified_by_name: PropTypes.string,
      link_id: PropTypes.string,
      link_type: PropTypes.number,
      is_self_hosted: PropTypes.bool,
    }),
    addressChains: PropTypes.shape({}),
    defaultAddressProvider: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressesTable)
