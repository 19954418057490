/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useSearchTransfer } from 'core/hooks/api'

// Styled Elements
import { TableFilterFormWrapper, TravelRuleTableFilterWrapper } from './TravelRuleSearchInput.elements'

// Components
import { Search } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function TravelRuleSearchInput(props) {
  // Destructure
  const { onTransferSearch, setIsTransferLoading, actions } = props

  // Store Actions
  const { setShowHeaderLoader } = actions

  // Hooks
  const { transferSearchData, searchTransfer, isTransferSearchLoading } = useSearchTransfer()

  // Functions
  // Search Functions
  const handleOnAutoCompleteInputChange = debounce((value) => {
    if (!value) {
      return onTransferSearch()
    }
    if (value) {
      return searchTransfer({ search_term: value })
    }
  }, 500)

  // useEffect
  useEffect(() => {
    if (transferSearchData) onTransferSearch(transferSearchData)
    if (!transferSearchData) onTransferSearch([])
  }, [transferSearchData])

  useEffect(() => {
    if (isTransferSearchLoading) {
      setIsTransferLoading(true)
      setShowHeaderLoader(true)
    } else if (!isTransferSearchLoading) {
      setIsTransferLoading(false)
      setShowHeaderLoader(false)
    }
  }, [isTransferSearchLoading])

  return (
    <TableFilterFormWrapper id="travelRuleTableFilter">
      <TravelRuleTableFilterWrapper>
        <Search
          disabledOptions
          placeholder="Search Name..."
          onInputChange={handleOnAutoCompleteInputChange}
          isLoading={isTransferSearchLoading}
        />
      </TravelRuleTableFilterWrapper>
    </TableFilterFormWrapper>
  )
}

// Default Props
TravelRuleSearchInput.defaultProps = {
  onTransferSearch: () => {},
  filters: {},
  actions: {},
}

// Proptypes Validation
TravelRuleSearchInput.propTypes = {
  onTransferSearch: PropTypes.func,
  filters: PropTypes.shape({
    travelRuleFilters: PropTypes.shape({
      sort: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      direction_id: PropTypes.string,
      asset_code: PropTypes.number,
      transfer_status_id: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setTravelRuleFilters: PropTypes.func,
  }),
  setIsTransferLoading: PropTypes.func,
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelRuleSearchInput)
