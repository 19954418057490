import styled from 'styled-components'

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 14.5px;
  font-weight: 400;
  padding: 0px 0px 20px 0px;
  width: 100%;
`

export const ModalFooterButton = styled.div`
  margin: 0px 0px 0px 10px;
`
export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;

  & div {
    flex: 1;
  }
`
export const GridRowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  padding: 10px 0;
  gap: 20px;
`
export const Label = styled.div`
  font-weight: 700;
  ${({ error, theme }) => error && `color: ${theme.colors.red}`}
`
