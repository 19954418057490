import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useGetReports } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './ReportsAddressTable.elements'

// Views
import { Table, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'
import { ReportsAddressTableItem, ReportsAddressTableHeader, ReportsAddressTableFilter } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function ReportsAddressTable(props) {
  // Destructure
  const { filters, ui, actions } = props

  // Store States
  const { reportsAddressFilters } = filters
  const { isPageTableUpdated } = ui
  const { setIsPageTableUpdated, setReportsAddressFilters, showAlert } = actions

  // Hooks
  const { getReports, getReportsData, getReportsError, isGetReportsLoading } = useGetReports()

  // Local states
  const [reports, setReports] = useState([])
  const [pagination, setPagination] = useState()

  // Functions
  const fetchData = () => {
    getReports(reportsAddressFilters)
  }

  // UseEffects
  useEffect(() => fetchData(), [reportsAddressFilters])
  useEffect(() => {
    if (isPageTableUpdated) {
      fetchData()
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])
  useEffect(() => {
    if (getReportsData) {
      setReports(getReportsData.items)
      setPagination(getReportsData.pagination)
    }
  }, [getReportsData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (getReportsError) showAlert({ type: 'error', message: 'An error occurred in fetching reports' })
  }, [getReportsError])
  return (
    <TableWrapper>
      <Table
        totalItems={pagination?.total && pagination.total}
        minWidth={900}
        tableFilters={reportsAddressFilters}
        setTableFilters={setReportsAddressFilters}
      >
        <ReportsAddressTableFilter />
        <ReportsAddressTableHeader />
        {reports.length > 0 && !isGetReportsLoading && (
          <>
            {reports.map((data) => (
              <ReportsAddressTableItem key={data.id} data={data} />
            ))}
          </>
        )}
        {reports.length < 1 && (
          <div>
            {isGetReportsLoading ? (
              <LoadingTablePlaceholder />
            ) : (
              <EmptyTablePlaceholder setFilter={setReportsAddressFilters} />
            )}
          </div>
        )}
      </Table>
    </TableWrapper>
  )
}

// Default Props
ReportsAddressTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
ReportsAddressTable.propTypes = {
  filters: PropTypes.shape({
    reportsAddressFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setReportsAddressFilters: PropTypes.func,
    showAlert: PropTypes.func,
    toggleUpgradeModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsAddressTable)
