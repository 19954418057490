import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Store
import { actions } from 'core/store'

// Components
import { SkeletonText } from 'views/components'

// Constants
import {
  // STANDARD_DATETIME_FORMAT,
  STANDARD_DATE_FORMAT,
} from 'common/constants/dateFormat'

// Styled Elements
import {
  Text,
  ColContainer,
  RowWrapper,
  RowContainer,
  TextRowContainer,
  BlockContainer,
  InfoTitle,
} from './FinancialDetails.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function FinancialDetails() {
  return (
    <BlockContainer style={{ marginTop: '15px', padding: '10px', borderWidth: 2 }}>
      <RowWrapper style={{ padding: 0 }}>
        <RowContainer style={{ gap: '15px' }}>
          <RowContainer style={{ gap: '15px' }}>
            <BlockContainer>
              <RowContainer style={{ flex: 1 }}>
                <ColContainer>
                  <TextRowContainer>
                    <InfoTitle>Digital Assets: </InfoTitle>
                    <SkeletonText width="90" />
                  </TextRowContainer>
                  <TextRowContainer>
                    <InfoTitle>Blockchain:</InfoTitle>
                    <SkeletonText width="50" />
                  </TextRowContainer>
                  <TextRowContainer>
                    <InfoTitle>Fee Amount USD: </InfoTitle>
                    <SkeletonText width="80" />
                  </TextRowContainer>
                  <TextRowContainer>
                    <InfoTitle>Timestamp:</InfoTitle>
                    <Text>{moment('2024-11-07').format(STANDARD_DATE_FORMAT)}</Text>
                  </TextRowContainer>
                </ColContainer>

                <ColContainer>
                  <TextRowContainer>
                    <InfoTitle>Input Amount USD:</InfoTitle>
                    <SkeletonText width="110" />
                  </TextRowContainer>
                  <TextRowContainer>
                    <InfoTitle>Input Events: </InfoTitle>
                    <SkeletonText width="50" />
                  </TextRowContainer>
                  <TextRowContainer>
                    <InfoTitle>Output Amount USD: </InfoTitle>
                    <SkeletonText width="110" />
                  </TextRowContainer>
                  <TextRowContainer>
                    <InfoTitle>Output Events: </InfoTitle>
                    <SkeletonText width="50" />
                  </TextRowContainer>
                </ColContainer>
              </RowContainer>
            </BlockContainer>

            <BlockContainer
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: '8px',
                flexWrap: 'wrap',
                height: 'fit-content',
              }}
            >
              {Array.from({ length: 30 }, (_, index) => (
                <SkeletonText key={index} width={`${Math.floor(Math.random() * 80) + 30}`} />
              ))}
            </BlockContainer>
          </RowContainer>
        </RowContainer>
      </RowWrapper>
    </BlockContainer>
  )
}

// Default Props
FinancialDetails.defaultProps = {
  actions: {},
  transactionData: {},
}

// Proptypes Validation
FinancialDetails.propTypes = {
  actions: PropTypes.shape({
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  transactionData: PropTypes.shape({
    id: PropTypes.string,
    hash: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    in_usd: PropTypes.number,
    out_usd: PropTypes.number,
    fee_amount_usd: PropTypes.number,
    balance: PropTypes.number,
    last_modified: PropTypes.string,
    created: PropTypes.string,
    in_count: PropTypes.number,
    out_count: PropTypes.number,
    asset_count: PropTypes.number,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialDetails)
