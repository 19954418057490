export const removeSameValues = (obj1, obj2) => {
  /** *
   * @description ! The order in the params for this function matters!
   * The first one will be the final result
   * the second param is the basis of what needs to be removed
   * * */
  const result = {}
  Object.entries(obj1).forEach(([key, value]) => {
    if (obj2[key] !== value) {
      result[key] = value
    }
  })
  return result
}
