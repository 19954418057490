import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Elements
import { Wrapper } from './AdminVaspDetails.elements'

// Layouts
import { AdminVaspDetailsSubHeader } from 'views/layouts'
import DetailsTab from './DetailsTab'
import UsersTab from './UsersTab/UsersTab'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AdminVaspDetails() {
  // state
  const [page, setPage] = useState(0)
  return (
    <Wrapper>
      <AdminVaspDetailsSubHeader page={page} setPage={setPage} />
      {page === 0 && <DetailsTab />}
      {page === 1 && <UsersTab />}
    </Wrapper>
  )
}

// Default Props
AdminVaspDetails.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
AdminVaspDetails.propTypes = {
  filters: PropTypes.shape({
    vaspDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setVaspDirectoryFilters: PropTypes.func,
    setIsIndividualVaspDrawerOpen: PropTypes.func,
    setIsEntityVaspDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminVaspDetails)
