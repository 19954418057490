import { authAxios, createAxiosInstance } from 'common/utils/axios'

import { ACCOUNT_ENDPOINTS, DIRECTORY_SERVICE_URL } from 'common/constants/apiUrls'

export const authAxiosLocalServer = createAxiosInstance(DIRECTORY_SERVICE_URL)

const handler = authAxios

export const GetAllAccounts = async (values) => {
  const response = await handler.get(ACCOUNT_ENDPOINTS.GET_ALL_ACCOUNTS, {
    params: { ...values, is_recipient: false },
  })
  const { data } = response.data

  return data
}

export const GetAccount = async ({ account_id }) => {
  const response = await handler.get(`${ACCOUNT_ENDPOINTS.GET_ACCOUNT}${account_id}`)

  const { data } = response.data

  return data
}
export const SearchAccount = async ({ search_term }) => {
  const response = await handler.get(ACCOUNT_ENDPOINTS.SEARCH_ACCOUNT, {
    params: {
      search_term,
      is_recipient: false,
    },
  })

  const { data } = response.data

  return data
}

export const CreateAccountEntity = async (values) => {
  const response = await handler.post(ACCOUNT_ENDPOINTS.CREATE_ACCOUNT_ENTITY, {
    ...values,
    is_recipient: false,
  })

  const { data } = response.data

  return data
}
export const CreateAccountIndividual = async (values) => {
  const response = await handler.post(ACCOUNT_ENDPOINTS.CREATE_ACCOUNT_INDIVIDUAL, {
    ...values,
    is_recipient: false,
  })

  const { data } = response.data

  return data
}
export const UpdateAccountEntity = async ({ account_id, values }) => {
  const response = await handler.patch(`${ACCOUNT_ENDPOINTS.UPDATE_ACCOUNT_ENTITY}${account_id}`, {
    ...values,
    is_recipient: false,
  })

  const { data } = response.data

  return data
}
export const UpdateAccountIndividual = async ({ account_id, values }) => {
  const response = await handler.patch(`${ACCOUNT_ENDPOINTS.UPDATE_ACCOUNT_INDIVIDUAL}${account_id}`, {
    ...values,
    is_recipient: false,
  })

  const { data } = response.data

  return data
}
