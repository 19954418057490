/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useRef, useCallback, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

// Assets
import { HelpIcon } from 'assets/images'

// Constants
import { CHAIN_TYPE_SIMPLIFIED_OPTIONS } from 'common/constants/formOptions'
import { BLOCKCHAIN_SYMBOL_MONO_SVGS } from 'common/constants/blockchainMonoSvgs'

// Store
import { actions } from 'core/store'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'

// Hooks
import { useFetchAssetConversion } from 'core/hooks/api'

// Styled Elements
import {
  TransferFormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormWrapper,
  FormLabel,
  SubText,
  AmountWrapper,
  ModalWrapper,
  ModalContainer,
  ModalTitle,
  ModalContent,
  ModalBody,
  ModalRowWrapper,
  AssetContainer,
} from './AmountTransferForm.elements'

// Views
import { TextField, SimpleSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AmountTransferForm(props) {
  // Destructure
  const { formData, setFormData, address } = props

  // Store
  const { addressChains } = address

  // Hooks
  const { getAssetConversion, assetConversionData, isAssetConversionLoading } = useFetchAssetConversion()

  // Internal State
  const [currencyConversion, setCurrencyConversion] = useState([''])
  const [isAmountUsdLoading, setIsAmountUsdLoading] = useState(false)
  const [availableChains, setAvailableChains] = useState()

  // functions
  const debouncedFunctionRef = useRef()
  debouncedFunctionRef.current = () => {
    if (formData?.transfer?.amount && !formData?.transfer?.asset_code) {
      getAssetConversion({
        from_symbols: CHAIN_TYPE_SIMPLIFIED_OPTIONS[0].value,
        to_symbols: 'USD,EUR,GBP,JPY,CAD,AUD,SGD,AED',
      })
    }
    if (formData?.transfer?.amount && formData?.transfer?.asset_code) {
      getAssetConversion({
        from_symbols: formData?.transfer?.asset_code,
        to_symbols: 'USD,EUR,GBP,JPY,CAD,AUD,SGD,AED',
      })
    }
  }

  function handleAvailableChains() {
    const providers = { ...addressChains?.financial }
    let allChains = {}
    Object.keys(providers).forEach((item) => {
      if (item === 'FDBDM') {
        allChains = { ...allChains, ...providers[item] }
      }
    })
    setAvailableChains(allChains)
  }
  const debounceFetch = useCallback(
    debounce((...args) => debouncedFunctionRef.current(...args), 500),
    []
  )

  function handleAssetConversion() {
    const newConversionData = []
    Object.keys(assetConversionData.data).forEach((asset) => {
      Object.keys(assetConversionData.data[asset]).forEach((currency) => {
        if (currency === 'USD') {
          setFormData({
            ...formData,
            transfer: {
              ...formData.transfer,
              amount_usd: assetConversionData.data[asset][currency] * formData.transfer.amount,
            },
          })
        }
        newConversionData.push(
          `${currency} ${numberWithCommas(
            Math.round(assetConversionData.data[asset][currency] * formData.transfer.amount * 100) / 100
          )}`
        )
      })
    })
    setCurrencyConversion(newConversionData)
    setIsAmountUsdLoading(false)
  }

  const handleTransactionChange = (value) => {
    setIsAmountUsdLoading(true)
    setFormData({ ...formData, transfer: { ...formData.transfer, ...value } })
  }
  const handleAssetChange = (value) => {
    setIsAmountUsdLoading(true)
    setFormData({ ...formData, transfer: { ...formData.transfer, ...value } })
  }

  // useEffect
  useEffect(() => {
    handleAvailableChains()
  }, [addressChains])
  useEffect(() => assetConversionData && handleAssetConversion(), [assetConversionData])
  useEffect(() => {
    if (!formData.transfer.amount_usd) {
      setIsAmountUsdLoading(true)
    } else if (formData.transfer.amount_usd) {
      setCurrencyConversion([`${numberWithCommas(Math.round(formData.transfer.amount_usd * 100) / 100)} USD`])
      setIsAmountUsdLoading(false)
    }
  }, [])
  useEffect(() => {
    if (isAmountUsdLoading) setFormData({ ...formData, transfer: { ...formData.transfer, amount_usd: null } })
  }, [isAmountUsdLoading])

  return (
    <TransferFormWrapper>
      <FormWrapper>
        <FormGroupWrapper>
          <FormLabel>Please enter transfer amount</FormLabel>
          <FormInputGroupItem>
            <TextField
              placeholder="Amount e.g. 1.5"
              type="number"
              min="0"
              step="0.1"
              value={formData?.transfer?.amount}
              onChange={(e) => {
                handleTransactionChange({ amount: e.target.value })
                debounceFetch()
              }}
            />
            <AssetContainer>
              {BLOCKCHAIN_SYMBOL_MONO_SVGS[formData?.transfer?.asset_code || CHAIN_TYPE_SIMPLIFIED_OPTIONS[0].label] &&
                cloneElement(
                  BLOCKCHAIN_SYMBOL_MONO_SVGS[formData?.transfer?.asset_code || CHAIN_TYPE_SIMPLIFIED_OPTIONS[0].label]
                )}
              {availableChains && (
                <SimpleSelect
                  id="addressSearchOptionsChain"
                  placeholder="Chain"
                  options={Object.keys(availableChains).map((item) => ({
                    value: availableChains[item]?.symbol,
                    label: availableChains[item]?.symbol,
                  }))}
                  value={formData?.transfer?.asset_code || addressChains?.[Object.keys(addressChains)[0]]?.symbol}
                  onChange={(value) => {
                    handleAssetChange({ asset_code: value, dti: availableChains[value]?.dti })
                    debounceFetch()
                  }}
                />
              )}
              {/* ???? Bruh why did I write this ???? */}
              {/* {addressChains && Object.keys(addressChains)?.length > 2 && (
                <SimpleSelect
                  id="addressSearchOptionsChain"
                  placeholder={addressChains?.[Object.keys(addressChains)[0]]?.symbol}
                  options={Object.keys(addressChains)?.map((key) => ({
                    label: addressChains[key]?.symbol,
                    value: addressChains[key]?.symbol,
                  }))}
                  value={formData?.transfer?.asset_code || addressChains?.[Object.keys(addressChains)[0]]?.symbol}
                  onChange={(value) => {
                    console.log(value)
                    handleAssetChange({ asset_code: value, dti: DTI_SIMPLIFIED_MAPPING?.[value]?.dti })
                    debounceFetch()
                  }}
                />
              )} */}
            </AssetContainer>
            <AmountWrapper>
              <SubText>
                {isAssetConversionLoading && 'Loading...'}
                {!isAssetConversionLoading && currencyConversion?.length > 1 && (
                  <>
                    {currencyConversion[0]} <HelpIcon />
                  </>
                )}
                {!isAssetConversionLoading && !assetConversionData && !formData.transfer.amount_usd && ' No Data'}
              </SubText>
              {currencyConversion?.length > 1 && (
                <ModalWrapper id="ConversionModal">
                  <ModalContainer>
                    <ModalTitle>Conversion Rate</ModalTitle>
                    <ModalContent>
                      {currencyConversion.map((item) => (
                        <ModalRowWrapper>
                          <ModalBody>{item?.split(' ')[0]}</ModalBody>
                          <ModalBody>{item?.split(' ')[1]}</ModalBody>
                        </ModalRowWrapper>
                      ))}
                    </ModalContent>
                    <ModalContent style={{ border: 'none' }}>
                      <ModalBody>
                        Conversion rates are calculated based on CCCAGG methodology.{' '}
                        <a
                          target="_blank"
                          href="https://www.cryptocompare.com/media/12318004/cccagg.pdf"
                          rel="noreferrer"
                        >
                          Learn more about this index.
                        </a>
                      </ModalBody>
                    </ModalContent>
                  </ModalContainer>
                </ModalWrapper>
              )}
            </AmountWrapper>
          </FormInputGroupItem>

          <FormLabel>Please enter transaction hash (Optional)</FormLabel>
          <FormInputGroupItem id="txhashinput">
            <TextField
              placeholder="transaction hash"
              value={formData?.transfer?.tx_hash}
              onChange={(e) => {
                setFormData({ ...formData, transfer: { ...formData.transfer, tx_hash: e.target.value } })
              }}
            />
          </FormInputGroupItem>
        </FormGroupWrapper>
      </FormWrapper>
    </TransferFormWrapper>
  )
}

// Default Props
AmountTransferForm.defaultProps = {
  formData: {},
  setFormData: () => {},
  actions: {},
  form: {},
}

// Proptypes Validation
AmountTransferForm.propTypes = {
  formData: PropTypes.shape({
    transfer: PropTypes.shape({
      tx_hash: PropTypes.string,
      amount: PropTypes.number,
      amount_usd: PropTypes.number,
      asset_code: PropTypes.string,
      protocol_code: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setIsVaspDrawerOpen: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdVasp: PropTypes.shape({}),
  }),
  setFormData: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(AmountTransferForm)
