import styled from 'styled-components'

export const SkeletonTextWrapper = styled.div`
  width: 300px;
  height: 20px;
  background: #474f5f;

  border-radius: 5px;

  // Conditional Properties
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px;`}
  ${({ height }) => height && `height: ${height}px;`}
  ${({ width }) => width && `width: ${width}px;`};

  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #474f5f;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-out infinite;

  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
`
