import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const Container = styled.div`
  display: flex;
  gap: 20px;
`
export const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 15px;
  & button {
    width: fit-content;
    font-weight: 400;
    font-size: 16px !important;
    padding: 5px 15px;
    border-radius: 7px;
  }
`
