import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`
export const EditBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 -40px 0;
`
export const FooterBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
`

export const Title = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Subtitle = styled.p`
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
`
export const Spacer = styled.div`
  padding-top: 20px;
  width: 100%;
`
export const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr, 200px;
`
