import React from 'react'

// Styled Elements
import {
  SubHeaderWrapper,
  SubHeaderNavList,
  SubHeaderNavListItem,
  SubHeaderNavListLink,
} from './AdminVaspsSubHeader.elements'

function AdminVaspsSubHeader() {
  return (
    <SubHeaderWrapper>
      <SubHeaderNavList>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/admin/vasps">
            Visible VASPs
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>

        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/admin/vasps/requested">
            Requested VASPs
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>

        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/admin/vasps/customers">
            Customer VASPs
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>

        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/admin/vasps/midprospects">
            Mid-Prospect VASPs
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>
      </SubHeaderNavList>
    </SubHeaderWrapper>
  )
}

export default AdminVaspsSubHeader
