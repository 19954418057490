import { authAxios, createAxiosInstance } from 'common/utils/axios'
import { TRANSACTION_ENDPOINTS } from '../../common/constants/apiUrls'

import { LOCAL_URL } from 'common/constants/apiUrls'

const {
  CREATE_OR_UPDATE_TRANSACTION,
  LINK_TRANSACTION,
  DELETE_TRANSACTION,
  GET_TRANSACTIONS,
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTION_HISTORY,
  POST_TRANSACTION_REPORT,
  GET_TRANSACTION_EVENTS,
  GET_TRANSACTION_CHAINS,
} = TRANSACTION_ENDPOINTS

export const authAxiosLocalServer = createAxiosInstance(LOCAL_URL)

const handler = authAxios

export const CreateOrUpdateTransaction = async ({
  transaction,
  chain,
  type,
  integration_id,
  link_id,
  name_first,
  name_last,
}) => {
  const response = await handler.post(CREATE_OR_UPDATE_TRANSACTION, {
    transaction,
    chain,
    type,
    integration_id,
    link_id,
    name_first,
    name_last,
  })

  const { data } = response.data

  return data
}
export const LinkTransaction = async ({
  sender_link_id,
  sender_link_type,
  recipient_link_id,
  recipient_link_type,
  transaction_id,
}) => {
  const response = await handler.patch(`${LINK_TRANSACTION}/${transaction_id}/link`, {
    sender_link_id,
    sender_link_type,
    recipient_link_id,
    recipient_link_type,
  })

  const { data } = response.data

  return data
}
export const DeleteTransaction = async ({ transaction_id }) => {
  const response = await handler.patch(`${DELETE_TRANSACTION}/${transaction_id}`)

  const { data } = response.data

  return data
}

export const GetTransactions = async ({ start_range, end_range, page, size, sort, link_id, identified }) => {
  const params = {
    start_range: start_range && start_range,
    end_range: end_range && end_range,
    start_range_create: start_range && start_range,
    end_range_create: end_range && end_range,
    page: page && page,
    size: size && size,
    sort: sort && sort,
  }
  if (link_id) {
    params.link_id = link_id
  }
  if (identified === false || identified === true) {
    params.identified = identified
  }
  const response = await handler.get(GET_TRANSACTIONS, { params })

  const { data } = response.data

  return data
}
export const GetTransactionDetails = async ({ transaction_id, start_range, end_range, page, size, sort }) => {
  const response = await handler.get(`${GET_TRANSACTION_DETAILS}/${transaction_id}`, {
    params: {
      start_range: start_range && start_range,
      end_range: end_range && end_range,
      page: page && page,
      size: size && size,
      sort: sort && sort,
    },
  })

  const { data } = response.data

  return data
}
export const GetTransactionHistory = async ({ transaction_id, start_range, end_range, page, size, sort }) => {
  const response = await handler.get(`${GET_TRANSACTION_HISTORY}/${transaction_id}/history`, {
    params: {
      start_range: start_range && start_range,
      end_range: end_range && end_range,
      page: page && page,
      size: size && size,
      sort: sort && sort,
    },
  })

  const { data } = response.data

  return data
}
// !UNSURE IF THIS IS RIGHT
export const PostTransactionReport = async ({ transaction_id, sections }) => {
  let url = `${POST_TRANSACTION_REPORT}/${transaction_id}/`
  if (sections?.length > 0) {
    url += `reports?sections=${sections.join(',')}`
  }
  const response = await handler.post(url)

  const { data } = response.data

  return data
}

export const GetTransactionEvents = async ({ transaction_id, page, type, linked, risk_integration_id }) => {
  const params = {
    sort: '-amount_usd',
    size: 10,
    page,
    type,
  }
  if (risk_integration_id) {
    params.risk_integration_id = risk_integration_id
  }
  if (linked) {
    params.linked = linked
  }
  const response = await handler.get(`${GET_TRANSACTION_EVENTS}/${transaction_id}/events`, { params })

  const { data } = response.data

  return data
}

export const GetTransactionChains = async (props) => {
  const params = {}
  if (props?.provider_name) {
    params.provider_name = props?.provider_name
  }
  const response = await handler.get(GET_TRANSACTION_CHAINS, { params })

  const { data } = response.data

  return data
}
