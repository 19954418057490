import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Views
import { Modal, ModalHeader, Image } from 'views/components'
import { RequestVaspForm, PushRequestVaspForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function RequestVaspModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isRequestVaspModalOpen } = ui

  // Store Actions
  const { setIsRequestVaspModalOpen } = actions

  // Local State
  const [isPushRequest, setIsPushRequest] = useState(false)
  const [email, setEmail] = useState()

  // Functions
  const toggleModal = () => {
    setIsRequestVaspModalOpen()
  }
  useEffect(() => {
    if (!isRequestVaspModalOpen) {
      setIsPushRequest(false)
      setEmail('')
    }
  }, [isRequestVaspModalOpen])
  return (
    <Modal isOpen={isRequestVaspModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Request for VASP
      </ModalHeader>
      {!isPushRequest && <RequestVaspForm setIsPushRequest={setIsPushRequest} setEmail={setEmail} />}
      {isPushRequest && <PushRequestVaspForm setIsPushRequest={setIsPushRequest} email={email} />}
    </Modal>
  )
}

// Default Props
RequestVaspModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
RequestVaspModal.propTypes = {
  ui: PropTypes.shape({
    isRequestVaspModalOpen: PropTypes.bool,
    approveVaspDetails: PropTypes.shape({
      vasp_name: PropTypes.string,
      vasp_id: PropTypes.string,
    }),
  }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  address: PropTypes.shape({
    addressRemove: PropTypes.shape({ address_id: PropTypes.string }),
  }),
  actions: PropTypes.shape({
    setIsRequestVaspModalOpen: PropTypes.func,
    showAlert: PropTypes.func,
    setAddressRemove: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestVaspModal)
