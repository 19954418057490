/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

export default function InIcon(props) {
  return (
    <svg
      width={props?.size || '15'}
      height={props?.size || '15'}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.96566 11.0355H11.0367V3.96447" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.96567 3.96409L11.0367 11.0352" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
