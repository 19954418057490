import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

// Views
import { Image } from 'views/components'

// Styled Elements
import {
  SelectWrapper,
  Label,
  FormGroupWrapper,
  SelectInput,
  SelectInputLabel,
  SelectInputOptionsContainer,
  SelectInputOptions,
  SelectPlaceholder,
  Abbreviation,
} from './SimpleSelect.elements'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, setShowOptions) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function SimpleSelect(props) {
  // Destructure
  const { id, label, placeholder, options, onChange, value, disabled, vertical, ...rest } = props

  // Variables
  const withOptions = options && options?.length > 0
  const wrapperRef = useRef()

  // States
  const [formValue, setFormValue] = useState('')
  const [valueLabel, setValueLabel] = useState('')
  const [showOptions, setShowOptions] = useState(false)

  // Functions
  const handleOnChange = (value, label) => {
    onChange(value)
    if (value === undefined) {
      setFormValue(value)
      setValueLabel(placeholder || '')
      return setShowOptions(false)
    }
    setFormValue(value)
    setValueLabel(label)
    setShowOptions(false)
  }
  // Closing options on click away
  useOutsideAlerter(wrapperRef, setShowOptions)

  // UseEffects
  useEffect(() => {
    // setting the value and label on initial load
    if (value === undefined || value === null) {
      setValueLabel(placeholder)
      return
    }

    setFormValue(value)
    const currentLabel = options.find((data) => {
      if (typeof data.value === 'number') {
        return data.value === Number(value)
      }
      return data.value === value
    })
    if (currentLabel?.label) {
      setValueLabel(currentLabel.label)
    } else if (!currentLabel?.label) {
      setValueLabel(placeholder || '')
    }
  }, [value])

  return (
    <SelectWrapper ref={wrapperRef} id={id} vertical={vertical}>
      {label && <Label disabled={disabled}>{label}</Label>}
      <FormGroupWrapper>
        <SelectInput disabled={disabled} type="select" value={`${formValue}`} onChange={() => {}} {...rest} />
        <SelectInputLabel
          className="simpleSelectInputLabel"
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              setShowOptions((prevState) => !prevState)
            }
          }}
        >
          <SelectPlaceholder vertical={vertical} disabled={disabled}>
            {' '}
            {!valueLabel ? placeholder : valueLabel}{' '}
          </SelectPlaceholder>
        </SelectInputLabel>
        {showOptions && options?.length > 0 && (
          <SelectInputOptionsContainer vertical={vertical}>
            {withOptions &&
              options.map(({ value, label, abrv, icon, SVG }) => {
                if (label !== valueLabel) {
                  return (
                    <SelectInputOptions
                      onClick={() => {
                        handleOnChange(value, label)
                      }}
                      value={value}
                      key={label}
                    >
                      {icon && <Image src={icon} width={30} height={30} />}
                      {SVG && SVG}
                      {label}
                      {abrv && <Abbreviation> {abrv} </Abbreviation>}
                    </SelectInputOptions>
                  )
                }
              })}
          </SelectInputOptionsContainer>
        )}
      </FormGroupWrapper>
    </SelectWrapper>
  )
}

// Default Props
SimpleSelect.defaultProps = {
  id: '',
  label: '',
  value: undefined,
  placeholder: '',
  options: [],
  onChange: () => {},
  disabled: false,
  vertical: false,
}

// Proptypes Validation
SimpleSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  vertical: PropTypes.bool,
}

export default SimpleSelect
