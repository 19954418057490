/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import {
  TRAVELRULE_STATUS_FILTER,
  TRAVELRULE_SORT,
  TRAVELRULE_DIRECTION_FILTER,
  TRAVELRULE_PROTOCOL_FILTER,
} from 'common/constants/filters'

// Assets
import FilterIcon from 'assets/images/filter'
import CloseIcon from 'assets/images/close'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  TableFilterFormWrapper,
  TravelRuleTableFilterWrapper,
  TravelRuleTableFilterContainer,
} from '../Filter.elements'

// Components
import { SimpleSelect, CustomDateRangePicker, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function TravelRuleTableFilter(props) {
  // Destructure
  const { filters, actions, address } = props
  const { travelRuleFilters } = filters
  const { sort, transfer_status_id, protocol_code, dti, start_date, end_date, direction_id } = travelRuleFilters

  // Store Actions
  const { setTravelRuleFilters } = actions
  const { addressChains } = address

  // Hooks
  const [availableChains, setAvailableChains] = useState()
  const [isFilterOpen, setIsFilterOpen] = useState()

  // Functions
  // Filter Functions
  const handleDateSelect = async ({ start, end }) => {
    await setTravelRuleFilters({
      ...travelRuleFilters,
      start_date: start,
      end_date: end,
      page: 1,
    })
  }
  const handleAssetSelect = async (value) => {
    await setTravelRuleFilters({ ...travelRuleFilters, dti: value })
  }
  const handleDirectionSelect = async (value) => {
    await setTravelRuleFilters({ ...travelRuleFilters, direction_id: value })
  }
  const handleProtocolSelect = async (value) => {
    await setTravelRuleFilters({ ...travelRuleFilters, protocol_code: value })
  }
  const handleStatusSelect = async (value) => {
    await setTravelRuleFilters({
      ...travelRuleFilters,
      transfer_status_id: Number.isNaN(+value) ? undefined : +value,
    })
  }
  const handleSortSelect = async (value) => {
    await setTravelRuleFilters({ ...travelRuleFilters, sort: value })
  }
  // Search Functions
  function handleAvailableChains() {
    const providers = { ...addressChains?.financial }
    let allChains = {}
    Object.keys(providers).forEach((item) => {
      if (item === 'FDBDM') {
        allChains = { ...allChains, ...providers[item] }
      }
    })
    setAvailableChains({ ALL: { symbol: undefined, label: 'All' }, ...allChains })
  }

  // useEffect
  useEffect(() => {
    handleAvailableChains()
  }, [addressChains])

  return (
    <TableFilterFormWrapper id="travelRuleTableFilter">
      <TravelRuleTableFilterWrapper>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          startIcon={!isFilterOpen ? <FilterIcon /> : <CloseIcon />}
        >
          Filters
        </Button>
        <TravelRuleTableFilterContainer active={isFilterOpen}>
          <CustomDateRangePicker
            id="travelruleDateFilter"
            key={start_date}
            onApply={handleDateSelect}
            start={start_date}
            end={end_date}
            simplifiedDate
            vertical
          />
          <SimpleSelect
            label="Direction: "
            placeholder="All"
            options={TRAVELRULE_DIRECTION_FILTER}
            onChange={handleDirectionSelect}
            value={direction_id}
            vertical
          />
          {availableChains && (
            <SimpleSelect
              id="travelRuleAssetSort"
              label="Asset: "
              placeholder="All"
              options={Object.keys(availableChains).map((item) => ({
                value: availableChains[item]?.dti || undefined,
                label: availableChains[item]?.symbol || availableChains[item]?.label,
              }))}
              onChange={handleAssetSelect}
              value={dti}
              vertical
            />
          )}
          <SimpleSelect
            label="Status: "
            placeholder="All"
            options={TRAVELRULE_STATUS_FILTER}
            onChange={handleStatusSelect}
            value={transfer_status_id}
            vertical
          />
          <SimpleSelect
            label="Protocol: "
            placeholder="All"
            options={TRAVELRULE_PROTOCOL_FILTER}
            onChange={handleProtocolSelect}
            value={protocol_code}
            vertical
          />
          <SimpleSelect
            id="travelRuleSort"
            label="Sort By: "
            placeholder="Amount or Date"
            options={TRAVELRULE_SORT}
            onChange={handleSortSelect}
            value={sort}
            vertical
          />
        </TravelRuleTableFilterContainer>
      </TravelRuleTableFilterWrapper>
    </TableFilterFormWrapper>
  )
}

// Default Props
TravelRuleTableFilter.defaultProps = {
  filters: {},
  actions: {},
}

// Proptypes Validation
TravelRuleTableFilter.propTypes = {
  filters: PropTypes.shape({
    travelRuleFilters: PropTypes.shape({
      sort: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      direction_id: PropTypes.string,
      dti: PropTypes.number,
      transfer_status_id: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setTravelRuleFilters: PropTypes.func,
  }),
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelRuleTableFilter)
