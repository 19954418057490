import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Assets
// import PlusWhiteIcon from 'assets/images/plus-white'
// import UsersIcon from 'assets/images/users'
// import BriefCaseIcon from 'assets/images/briefcase'

// Constants
import {
  // STANDARD_DATETIME_FORMAT,
  STANDARD_DATE_FORMAT,
} from 'common/constants/dateFormat'

// Store
import { actions } from 'core/store'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'

// Styled Elements
import {
  Text,
  ColContainer,
  RowWrapper,
  RowContainer,
  TextRowContainer,
  BlockContainer,
  InfoTitle,
} from './FinancialDetails.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function FinancialDetails(props) {
  const { transactionData, isGetTransactionDetailsLoading, txDescription } = props

  // Functions
  function formatValue(number) {
    if (number < 0.01) return number
    return numberWithCommas(Math.round(number * 100) / 100)
  }
  if (transactionData && !isGetTransactionDetailsLoading) {
    return (
      <BlockContainer style={{ marginTop: '15px', padding: '10px', borderWidth: 2 }}>
        <RowWrapper style={{ padding: 0 }}>
          <RowContainer style={{ gap: '15px' }}>
            <RowContainer style={{ gap: '15px' }}>
              <BlockContainer>
                <RowContainer style={{ flex: 1 }}>
                  <ColContainer>
                    <TextRowContainer>
                      <InfoTitle>Digital Assets: </InfoTitle>
                      <Text>
                        {transactionData?.symbol}
                        {transactionData?.asset_count > 1 && `, +${transactionData.asset_count - 1}`}
                      </Text>
                    </TextRowContainer>
                    <TextRowContainer>
                      <InfoTitle>Blockchain:</InfoTitle>
                      <Text>{transactionData?.chain}</Text>
                    </TextRowContainer>
                    <TextRowContainer>
                      <InfoTitle>Fee Amount USD: </InfoTitle>
                      <Text>
                        {transactionData?.fee_amount_usd ? `$ ${formatValue(transactionData.fee_amount_usd)}` : '-'}
                      </Text>
                    </TextRowContainer>
                    <TextRowContainer>
                      <InfoTitle>Timestamp:</InfoTitle>
                      <Text>
                        {transactionData?.created && moment(transactionData?.created).format(STANDARD_DATE_FORMAT)}
                      </Text>
                    </TextRowContainer>
                  </ColContainer>

                  <ColContainer>
                    <TextRowContainer>
                      <InfoTitle>Input Amount USD:</InfoTitle>
                      <Text>{transactionData?.in_usd ? `$ ${formatValue(transactionData.in_usd)}` : '-'}</Text>
                    </TextRowContainer>
                    <TextRowContainer>
                      <InfoTitle>Input Events: </InfoTitle>
                      <Text>{transactionData?.in_count}</Text>
                    </TextRowContainer>
                    <TextRowContainer>
                      <InfoTitle>Output Amount USD: </InfoTitle>
                      <Text>{transactionData?.out_usd ? `$ ${formatValue(transactionData.out_usd)}` : '-'}</Text>
                    </TextRowContainer>
                    <TextRowContainer>
                      <InfoTitle>Output Events: </InfoTitle>
                      <Text>{transactionData?.out_count}</Text>
                    </TextRowContainer>
                  </ColContainer>
                </RowContainer>
              </BlockContainer>

              <BlockContainer>{txDescription && txDescription}</BlockContainer>
            </RowContainer>
          </RowContainer>
        </RowWrapper>
      </BlockContainer>
    )
  }
  return <BlockContainer />
}

// Default Props
FinancialDetails.defaultProps = {
  isGetTransactionDetailsLoading: false,
  actions: {},
  transactionData: {},
}

// Proptypes Validation
FinancialDetails.propTypes = {
  txDescription: PropTypes.element,
  isGetTransactionDetailsLoading: PropTypes.bool,
  actions: PropTypes.shape({
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  transactionData: PropTypes.shape({
    id: PropTypes.string,
    hash: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    in_usd: PropTypes.number,
    out_usd: PropTypes.number,
    fee_amount_usd: PropTypes.number,
    balance: PropTypes.number,
    last_modified: PropTypes.string,
    created: PropTypes.string,
    in_count: PropTypes.number,
    out_count: PropTypes.number,
    asset_count: PropTypes.number,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialDetails)
