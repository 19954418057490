import styled from 'styled-components'

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  & form {
    height: 100%;
  }
`
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-height: 80vh;
  overflow-y: auto;
`

// content
export const FormInputGroupItem = styled.div``
export const FormTitle = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const FormSuccess = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.blue};
`
export const FormError = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.red};
`
export const FormInfo = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 20px 0;
`
export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 0 0 0;
  gap: 20px;
`
export const TableButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 25px;
  gap: 50px;
  align-items: center;

  padding: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const TableRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  gap: 20px;
`

export const TablePanelWrapper = styled.div``
