import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Styled Components
import { CaseDetailsFormWrapper } from './CaseDetails.elements'

// Views
import { Container } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  CaseDetailsForm,
  // BreachReportTable,
  PageWrapper,
  CasesSubHeader,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function CaseDetails() {
  // States
  const [caseDetailsSummary, setCaseDetailsSummary] = useState({})
  // const [caseDetailsReport, setCaseDetailsReport] = useState([])

  // Hooks
  const { caseId } = useParams()
  const [casesData] = useLocalStorage('casesData')
  // const { getAlertDetails, alertDetailsData, isAlertDetailsLoading, isAlertDetailsError } = useFetchAlertDetails()

  // Functions
  function getData() {
    let foundCase
    if (casesData?.length > 0) {
      casesData.forEach((item) => {
        if (item.case_id === caseId) foundCase = item
      })
    }
    if (foundCase) setCaseDetailsSummary(foundCase)
  }

  // useEffect
  useEffect(() => {
    // Fetch Cases Data on Render
    // getAlertDetails({ alert_id: caseId })
    getData()
  }, [caseId])

  // useEffect(() => {
  //   if (alertDetailsData && !isAlertDetailsLoading) {
  //     setAlertDetailsSummary({ ...alertDetailsData, reports: [] })
  //     setAlertDetailsReport(alertDetailsData.reports)
  //   }
  // }, [alertDetailsData])

  // useEffect(() => {
  //   if (isAlertDetailsLoading) {
  //     return setShowHeaderLoader(true)
  //   }
  //   if (!isAlertDetailsLoading) {
  //     return setShowHeaderLoader(false)
  //   }
  // }, [isAlertDetailsLoading])

  // useEffect(() => {
  //   if (isAlertDetailsError) showAlert({ type: 'error', message: 'An error occurred in fetching the data.' })
  // }, [isAlertDetailsError])

  return (
    <App>
      <Sidebar />
      <Header pageTitle="Case Details" subHeader={<CasesSubHeader />} />
      <Content>
        <PageWrapper>
          <Container>
            {caseDetailsSummary && (
              <CaseDetailsFormWrapper>
                <CaseDetailsForm data={caseDetailsSummary} />
              </CaseDetailsFormWrapper>
            )}
            {/* {caseDetailsReport?.length > 0 && (
              <CaseDetailsFormWrapper>
                <BreachReportTable data={caseDetailsReport} />
              </CaseDetailsFormWrapper>
            )} */}
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
CaseDetails.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
CaseDetails.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetails)
