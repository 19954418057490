import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
// import PlusWhiteIcon from 'assets/images/plus-white'
// import UsersIcon from 'assets/images/users'
// import BriefCaseIcon from 'assets/images/briefcase'

// Store
import { actions } from 'core/store'

// Hooks
// import { useLocalStorage } from 'core/hooks/storage'
import { useGetTransactions, useGetTransactionChains } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './TransactionsTable.elements'

// Views
import { AccordionTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

import { TransactionsTableItem, TransactionTableFilter } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function TransactionsTable(props) {
  // Destructure
  const { filters, ui, actions, transaction } = props

  // Store States
  const { transactionFilters } = filters
  const {
    scannedTransaction,
    transactionChains,
    // transactionChains, defaultTransactionProvider
  } = transaction

  const { isPageTableUpdated } = ui
  const {
    setIsPageTableUpdated,
    setTransactionFilters,
    showAlert,
    setScannedTransaction,
    setScannedTransactionDetails,
    setTransactionChains,
    setDefaultTransactionProvider,
  } = actions

  // Hooks
  const { getTransactions, getTransactionsData, getTransactionsError, isGetTransactionsLoading } = useGetTransactions()
  const { getTransactionChains, getTransactionChainsData } = useGetTransactionChains()

  // Local states
  const [transactions, setTransactions] = useState([])
  const [pagination, setPagination] = useState()
  const [openedTab, setOpenedTab] = useState()

  // Functions
  function handleScannedAddress() {
    const newTransaction = {
      ...scannedTransaction,
      ...scannedTransaction.risk_data,
      ...scannedTransaction.financial_data,
      events: null,
      risk_partner_desc: null,
      risk_score_desc: null,
    }
    const newTransactionList = [newTransaction]
    transactions.forEach((item) => {
      if (item.hash !== scannedTransaction.hash) {
        newTransactionList.push(item)
      }
    })
    // Changing it back to null
    setScannedTransaction(null)
    setTransactions(newTransactionList)
    setScannedTransactionDetails(scannedTransaction)
  }
  function handleTransactionChains() {
    if (!getTransactionChainsData) return
    // !Idk why but this is causing some bugs but we need this to optimize the process
    // if (transactionChains && defaultTransactionProvider) return
    const newTransactionChains = {}
    const newDefaultTransactionProvider = {}
    getTransactionChainsData.forEach((item) => {
      // Destructure with default values
      const {
        data_type = '',
        provider_code = '',
        symbol = '',
        is_user_default = false,
        integration_id = '',
      } = item || {}

      // Initialize the data_type and provider_code if they don't exist
      if (!newTransactionChains[data_type]) newTransactionChains[data_type] = {}
      if (!newTransactionChains[data_type][provider_code]) newTransactionChains[data_type][provider_code] = {}
      // Insert the item directly
      newTransactionChains[data_type][provider_code][symbol] = item
      // Handle the default address provider
      if (is_user_default && !newDefaultTransactionProvider[data_type]) {
        newDefaultTransactionProvider[data_type] = { provider_code, integration_id, data_type }
      }
    })

    // Sorting by symbol using .sort()
    Object.keys(newTransactionChains).forEach((data_type) => {
      Object.keys(newTransactionChains[data_type]).forEach((provider_code) => {
        // Get the symbols and sort them
        const symbols = Object.keys(newTransactionChains[data_type][provider_code])
        const sortedSymbols = symbols.sort() // Sort the symbols
        // Create a new object to store sorted entries
        const sortedPartner = {}
        sortedSymbols.forEach((symbol) => {
          sortedPartner[symbol] = newTransactionChains[data_type][provider_code][symbol]
        })
        // Replace the unsorted partner object with the sorted one
        newTransactionChains[data_type][provider_code] = sortedPartner
      })
    })

    setTransactionChains(newTransactionChains)
    setDefaultTransactionProvider(newDefaultTransactionProvider)
  }
  function fetchTransactions() {
    getTransactions(transactionFilters)
  }

  // UseEffects
  useEffect(() => {
    if (!transactionChains || Object.keys(transactionChains || {})?.length < 1) getTransactionChains()
  }, [])
  useEffect(() => handleTransactionChains(), [getTransactionChainsData])
  useEffect(() => fetchTransactions(), [transactionFilters])
  useEffect(() => {
    if (isPageTableUpdated) {
      fetchTransactions()
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])
  useEffect(() => {
    if (getTransactionsData) {
      setTransactions(getTransactionsData.items)
      setPagination(getTransactionsData.pagination)
    }
  }, [getTransactionsData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (getTransactionsError) showAlert({ type: 'error', message: 'An error occurred in fetching transactions' })
  }, [getTransactionsError])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (scannedTransaction) handleScannedAddress()
  }, [scannedTransaction])
  return (
    <TableWrapper>
      <AccordionTable
        totalItems={pagination?.total && pagination.total}
        minWidth={900}
        filterComponents={<TransactionTableFilter />}
        tableFilters={transactionFilters}
        setTableFilters={setTransactionFilters}
      >
        {transactions.length > 0 &&
          !isGetTransactionsLoading &&
          transactions.map((data) => (
            <TransactionsTableItem openedTab={openedTab} setOpenedTab={setOpenedTab} key={data?.id} data={data} />
          ))}
        {isGetTransactionsLoading && <LoadingTablePlaceholder />}
        {transactions.length < 1 && !isGetTransactionsLoading && (
          <EmptyTablePlaceholder setFilter={setTransactionFilters} />
        )}
      </AccordionTable>
    </TableWrapper>
  )
}

// Default Props
TransactionsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
TransactionsTable.propTypes = {
  transaction: PropTypes.shape({
    scannedTransaction: PropTypes.instanceOf(Object),
    transactionChains: PropTypes.shape({}),
    defaultTransactionProvider: PropTypes.shape({}),
  }),
  filters: PropTypes.shape({
    transactionFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setTransactionFilters: PropTypes.func,
    showAlert: PropTypes.func,
    setScannedTransaction: PropTypes.func,
    setScannedTransactionDetails: PropTypes.func,
    setTransactionChains: PropTypes.func,
    setDefaultTransactionProvider: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTable)
