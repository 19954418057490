import styled from 'styled-components'

export const FormWrapper = styled.div`
  width: 100%;
`

export const FormInputGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > div > div {
    margin-bottom: 0 !important;
  }
`
export const FormGroupWrapper = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px 60px;
`
export const FormTitle = styled.h4`
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0 0 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const TableButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 25px;
  gap: 50px;
  align-items: center;

  padding: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const TableRowWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;

  width: 100%;

  gap: 20px;

  & :first-child {
    align-items: flex-start;
  }
  & :last-child {
    align-items: flex-end;
  }
`

export const TablePanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`

export const Title = styled.div`
  font-weight: 600;
`
export const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 300;
`
export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
