import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const SubHeaderWrapper = styled.div`
  padding: 10px 0 0 0;
`

export const SubHeaderNavList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
`

export const SubHeaderNavListItem = styled.li`
  padding: 0px;
  margin: 0 10px;
`

export const SubHeaderNavListLink = styled(NavLink)`
  position: relative;
  display: block;
  padding: 0 0 12px 0;
  margin: 0px;
  font-weight: 400;
  font-size: 14px;

  // TODO: Use theme mixins
  &.active {
    font-weight: 700;
    & #BetaTag {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.blue};

    border-bottom: 3px solid ${({ theme }) => theme.colors.blue};

    & #BetaTag {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`

export const BetaTag = styled.div`
  position: absolute;
  font-size: 10px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkGray};

  top: -2px;
  right: -25px;
`
