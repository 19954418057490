import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { connect } from 'react-redux'

// Utils
import { parseJwt } from 'common/utils/jwt'

// Store
import { actions } from 'core/store'

// Hooks
import { useResetPassword, useGetUserAuth } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  LoginFormWrapper,
  FormInputGroupWrapper,
  FormInputGroupItem,
  SuccessMsg,
  Msg,
  FormLink,
} from './ResetPasswordForm.elements'

// Views
import { Form, FormTextField, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function ResetPasswordForm(props) {
  // Destructure
  const { actions } = props

  // Store Actions
  const { showAlert } = actions

  // Hooks
  const { resetPassword, resetPasswordData, resetPasswordError, isResetPasswordLoading } = useResetPassword()
  // const { getUserAuth, getUserAuthData, isGetUserAuthLoading } = useGetUserAuth()

  // const [userCredentials, setUserCredentials] = useLocalStorage('userCredentials', {})
  // const [, setTheme] = useLocalStorage('theme', 'light')
  // const [TwoFactorAuthMsg, setTwoFactorAuthMsg] = useState()

  // const [userData, setUserData] = useState()

  // Variables
  const formRef = useRef()
  const initialFormValues = {
    email: '',
    password: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email Format').required('Email is required'),
  })

  // Functions
  const handleOnSubmit = (values) => {
    resetPassword(values)
  }
  // const handleLoginSuccess = (data) => {
  //   if (data?.has_2fa && !data?.access_token) {
  //     setTwoFactorAuthMsg('The sign in link was sent to your email.')
  //     return
  //   }
  //   setUserData(data)
  //   localStorage.setItem('userCredentials', JSON.stringify(data))
  //   const { uid } = parseJwt(data.access_token)
  //   if (uid) {
  //     getUserAuth({ user_id: uid })
  //   }
  // }
  useEffect(() => {
    if (resetPasswordError) {
      showAlert({ type: 'error', message: 'An error occurred in sending reset password link' })
    }
  }, [resetPasswordData])

  return (
    <LoginFormWrapper>
      <Form
        formRef={formRef}
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <FormInputGroupWrapper>
          {!resetPasswordData && !resetPasswordError && (
            <Msg>Forgot your password? Enter your email to reset your password</Msg>
          )}
          {resetPasswordData && (
            <SuccessMsg>Successfully sent reset password link. You may check your email</SuccessMsg>
          )}
          {!resetPasswordData && (
            <FormInputGroupItem>
              <FormTextField label="Email Address" type="email" name="email" disabled={isResetPasswordLoading} />
            </FormInputGroupItem>
          )}
        </FormInputGroupWrapper>
        {!resetPasswordData && (
          <Button fullWidth type="submit" disabled={isResetPasswordLoading}>
            {isResetPasswordLoading ? 'Sending email...' : 'Reset Password'}
          </Button>
        )}
      </Form>
    </LoginFormWrapper>
  )
}

// Default Props
ResetPasswordForm.defaultProps = {
  actions: {},
}

// Proptypes Validation
ResetPasswordForm.propTypes = {
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
