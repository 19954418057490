import { useMutation } from 'react-query'

// Services
import { RegisterVasp } from 'services/api/Business'

export default () => {
  const {
    mutate: registerVasp,
    data: registerVaspData,
    error: registerVaspError,
    isLoading: isRegisterVaspLoading,
    isSuccess: isRegisterVaspSuccess,
    isError: isRegisterVaspError,
  } = useMutation(RegisterVasp)

  return {
    registerVasp,
    registerVaspData,
    registerVaspError,
    isRegisterVaspLoading,
    isRegisterVaspSuccess,
    isRegisterVaspError,
  }
}
